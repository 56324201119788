<!DOCTYPE html>
<html>
<head>
</head>
<body style="margin: 0; padding: 0; height: 100vh;">
 <div *ngIf="showIframe">
   <p style="margin: 0; height: 100vh;">
     <iframe  style="width: 100%; height: 100%; border: none;" [src]="monitorUrl | safe"></iframe>
   </p>
 </div>

 <div *ngIf="!showIframe">
   <!-- Placeholder content while iframe is loading -->
   <div style="display: flex; justify-content: center; align-items: center;">
     <span class="spinner-border spinner-border-sm mr-1" style="width: 1.5rem; height: 1.5rem;"></span>
   </div>
 </div>
</body>
</html>

