import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {RestApiService} from "../../shared/rest-api.service";
import {ConfirmDialogComponent} from "../../_dialog/confirm-dialog/confirm-dialog.component";
import {InputTemplate} from "../show/show-template-asset/InputTemplate";

@Component({
  selector: 'app-list-templates-asset',
  templateUrl: './list-templates-asset.component.html',
  styleUrl: './list-templates-asset.component.css'
})
export class ListTemplatesAssetComponent implements OnInit {
  items: InputTemplate[] = [];
  @ViewChild(MatTable) table!: MatTable<InputTemplate>;
  dataSource = new MatTableDataSource<InputTemplate> ;
  displayedColumns = ['id', 'name', 'actions'];
  constructor(private restApiService: RestApiService,
              private dialog: MatDialog,
              private router: Router,
              private snackBar: MatSnackBar) {
  }
  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>(this.items);
    this.restApiService.getTemplatesAssets().subscribe({
      next: (response) => {
        if (response.success){
          this.items = response.payload
          this.dataSource.data = response.payload
        }else{
          this.snackBar.open('Error intentarlo de nuevo', '', {
            duration: 3000, verticalPosition: 'bottom',
            panelClass: 'notif-success'
          });
        }
      },
      error: (error) => {
        this.displayError()
      }
    });
  }

  didTapOpen (id:InputTemplate){
    this.router.navigate(['info-plantilla',id._id]);
  }

  assertItemType(item: InputTemplate): InputTemplate {
    return item;
  }

  onTapCreateTemplateAsset() {
    this.router.navigate(['crear-plantilla']);
  }

  didTapDelete (item:InputTemplate){
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirmar',
        body: '¿Esta seguro de eliminar Activo: ' + item.name + ' ?',
        confirm: 'Confirmar',
        confirmClass: 'danger-button',
        cancel: 'Cancelar'
      }
    });
    confirmRef.afterClosed().subscribe(result => {
      if (result) {
        this.restApiService.deleteTemplateAsset(item._id?.toString()).subscribe(
            response => {
              if (response.success) {
                this.deleteRowData(item)
                this.snackBar.open('Se elimino Activo: ' + item.name, '', {
                  duration: 3000, verticalPosition: 'bottom',
                  panelClass: ['blue-snackbar']
                });
              } else {
                this.displayError()
              }
            }
        );
      }
    });
  }
  deleteRowData(item: InputTemplate) {
    const index: number = this.items.findIndex(d => d === item);
    this.items.splice(index, 1);
    this.dataSource = new MatTableDataSource<InputTemplate>(this.items);
  }
  reloadRouter() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl);
    });
  }
  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
}
