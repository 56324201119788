<div class="grid p-2 m-0" >
    <div class="col-12">
        <div class="flex flex-column sm:flex-row align-items-center gap-4">
            <div class="flex flex-column sm:flex-row align-items-center gap-3">
                <p-avatar 
                    image="../../../assets/images/LIND_Building_BG.png" 
                    styleClass="w-5rem h-5rem flex-shrink-0" 
                    size="xlarge" 
                    shape="circle" />
                <div class="flex flex-column align-items-center sm:align-items-start">
                    <span class="text-900 font-bold text-4xl">Bienvenido {{userInfo["payload"]["firstName"]}}</span>
                    <span class="text-500 font-semibold text-xl m-0"> {{userInfo["payload"]["company"]["name"] | uppercase}}</span>
                    <span class="text-400 text-sm m-0">Última actualización: {{ lastUpdated | date:'dd/MM/yyyy, HH:mm:ss' }}</span>
                </div>
            </div>
            <div *ngIf="showCompanySelector" class="flex  gap-2 sm:ml-auto align-items-center">
                <span class="flex-none text-500 font-semibold text-lg"> Empresas: </span>
                <p-dropdown 
                    [options]="data"
                    [loading]="loading"
                    [(ngModel)]="selectedCompany"
                    placeholder="-- No Seleccionada --"
                    [filter]="true" 
                    filterBy="label"
                    [style]="{ display: 'flex', width: '20vw' }"
                    (onChange)="onSelectCompany($event)"/>
            </div>
        </div>
    </div>
        <p-card *ngIf="!loading; else skeleton" class="h-full col-12 md:col-6 xl:col-3">
            <p class="font-semibold text-lg m-1 text-center">Activos Registrados</p>
            <p class="text-4xl font-bold text-900 m-1 text-center">{{assets.length}}</p>
        </p-card>

        <p-card *ngIf="!loading; else skeleton" class="h-full col-12 md:col-6 xl:col-3">
            <p class="font-semibold text-lg m-1 text-center">Activos en Monitoreo</p>
            <p class="text-4xl font-bold text-900 m-1 text-center">{{assetsUnderMonitoring}}</p>
        </p-card>
        <p-card *ngIf="!loading; else skeleton" class="h-full col-12 md:col-6 xl:col-3">
            <p class="font-semibold text-lg m-1 text-center">Alertas (24 horas)</p>
            <p class="text-4xl font-bold text-900 m-1 text-center">{{alerts24}}</p>
        </p-card>
        <p-card *ngIf="!loading; else skeleton" class="h-full col-12 md:col-6 xl:col-3">
            <p class="font-semibold text-lg m-1 text-center">Alertas (Mes)</p>
            <p class="text-4xl font-bold text-900 m-1 text-center">{{alertsMonth}}</p>
        </p-card>
        <p-card class="h-full col-12 md:col-6 xl:col-6">
            <ng-template pTemplate="header">
                <p class="text-700 text-xl font-semibold py-3 px-3 m-0">Riesgo Actual</p>
            </ng-template>
            <ng-container *ngIf="selectedCompany">
                <app-current-risk-chart [data]="selectedCompany" width="50vh"></app-current-risk-chart>
            </ng-container>
            <ng-container *ngIf="!selectedCompany">
                <p class="text-500 text-center">Seleccione una empresa para visualizar el riesgo actual</p>
            </ng-container>
        </p-card>         
        <p-card class="h-full col-12 md:col-6 xl:col-6">
            <ng-template pTemplate="header">
                <p class="text-700 text-xl font-semibold py-3 px-3 m-0" >Mapa</p>
            </ng-template>
            <app-assets-map [data]="assets" *ngIf="assets"></app-assets-map>
        </p-card>    
        <p-card class="h-full col-12 md:col-6 xl:col-6">
            <ng-template pTemplate="header">
                <p class="text-700 text-xl font-semibold py-3 px-3 m-0">Histórico de Alertas</p>
            </ng-template>
            <app-historical-alert-chart [data]="selectedCompany"></app-historical-alert-chart>
        </p-card>      
        <p-card class="h-full col-12 md:col-6 xl:col-6">
            <ng-template pTemplate="header">
                <p class="text-700 text-xl font-semibold py-3 px-3 m-0">Listado Activos</p>
            </ng-template>
            <app-assets-list [data]="assets" *ngIf="assets" width="50vh"></app-assets-list>
        </p-card>    
</div>

<ng-template #skeleton>
            <div class="flex flex-column flex-grow-1 align-items-center justify-content-center border-round border-1 surface-border surface-card shadow-1 font-bold m-1 px-5 py-3 border-round">
                <p-skeleton width="10rem" height="2rem" class="m-1"/>
                <p-skeleton width="6rem" height="3rem" />
            </div>
            
</ng-template>