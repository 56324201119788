import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map, Observable, throwError} from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {environment} from "../../environments/environment";
import {Monitors} from "../Views/monitor/monitors/model/Monitors";
import {User} from "../Views/user/user-list/model/User";
import {Users} from "../Views/user/user-list/model/Users";
import {ResponseHTTP} from "../Views/user/register-user/model/responseHTTP";
import {CompanyAndUbications} from "../Views/monitor/register-monitor/model/CompanyAndUbications";
import {MonitorNewResponse} from "../Views/monitor/register-monitor/model/MonitorNewResponse";
import {PermissionAndCompany} from "../Views/user/register-user/model/PermissionAndCompany";
import {getMonitors} from "../Views/monitor/monitors/model/getMonitor";
import { Details_unit, Details_units, DetailsActive, List_units, listDatailsUnit, PayloadUnits} from "../Views/unit/units/model/list_units";
import {List_templates_asset} from "../Views/list-templates-asset/model/templates_asset";
import {ActiveTypes} from "../Views/create/create-asset/ActiveTypes";
import {ResponseAdditemsModal} from "../_dialog/add-Item-dialog/model/responseAdditemsModal";
import {InputTemplateData} from "../Views/show/show-template-asset/InputTemplateData";
import {InputTemplateDataInfo} from "../Views/show/show-template-asset/InputTemplateInfo";
import {PhotoSaved} from "../Views/show/show-template-active/entities/Photo";
import {FileSaved} from "../Views/show/show-template-active/entities/File";
import {Companies} from "../Views/companies/model/Companies";
import {Ubications} from "../Views/ubications/model/Ubications";
import {ActiveTypesAndTemplates} from "../Views/create/create-asset/ActiveTypesAndTemplates";

@Injectable({
  providedIn: 'root',
})
export class RestApiService {
  constructor(private http: HttpClient) {
  }

  /*========================================
    CRUD Methods for consuming RESTful API
  =========================================*/
  // Http Options

  getUsers(): Observable<Users> {
    return this.http
        .get<Users>(environment.apiUrl + '/user/all', {})
        .pipe(retry(1), catchError(this.handleError));
  }
    updateClientAdminPassword(status: string, id:string,firstName:string,lastName:string,email:string,password:string,company:string,permission:string) {
        switch (status) {
            case "new":
                return this.http
                    .post<ResponseHTTP>(
                        environment.apiUrl + '/user/create/',
                        {firstName,lastName,email,password,company,permission})
                    .pipe(retry(1), catchError(this.handleError));
            default:
                return this.http
                    .post<ResponseHTTP>(
                        environment.apiUrl + '/user/edit/'+id,
                        {firstName,lastName,email,password,company,permission})
                    .pipe(retry(1), catchError(this.handleError));
        }
    }
  deleteUser(id: any) {
    return this.http
        .delete<User>(
          environment.apiUrl + '/user/delete/' + id.toString(),
          {})
        .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
  getMonitors() {
    return this.http.get<Monitors>(`${environment.apiUrl}/monitor/all`,  {})
      .pipe(map(response => {
        if (response) {
          return response;
        }
        return response;
      }));
  }
  getMonitor(id: any) {
    return this.http.get<getMonitors>(`${environment.apiUrl}/monitor/`+ id,  {})
      .pipe(map(response => {
        if (response) {
          return response;
        }
        return response;
      }));
  }
  deleteMonitor(id: any) {
    return this.http
      .delete<ResponseHTTP>(environment.apiUrl + '/monitor/delete/' + id, {})
      .pipe(retry(1), catchError(this.handleError));
  }
  // HttpClient API post() method => Create employee
  createCompany(name: string, description:string): Observable<ResponseHTTP> {
    return this.http
      .post<ResponseHTTP>(
        environment.apiUrl + '/company/create',  {name, description},
        {}
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  // HttpClient API put() method => Update employee
  updateCompany(id: any, name:string,description:string): Observable<ResponseHTTP> {
    return this.http
      .patch<ResponseHTTP>(
        environment.apiUrl + '/company/edit/' + id,
        {name, description},
        {}
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  // HttpClient API delete() method => Delete employee
  deleteCompany(id: any) {
    return this.http
      .delete<ResponseHTTP>(environment.apiUrl + '/company/delete/' + id, {})
      .pipe(retry(1), catchError(this.handleError));
  }

  // Error handling
  getCompanies() {
    return this.http.get<Companies>(`${environment.apiUrl}/company/all`, {})
      .pipe(map(response => {
        if (response) {
          return response;
        }
        return response;
      }));
  }

  getCompaniesForSelector() {
    return this.http.get<Companies>(`${environment.apiUrl}/company/selector`, {})
      .pipe(map(response => {
        if (response) {
          return response;
        }
        return response;
      }));
  }

  createubication(name: string, description:string,company:string): Observable<ResponseHTTP> {
    return this.http
      .post<ResponseHTTP>(
        environment.apiUrl + '/ubication/create',  {name, description, company},
        {}
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  // HttpClient API put() method => Update employee
  updateUbication(id: any, name: string, description:string,company:string): Observable<ResponseHTTP> {
    return this.http
      .patch<ResponseHTTP>(
        environment.apiUrl + '/ubication/edit/' + id,
        {name, description, company},
        {}
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  // HttpClient API delete() method => Delete employee
  deleteUbication(id: any) {
    return this.http
      .delete<ResponseHTTP>(environment.apiUrl + '/ubication/delete/' + id, {})
      .pipe(retry(1), catchError(this.handleError));
  }

  // Error handling
  getUbications() {
    return this.http.get<Ubications>(`${environment.apiUrl}/ubication/all`,  {})
      .pipe(map(response => {
        if (response) {
          return response;
        }
        return response;
      }));
  }
  createMonitor(company: number, url:string, image:string,version: string, description:string ,ubication:number): Observable<ResponseHTTP> {
    return this.http
      .post<ResponseHTTP>(
        environment.apiUrl + '/monitor/create',  {company, url, image, version,description,ubication},
        {}
      )
      .pipe(retry(1), catchError(this.handleError));
  }
  getCompaniesAndUbications() {
    return this.http.get<CompanyAndUbications>(`${environment.apiUrl}/monitor/companiesAndUbicationAndActives/`,  {})
      .pipe(map(response => {
        if (response) {
          return response;
        }
        return response;
      }));
  }
  updateMonitor(id:string,company: number, url:string, image:string,version: string, description:string ,ubication:number,_activeUnit:string){
    return this.http
      .patch<ResponseHTTP>(
        environment.apiUrl + '/monitor/edit/' + id,
        {company, url, image, version,description,ubication,_activeUnit},
        {}
      )
      .pipe(retry(1), catchError(this.handleError));
  }
  uploadImageMonitorNew(formData: FormData,company: number, url:string, image:string,version: string, description:string ,ubication:number){
    formData.append('company', company.toString());
    formData.append('url', url);
    formData.append('image', image);
    formData.append('version', version);
    formData.append('description', description);
    formData.append('ubication', ubication.toString());
    // @ts-ignore
    return this.http.post<MonitorNewResponse>(`${environment.apiUrl}/monitor/upload/file/new`,  formData)
      .pipe(map(response => {
        if (response) {
          return response;
        }
        return response;
      }));
  }
  uploadImageMonitorEdit(id: any,formData: FormData,company: number, url:string, image:string,version: string, description:string ,ubication:number,_activeUnit:string){
    formData.append('company', company.toString());
    formData.append('url', url);
    formData.append('image', image);
    formData.append('version', version);
    formData.append('description', description);
    formData.append('ubication', ubication.toString());
    formData.append('activeUnit', _activeUnit);


    // @ts-ignore
    return this.http.post<ResponseHTTP>(`${environment.apiUrl}/monitor/upload/file/edit/`+id,formData)
      .pipe(map(response => {
        if (response) {
          return response;
        }
        return response;
      }));
  }
  getCompaniesAndPermission(){
    return this.http.get<PermissionAndCompany>(`${environment.apiUrl}/monitor/companyAndPermission`)
      .pipe(map(response => {
        if (response) {
          return response;
        }
        return response;
      }));
  }
  getTemplatesAssets() {
    return this.http.get<List_templates_asset>(`${environment.apiUrl}/template-assets/all`,  {})
        .pipe(map(response => {
          if (response) {
            return response;
          }
          return response;
        }));
  }
  getTemplateAsset(id: any) {
    return this.http.get<ResponseHTTP>(`${environment.apiUrl}/template-assets/`+ id,  {})
        .pipe(map(response => {
          if (response) {
            return response;
          }
          return response;
        }));
  }
  deleteTemplateAsset(id: any) {
    return this.http
        .delete<ResponseHTTP>(environment.apiUrl + '/template-assets/delete/' + id, {})
        .pipe(retry(1), catchError(this.handleError));
  }
    createTemplateAsset(inputTemplate: object[],selectedTypeId:string,name:string) {
        return this.http.post<ResponseHTTP>(`${environment.apiUrl}/template-assets/create`,  {inputTemplate,selectedTypeId,name})
            .pipe(map(response => {
                return response;
            }));
    }
    editTemplateAsset(id:any, inputTemplate: InputTemplateData[],selectedTypeId:string,name:string) {
        return this.http.patch<ResponseHTTP>(`${environment.apiUrl}/template-assets/edit/`+id,  {inputTemplate,selectedTypeId,name})
            .pipe(map(response => {
                return response;
            }));
    }

    getActiveUnits() {
        return this.http.get<List_units>(`${environment.apiUrl}/active-units/all`,  {})
            .pipe(map(response => {
                if (response) {
                    return response;
                }
                return response;
            }));
    }
    createUnit(name:string, description:string,company:string) {
        return this.http.post<ResponseHTTP>(`${environment.apiUrl}/active-units/create`,  {name, description, company})
            .pipe(map(response => {
                if (response) {
                    return response;
                }
                return response;
            }));
    }
    updateUnit(id:string,name:string, description:string,company:string) {
        return this.http.patch<ResponseHTTP>(`${environment.apiUrl}/active-units/edit/`+id,  {name, description, company})
            .pipe(map(response => {
                if (response) {
                    return response;
                }
                return response;
            }));
    }
    deleteUnit(id:string) {
        return this.http.delete<ResponseHTTP>(`${environment.apiUrl}/active-units/delete/`+id,  {})
            .pipe(map(response => {
                if (response) {
                    return response;
                }
                return response;
            }));
    }

    getAllUnitsAndAssets() {
        return this.http.get<List_units>(`${environment.apiUrl}/active-units/all-with-assets`,  {})
            .pipe(map(response => {
                if (response) {
                    return response;
                }
                return response;
            }));
    }

    getDetailsActiveUnits(id: any) {
        return this.http.get<listDatailsUnit>(`${environment.apiUrl}/active-template/`+id,  {})
            .pipe(map(response => {
                if (response) {
                    return response;
                }
                return response;
            }));
    }
    showTemplateDetailsActiveUnits(id: any) {
        return this.http.get<Details_units>(`${environment.apiUrl}/active-template/info/`+id,  {})
            .pipe(map(response => {
                if (response) {
                    return response;
                }
                return response;
            }));
    }
    getActiveUnit(id: any) {
        return this.http.get<PayloadUnits>(`${environment.apiUrl}/active-units/`+id,  {})
            .pipe(map(response => {
                if (response) {
                    return response;
                }
                return response;
            }));
    }
    deleteTemplateDetailsActiveUnits(id: any) {
        return this.http.delete<ResponseHTTP>(`${environment.apiUrl}/active-template/delete/`+id,  {})
            .pipe(map(response => {
                if (response) {
                    return response;
                }
                return response;
            }));
    }
    getTemplateAssetByType(typeId: any) {
        return this.http.get<List_templates_asset>(`${environment.apiUrl}/template-assets/type/`+ typeId,  {})
            .pipe(map(response => {
                if (response) {
                    return response;
                }
                return response;
            }));
    }

    getDetailsTemplateActive(id: any) {
        return this.http.get<DetailsActive>(`${environment.apiUrl}/active-template/info/`+id,  {})
            .pipe(map(response => {
                if (response) {
                    return response;
                }
                return response;
            }));
    }
    createTemplateActive(name: any, is_active: any, hasBIM:any,hasMD:any,
                         hasMI:any,hasVA:any,active_type:any,description:any, company:any,URLMD:any,
                         unit:any,latitude:any,longitude:any,belong_to:any,
                         inputTemplateDataInfo : InputTemplateDataInfo[],  inputTemplateData: InputTemplateData[]) {

          return this.http.post<Details_unit>(`${environment.apiUrl}/active-template/create/`,  { name, is_active,
              hasBIM,hasMD,hasMI,hasVA,active_type,
              description, company,URLMD,unit,latitude,longitude,belong_to,inputTemplateDataInfo, inputTemplateData
          }).pipe(map(response => {
              if (response) {
                  return response;
              }
              return response;
          }));
    }
    createTemplateActiveSelector(name: any, is_active: any, hasBIM:any,hasMD:any,
                         hasMI:any,hasVA:any,template:any,active_type:any,description:any, company:any,URLMD:any,
                         unit:any,latitude:any,longitude:any,belong_to:any,
                         inputTemplateDataInfo : InputTemplateDataInfo[],  photos?: PhotoSaved[], files?: FileSaved[] ) {


        return this.http.post<Details_unit>(`${environment.apiUrl}/active-template/create/`,  { name, is_active,
            hasBIM,hasMD,hasMI,hasVA,template,active_type,
            description, company,URLMD,unit,latitude,longitude,belong_to,inputTemplateDataInfo, photos,files
        }).pipe(map(response => {
            if (response) {
                return response;
            }
            return response;
        }));
    }

    editFullTemplateActive(id:any, name: any, is_active: any, hasBIM:any,hasMD:any,
                         hasMI:any,hasVA:any,template:any,active_type:any,description:any, company:any,URLMD:any,
                         unit:any,latitude:any,longitude:any,inputTemplateDataInfo:InputTemplateDataInfo[] ) {
        return this.http.patch<Details_unit>(`${environment.apiUrl}/active-template/edit-full/`+id,  { name,active_type,
            description,latitude,longitude, inputTemplateDataInfo
        }).pipe(map(response => {
            if (response) {
                return response;
            }
            return response;
        }));
    }
    uploadFile(formData: FormData,activedId:string,campoId:string,templateId:string){
        formData.append("activeId",activedId)
        formData.append("inputId",campoId)
        formData.append("templateId",templateId)
        return this.http.post<ResponseHTTP>(`${environment.apiUrl}/active-template/upload`,  formData)
            .pipe(retry(1), catchError(this.handleError));
    }
    deleteImage(activeId: string, imageId: number) {
        return this.http.delete<ResponseHTTP>(`${environment.apiUrl}/active-template/delete/image/${activeId}/${imageId}`);
    }
    deleteFile(activeId: string, assetId: number) {
        return this.http.delete<ResponseHTTP>(`${environment.apiUrl}/active-template/delete/file/${activeId}/${assetId}`);
    }
    getAllActiveType() {
        return this.http.get<ActiveTypes>(`${environment.apiUrl}/active-type/all`, {})
            .pipe(map(response => {
                if (response) {
                    return response;
                }
                return response;
            }));
    }
    getAllActiveTypeandTemplate() {
        return this.http.get<ActiveTypesAndTemplates>(`${environment.apiUrl}/active-type/all-with-template`, {})
            .pipe(map(response => {
                if (response) {
                    return response;
                }
                return response;
            }));
    }
    updateActiveType(status:string, id:string,name:string) {
        switch (status) {
            case "new":
                return this.http
                    .post<ResponseHTTP>(
                        environment.apiUrl + '/active-type/create',
                        {name})
                    .pipe(retry(1), catchError(this.handleError));
            default:
                return this.http
                    .post<ResponseHTTP>(
                        environment.apiUrl + '/active-type/edit/'+id,
                        {name})
                    .pipe(retry(1), catchError(this.handleError));
        }
    }
    getItems(url:string) {
        return this.http.get<ResponseAdditemsModal>(`${environment.apiUrl}${url}`, {})
            .pipe(retry(1), catchError(this.handleError));
    }
    addItem(url:string, name:any) {
        return this.http.post<ResponseAdditemsModal>(`${environment.apiUrl}${url}`, {name})
            .pipe(retry(1), catchError(this.handleError));
    }
    deleteItem(url:string, id:any) {
        return this.http
            .delete<ResponseAdditemsModal>(
                `${environment.apiUrl}${url}/` + id.toString(),
                {})
            .pipe(retry(1), catchError(this.handleError));
    }

    getLatestWorstRisks(companyId: string) {
        return this.http.get<ResponseHTTP>(`${environment.apiUrl}/latest-risk-measurements/worst-risks/` + companyId, { })
            .pipe(retry(1), catchError(this.handleError));
    }

    getAssetsByCompany(companyId: string) {
        return this.http.get<ResponseHTTP>(`${environment.apiUrl}/active-template/by-company/` + companyId, {  } )
            .pipe(retry(1), catchError(this.handleError));
    }

    getInitialRiskAlerts(companyId: string) {
        return this.http.get<ResponseHTTP>(`${environment.apiUrl}/risk-alerts/all-alerts/` + companyId, {  } )
            .pipe(retry(1), catchError(this.handleError));
    }

    getRiskAlertsByDate(companyId: string, startDate: string, endDate: string) {
      const params = new HttpParams()
        .set('startDate', startDate)
        .set('endDate', endDate);

      return this.http.get<ResponseHTTP>(`${environment.apiUrl}/risk-alerts/period/${companyId}`, { params })
        .pipe(retry(1), catchError(this.handleError));
    }
    
    getDetailedLatestRisks(companyId: string) {
        return this.http.get<ResponseHTTP>(`${environment.apiUrl}/latest-risk-measurements/` + companyId, {  } )
            .pipe(retry(1), catchError(this.handleError));
    }

    getAssetsLight(){
        return this.http.get<ResponseHTTP>(`${environment.apiUrl}/active-template/light`, {  } )
            .pipe(retry(1), catchError(this.handleError));
    }
}
