import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-root-input-text-dialog',
  templateUrl: './input-text-dialog.component.html',
  styleUrls: ['./input-text-dialog.component.scss']
})
export class InputTextDialogComponent implements OnInit {
  form!: FormGroup;
  text:string;
  placeHolder:string = "Texto"
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<InputTextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.text = data.textEdit
  }
  ngOnInit(): void {
    this.placeHolder = this.data.placeHolder
    this.form = this.fb.group({
      textAdd: [this.text, []]
    });
  }
  save() {
    this.dialogRef.close(this.form?.value);
  }
  close() {
    this.dialogRef.close();
  }
}
