<!DOCTYPE html>
<html>
<head>
    <script src="//ajax.googleapis.com/ajax/libs/jquery/1.12.0/jquery.min.js"></script>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/css/bootstrap.min.css">
</head>
<body>


<div class="m-3" >
    <div fxFlex="95" fxLayout="column" style="margin: 5px;font-family: Roboto, sans-serif!important;font-size:20px !important;">
        <h3  class ="logo  title m-3" fxFlex="none"> Crear Plantilla 📋</h3>

        <mat-form-field class="example-full-width" [floatLabel]="'always'">
            <mat-label>Nombre</mat-label>
            <input matInput class="form-control" placeholder="" id="Nombre" name="nombre_plantilla" [(ngModel)]="nombre" type="text" required>
        </mat-form-field>


        <mat-form-field class="example-full-width">
            <mat-label>Seleccionar plantilla</mat-label>
            <mat-select class="js-example-basic-single" style="width:100%" id="Tipo" name="Tipo" [(ngModel)]="selectedTemplate" (selectionChange)="onSelectTemplate($event.value)">
                <mat-option *ngFor="let tipo of templates" [value]="tipo">{{ tipo.name }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label>Tipo plantilla</mat-label>
            <mat-select class="js-example-basic-single" style="width:100%" id="Tipo" name="Tipo" [(ngModel)]="selectedTipo">
                <mat-option *ngFor="let tipo of tipos" [value]="tipo._id">{{ tipo.name }}</mat-option>
            </mat-select>
        </mat-form-field>

    <button mat-raised-button class="btn boton" id="añadir_campo" (click)="addCampo()">Añadir Nuevo Campo</button>
    <div class="mat-elevation-z0 small-table">
        <table mat-table #dataTable [dataSource]="dataSource"
               cdkDropList
               [cdkDropListData]="dataSource"
               (cdkDropListDropped)="drop($event)">
            <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                <th mat-header-cell *matHeaderCellDef>{{titleHeaderCell(column) }}</th>
                <td mat-cell *matCellDef="let campo" >
                    <ng-container *ngIf="column !== 'obligatory' && column !== 'isHidden'; else checkboxOrText">
                  <span *ngIf="column === 'type'">
                      <select class="form-select" style="width:100%"  [(ngModel)]="campo[column]">
                        <option value="" disabled selected>Seleccione un tipo</option>
                        <option value="Text">Texto</option>
                        <option value="Number">Numero</option>
                        <option value="Checkbox">Checkbox</option>
                        <option value="Selector">Selector</option>
                        <option value="Photos">Fotos</option>
                        <option value="Files">Archivos</option>
                      </select>
                    </span>
                        <span *ngIf="column === 'name' || column === 'notation'">
                      <input type="text" [(ngModel)]="campo[column]">
                        </span>

                        <span *ngIf="column === 'action'">
                            <div >
                            <button mat-button  (click)="didTapDelete(campo)">
                                <mat-icon class = "svg" svgIcon="delete-outline"></mat-icon>
                                Borrar
                            </button>
                        </div>
                        </span>
                    </ng-container>
                    <ng-template #checkboxOrText>
                        <mat-checkbox color="primary" [(ngModel)]="campo[column]" ></mat-checkbox>
                    </ng-template>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"
                cdkDrag
                [cdkDragData]=row></tr>
        </table>
    </div>
        <div class="mt-4">
            <button  style=" width: 250px;  height:50px; border-radius: 15px; margin-left: 10px;background-color: #2196F3; color:white" mat-raised-button class="btn boton" id="añadir_planilla" (click)="createTemplate()">Guardar Planilla</button>

        </div>
  </div>
</div>

</body>
</html>
