import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {MatSnackBar} from "@angular/material/snack-bar";
import {RestApiService} from "../../shared/rest-api.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Ubication} from "../../Views/ubications/model/Ubication";
import {Company} from "../../Views/companies/model/company";
import {DetailsUnit} from "../../Views/unit/detail-unit/model/detailsUnit";

@Component({
  selector: 'app-root-input-edit-data-monitor-dialog',
  templateUrl: './input-edit-data-monitor-dialog.component.html',
  styleUrls: ['./input-edit-data-monitor-dialog.component.scss']
})

export class InputEditDataMonitorDialogComponent implements OnInit {
  loginForm!: FormGroup;
  constructor(
      private breakpointObserver: BreakpointObserver,
      private dialogRef: MatDialogRef<InputEditDataMonitorDialogComponent>,
      private restApiService: RestApiService,
      private formBuilder: FormBuilder,
      private snackBar: MatSnackBar,
      @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  flex_reduce_size = 'row';
  registering = false;
  uploadFile?:File
  ubications: Ubication[] = [];
  companies: Company[] = [];
  actives: DetailsUnit[] = []
  companyId = -1;
  ubicationId = -1;
  _activeUnit = "";

  type = 'REGISTRAR';
  imageSrc:string = "https://api.qa.lind.global/empty_image.png"
  ngOnInit(): void {
    this.breakpointObserver
        .observe(['(min-width: 720px)'])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.flex_reduce_size = 'row';
          } else {
            this.flex_reduce_size = 'column';
          }
        });
    if (this.data.type == 'ACTUALIZAR') {
      this.type = this.data.type;
      this.imageSrc = this.data.image
      this._activeUnit = this.data._activeUnit
      this.loginForm = this.formBuilder.group({
        url: [this.data.url, Validators.required],
        image: [this.data.image, Validators.required],
        version: [this.data.version, Validators.required],
        description: [this.data.description, Validators.required],
      });

    } else {
      this.type = this.data.type;
      this.loginForm = this.formBuilder.group({
        url: ['', Validators.required],
        image: ['', Validators.required],
        version: ['', Validators.required],
        description: ['', Validators.required]
      });
    }
    this.restApiService.getCompaniesAndUbications().subscribe({
      next: (response) => {
        if (response.success) {
          this.ubications =  response.payload.ubications
          this.companies = response.payload.companies
          this.actives = response.payload.actives

          this.companyId = this.data.companyId ?? -1
          this.ubicationId = this.data.ubicationId ?? -1
        } else {
          this.displayError()
        }
      },
      error: (error) => {
        this.displayError()
      }
    });
  }
  submit() {
    const url: string = this.loginForm?.get('url')?.value ?? ""
    const version: string = this.loginForm?.get('version')?.value ?? ""
    const description: string = this.loginForm?.get('description')?.value ?? ""
    if(url.length === 0){
      this.snackBar.open('URL no definida', '', {
        duration: 3000, verticalPosition: 'top',
        panelClass: 'notif-success'
      });
    } else if(version.length === 0){
      this.snackBar.open('Versión no definida', '', {
        duration: 3000, verticalPosition: 'top',
        panelClass: 'notif-success'
      });
    } else if(this._activeUnit === undefined){
      this.snackBar.open('Activo no definido', '', {
        duration: 3000, verticalPosition: 'top',
        panelClass: 'notif-success'
      });
    } else if(this._activeUnit.length ===0){
      this.snackBar.open('Activo no definido', '', {
        duration: 3000, verticalPosition: 'top',
        panelClass: 'notif-success'
      });
    } else if(description.length === 0){
      this.snackBar.open('Descripción no definida', '', {
        duration: 3000, verticalPosition: 'top',
        panelClass: 'notif-success'
      });
    } else {
      this.registering = true;
      if (this.data.type == 'ACTUALIZAR') {
        if (this.uploadFile != null){
          const formData = new FormData();
          formData.append('image_upload', this.uploadFile);
          this.restApiService.uploadImageMonitorEdit(this.data.monitorId, formData,this.companyId,url,this.data.image,version,description,this.ubicationId,this._activeUnit).subscribe({
            next: (response) => {
              this.registering = false;
              if (response.success) {
                this.dialogRef.close(this.loginForm?.value);
              }
            },
            error: (error) => {
              this.registering = false
              this.displayError()
            }
          });
        }else{
          this.restApiService.updateMonitor(this.data.monitorId,this.companyId,url,this.data.image,version,description,this.ubicationId, this._activeUnit).subscribe({
            next: (response) => {
              this.registering = false;
              if (response.success) {
                this.dialogRef.close(this.loginForm?.value);
              }
            },
            error: (error) => {
              this.registering = false
              this.displayError()
            }
          });
        }
      } else {
        if (this.uploadFile == null){
          this.registering = true
          this.restApiService.createMonitor(this.companyId, url, this.data.image?? "https://api.qa.lind.global/empty_image.png", version, description, this.ubicationId).subscribe({
            next: (response) => {
              if (response.success) {
                this.dialogRef.close(this.loginForm?.value);
              } else if (response.status === 'duplicate') {
                this.snackBar.open('Existe monitor', '', {
                  duration: 3000, verticalPosition: 'bottom',
                  panelClass: ['blue-snackbar']
                });
              }
              this.registering = false;
            },
            error: (error) => {
              this.registering = false
              this.snackBar.open('Error Intentar de nuevo', '', {
                duration: 3000, verticalPosition: 'bottom',
                panelClass: ['blue-snackbar']
              });
            }
          });

        }else{
          if (this.uploadFile) {
            const formData = new FormData();
            formData.append('file', this.uploadFile);
            this.restApiService.uploadImageMonitorNew(formData,this.companyId, url, this.data.image?? "https://api.qa.lind.global/empty_image.png", version, description, this.ubicationId).subscribe({
              next: (response) => {
                if (response.success) {
                  this.dialogRef.close(this.loginForm?.value);
                }
                this.registering = false;
              },
              error: (error) => {
                this.registering = false
                this.snackBar.open('Error Intentar de nuevo', '', {
                  duration: 3000, verticalPosition: 'bottom',
                  panelClass: ['blue-snackbar']
                });
              }
            });
          }
        }
      }
    }
  }
  upload(event:Event){
    const inputElement = event.target as HTMLInputElement;
    // @ts-ignore
    this.uploadFile = inputElement.files[0];
    if (this.uploadFile) {
      this.imageSrc = URL.createObjectURL(this.uploadFile);
    }
  }
  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
}
