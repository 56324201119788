<!DOCTYPE html>
<html>
<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/css/bootstrap.min.css">
</head>
<body>
    <ngx-loading-bar></ngx-loading-bar>
        <div fxFlex="95" fxLayout="column" style="margin: 5px;font-family: Roboto, sans-serif!important;font-size:20px !important;">
            <h1  class ="logo  title m-3" fxFlex="none"> Lista de plantillas 🗒</h1>
                <div class="centered-div" *ngxPermissionsOnly="['MASTER', 'ADMIN']">
                    <button mat-button (click)="onTapCreateTemplateAsset()"  mat-flat-button class="mat-button-register-user">
                        <mat-icon inline=true>add</mat-icon>
                        Crear Plantilla
                    </button>
                </div>
                <div class="mat-elevation-z0">
                    <table mat-table [dataSource]="dataSource" class="full-width-table" matSort aria-label="Elements">
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
                            <td mat-cell *matCellDef="let row">{{assertItemType(row).name ?? "Sin información" }}</td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
                            <td mat-cell *matCellDef="let row">{{ assertItemType(row).type?.name ?? "Sin información"}}</td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef > Acciones </th>
                            <mat-cell *matCellDef="let user" >
                                <div >
                                    <button mat-button  (click)="didTapOpen(user)">
                                        <mat-icon class = "svg" svgIcon="open-in-new"></mat-icon>
                                        Abrir
                                    </button>
                                    <button mat-button  (click)="didTapDelete(user)">
                                        <mat-icon class = "svg" svgIcon="delete-outline"></mat-icon>
                                        Borrar
                                    </button>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <div class="m-2" style="font-size: 15px" *ngIf="items.length === 0">Sin Plantillas ⛔</div>
                    <mat-paginator [hidden]="items.length === 0"    #paginator
                                   [length]="dataSource.data.length"
                                   [pageIndex]="0"
                                   [pageSize]="10"
                                   [pageSizeOptions]="[5, 10, 20]"
                                   aria-label="Select page">
                    </mat-paginator>
                </div>
        </div>
    <script src="//ajax.googleapis.com/ajax/libs/jquery/1.12.0/jquery.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/js/bootstrap.min.js"></script>
</body>
</html>
