import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Units} from "./model/units";
import {RestApiService} from "../../../shared/rest-api.service";
import {
  InputEditDataUnitDialogComponent
} from "../../../_dialog/input-edit-data-unit-dialog/input-edit-data-unit-dialog.component";
import {ConfirmDialogComponent} from "../../../_dialog/confirm-dialog/confirm-dialog.component";
import { Column } from 'src/app/_models/columns';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrl: './units.component.css'
})
export class UnitsComponent implements OnInit{

  items: Units[] = [];
  @ViewChild(MatTable) table!: MatTable<Units>;
  dataSource = new MatTableDataSource<Units> ;
  displayedColumns = [ 'name','enterprise','description','actions'];
  columns!: Column[];
  loading: boolean = true;
  selectedUnit!: Units;

  constructor(private restApiService: RestApiService,
              private dialog: MatDialog,
              private router: Router,
              private snackBar: MatSnackBar) {
  }
  ngOnInit(): void {

    this.columns = [
      {field: 'name', header: 'Nombre'},
      {field: 'company', header: 'Empresa'},
      {field: 'description', header: 'Descripción'},
      {field: 'actions', header: 'Acciones'}
    ]
    this.dataSource = new MatTableDataSource<Units>(this.items);
    this.restApiService.getActiveUnits().subscribe({
      next: (response) => {
        if (response.success){
          this.items = response.payload
          this.loading = false
          this.dataSource.data = response.payload
        }else{
          this.displayError()
        }
      },
      error: (error) => {
        this.displayError()
      }
    });
  }
  assertItemType(item: Units): Units {
    return item;
  }
  didTapCreateUnit(){
    const confirmRefUpdateClient = this.dialog.open(InputEditDataUnitDialogComponent, {
      data: {
        title: 'Se necesita Rut Empresa',
        body: 'Se tiene que asociar al cliente',
        confirm: 'Editar cliente',
        confirmClass: 'danger-button',
        cancel: 'Cancelar ',
        type:'REGISTRAR',
        unitId: '',
        name:'',
        description: '',
        companyId: '',
      }
    });
    confirmRefUpdateClient.afterClosed().subscribe(result => {
      if(result !== undefined){
        this.snackBar.open('Unidad Creada', '', {
          duration: 3000, verticalPosition: 'bottom',
          panelClass: 'notif-success'
        });
        this.reloadRouter()
      }
    });
  }
  reloadRouter() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl);
    });
  }
  didTapOpen(item:Units){
    this.router.navigate(['detalle-unidad',item._id]);
  }

  didtapEdit(item:Units){
    const confirmRefUpdateClient = this.dialog.open(InputEditDataUnitDialogComponent, {
      data: {
        title: 'Se necesita Rut Empresa',
        body: 'Se tiene que asociar al cliente',
        confirm: 'Editar cliente',
        confirmClass: 'danger-button',
        cancel: 'Cancelar ',
        unitId: item._id,
        name:item.name,
        description: item.description,
        companyId: item.company._id,
        type:'ACTUALIZAR'
      }
    });
    confirmRefUpdateClient.afterClosed().subscribe(result => {
      if(result !== undefined){
        this.snackBar.open('Unidad Actualizada '+result.name, '', {
          duration: 3000, verticalPosition: 'bottom',
          panelClass: 'notif-success'
        });
        this.reloadRouter()
      }
    });
  }

  didTapDelete(item:Units){
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirmar',
        body: '¿Está seguro de eliminar a: ' + item.name + ' ?',
        confirm: 'Borrar',
        confirmClass: 'danger-button',
        cancel: 'Cancelar'
      }
    });
    confirmRef.afterClosed().subscribe(result => {
      if (result) {
        this.restApiService.deleteUnit(item._id).subscribe(
            response => {
              if( response.success){
                this.deleteRowData(item);
                this.snackBar.open('Se elimino la unidad: '+item.name, '', {
                  duration: 3000, verticalPosition: 'bottom',
                  panelClass: 'notif-success'
                });
              } else {
                this.displayError()
              }
            }
        );
      }
    });
  }
  deleteRowData(rowobj: Units) {
    const index: number = this.items.findIndex(d => d === rowobj);
    this.items.splice(index, 1);
    this.dataSource = new MatTableDataSource<Units>(this.items);
  }
  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
}
