import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Ubication} from "./model/Ubication";
import {Company} from "../companies/model/company";
import {RestApiService} from "../../shared/rest-api.service";
import {
  InputEditDataGeneralDialogComponent
} from "../../_dialog/input-edit-data-general-dialog/input-edit-data-general-dialog.component";
import {ConfirmDialogComponent} from "../../_dialog/confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-ubications',
  templateUrl: './ubications.component.html',
  styleUrls: ['./ubications.component.css']
})

export class UbicationsComponent implements OnInit  {
  @ViewChild('dataTable') dataTable?: MatTable<Company>;
  items: Ubication[] = [];
  dataSource: MatTableDataSource<Ubication> = new MatTableDataSource<Ubication>(this.items);
  displayedColumns: string[] = [ 'id', 'name','descripcion','company', 'actions'];

  constructor(private restApiService: RestApiService,
              private router :Router,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,) {
  }
  ngOnInit(): void {
    this.loadData();
  }
  public loadData() {
    this.restApiService.getUbications().subscribe({
      next: (response) => {
        if (response.success){
          this.items = response.payload;
          this.dataSource.data = this.items;
        }else{
          this.snackBar.open('Email  o Contraseña incorrecta', '', {
            duration: 3000, verticalPosition: 'bottom',
            panelClass: 'notif-success'
          });
        }
      },
      error: (error) => {
        this.displayError()
      }
    });
  }
  onSelectUbication(item: Ubication) {
    const confirmRefUpdateClient = this.dialog.open(InputEditDataGeneralDialogComponent, {
      data: {
        ubicationId:item.id,
        companyId:item.company?.id,
        description: item.description,
        name:item.name,
        type: 'ACTUALIZAR'
      }
    });
    confirmRefUpdateClient.afterClosed().subscribe(result => {
      if(result !== undefined){
        this.snackBar.open('Ubicación Actualizada', '', {
          duration: 3000, verticalPosition: 'bottom',
          panelClass: 'notif-success'
        });
        this.reloadRouter()
      }
    });
  }
  onSelectCreateUbication() {
    const confirmRefUpdateClient = this.dialog.open(InputEditDataGeneralDialogComponent, {
      data: {
        ubicationId:"",
        companyId:"",
        description: "",
        name: "",
        type: 'REGISTRAR'
      }
    });
    confirmRefUpdateClient.afterClosed().subscribe(result => {
      if(result !== undefined){
        this.snackBar.open('Ubicación Creada', '', {
          duration: 3000, verticalPosition: 'bottom',
          panelClass: 'notif-success'
        });
        this.reloadRouter()
      }
    });
  }
  onDelete( path: Ubication) {
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirmar',
        body: '¿Está seguro de eliminar a: ' + path.name + ' ?',
        confirm: 'Borrar',
        confirmClass: 'danger-button',
        cancel: 'Cancelar'
      }
    });
    confirmRef.afterClosed().subscribe(result => {
      if (result) {
        this.restApiService.deleteUbication(path.id).subscribe(
          response => {
            if (response.success){
              this.snackBar.open('Se elimino ubicación: ' + path.name, '', {
                duration: 3000, verticalPosition: 'top',
                panelClass: 'notif-success'
              });
              this.deleteRowData(path);
            }else{
              this.displayError()
            }
          }
        );
      }
    });
  }
  deleteRowData(rowobj: Company) {
    const index: number = this.items.findIndex(d => d === rowobj);
    this.items.splice(index, 1);
    this.dataSource = new MatTableDataSource<Company>(this.items);
  }
  assertItemType(item: Company): Ubication {
    return item;
  }
  reloadRouter() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl);
    });
  }
  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
}
