import {Component, OnInit, Output} from '@angular/core';
import {DetailsUnit} from "../unit/detail-unit/model/detailsUnit";
import {Units} from "../unit/units/model/units";
import {RestApiService} from "../../shared/rest-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatTabChangeEvent} from "@angular/material/tabs";
@Component({
  selector: 'app-info-monitor',
  templateUrl: './info-monitor.component.html',
  styleUrls: ['./info-monitor.component.css'],
})
export class InfoMonitorComponent implements OnInit{
  selectedTab:number = 0;
  @Output() items: DetailsUnit[] = []
  @Output() infoUnit?: Units
  id: string | undefined

  constructor(private restApiService: RestApiService,
              private snackBar: MatSnackBar,
              private activatedRouter: ActivatedRoute,) {

  }
  ngOnInit(): void {
    this.activatedRouter.params.subscribe(params => {
      this.id = params['id'];
      this.restApiService.getDetailsActiveUnits(this.id).subscribe({
        next: (response) => {
          if (response.success){
            this.items = response.payload.listActiveTemplateEntity
            this.infoUnit = response.payload.unitInfo
          }else{
            this.displayError()
          }
        },
        error: (error) => {
          this.displayError()
        }
      });

    });
    }
  onChange(data:any){
    this.selectedTab = data.index

  }
  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
  receiveValue($event: string) {
    // Handle the emitted value
  }
  onTabChange(event: MatTabChangeEvent) {
   // this.appService.currentTabIndex = event.index
  }
}
