<div class="grid p-1 m-1">
  <div class="col-12">
    <div class="flex flex-column sm:flex-row align-items-center gap-3">
      <div class="flex flex-column sm:flex-row align-items-center gap-3">
        <div class="flex flex-column align-items-center sm:align-items-start">
          <span class="text-900 font-bold text-2xl">Módulo BIM</span>
          <span class="text-500 text-md">Importar Modelo</span>
        </div>
      </div>
    </div>
  </div>
  <div #bimContainer class="canvas-container m-0 p-0"></div>
</div>
<p-toast position="bottom-right"/>