<!DOCTYPE html>
<html>
<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/css/bootstrap.min.css">
</head>
<body>
    <div fxFlex="95" fxLayout="column" style="margin: 5px;font-family: Roboto, sans-serif!important;font-size:20px !important;">

        <div fxLayout="row">
            <div>
                <h3 class="m-3">Nuevo Activo 🆕</h3>
            </div>
        </div>
        <div class ="m-3">
        <form class="form-group" [formGroup]="formGroup">
        <mat-form-field class="w-50">
            <mat-label>Nombre</mat-label>
            <input matInput id="nombre_activo" type="text" formControlName="nombre_activo" required>
        </mat-form-field>

        <div id="mapa-creacion">
            <h4  class ="m-3" > Mapa y ubicación 🗺️</h4>
            <div style="height: 600px;"
                 id="map"
                 leaflet
                 [leafletOptions]="options"
                 [leafletLayersControl]="layersControl"
                 (leafletMapReady)="onMapReady($event)"  >
            </div>

        </div>
        <div fxLayout="column" class="mt-4">
            <div fxLayout = "row" fxFlex="95">
                  <mat-label class="m-3 elegant-label">
                     <span class="icon-label">
                         <mat-icon class="svg" svgIcon="map-marker-outline"></mat-icon>
                            Longitud
                     </span>
                 </mat-label>

                <mat-form-field appearance="fill" style="width: 100%">
                    <input matInput id="longitud" formControlName="longitud">
                </mat-form-field>

                <mat-label class="m-3 elegant-label">
                     <span class="icon-label">
                        <mat-icon class="svg" svgIcon="map-marker-outline"></mat-icon>
                        Latitud
                    </span>
                </mat-label>

                <mat-form-field appearance="fill" style="width: 100%">
                    <input matInput id="latitud" formControlName="latitud">
                 </mat-form-field>

            </div>
                <div fxLayout = "column" class="m-3" >
                    <mat-label class="elegant-label">
                        <span class="icon-label">
                            <mat-icon class="svg" svgIcon="text-short"></mat-icon>
                            Descripción
                        </span>
                    </mat-label>

                    <mat-form-field class ="m-3" appearance="fill" style="width: 100%">
                        <input matInput id="descripcion" formControlName="descripcion" >
                    </mat-form-field>
                </div>
        <div>
            <mat-checkbox color ="primary" id="es_activo" [formControl]="isCheckedControl" name="es_activo">
                Es activo
            </mat-checkbox>
        </div>
            <div fxLayout="row" fxLayoutAlign="space-between">
                <div class="tipo_activo m-3"  fxFlex="50%">

                    <div fxLayout="column">
                        <div>
                            <mat-label class="elegant-label">
                        <span class="icon-label">
                            <mat-icon class="svg" svgIcon="format-list-numbered"></mat-icon>
                            Pertenece a
                        </span>
                            </mat-label><br>
                            <mat-form-field class="full-width m-2">
                                <mat-label class="m-4">
                            <span class="icon-label">
                                Activo
                            </span>
                                </mat-label>
                                <mat-select class="full-width" id="Activo" name="Activo" [(value)]="selectedActiveId">
                                    <mat-option *ngFor="let active of items" [value]="active._id">{{ active.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="m-1">
                            <button    class="m-4"  mat-raised-button (click)="didTapCreate()" [disabled]="loading"  style=" width: 250px;  height:50px; border-radius: 15px; margin-left: 10px;background-color: #2196F3; color:white">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                <div >
                                    Continuar  y Crear
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="tipo_activo m-3" *ngIf="isCheckedControl.value" fxFlex="50%">

                    <div>
                        <div>
                            <mat-label class="elegant-label">
                        <span class="icon-label">
                            <mat-icon class="svg" svgIcon="format-list-numbered"></mat-icon>
                            Tipo de Activo
                        </span>
                            </mat-label><br>
                            <mat-form-field class="full-width m-2">
                                <mat-label class="m-4">
                            <span class="icon-label">
                                Tipo
                            </span>
                                </mat-label>
                                <mat-select class="full-width" id="Tipo" name="Tipo" [(value)]="selectedTipo">
                                    <mat-option *ngFor="let tipo of tipos" [value]="tipo._id">{{ tipo.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="m-1">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </form>
        <input type="hidden" id="pertenece_nombre" name="pertenece_nombre">
        <input type="hidden" id="tipo_nombre" name="tipo_nombre">

        </div>
    </div>
</body>
</html>
