<ngx-loading-bar></ngx-loading-bar>

<div class="absolute justify-content-center">
  <p-speedDial
    [model]="menuItems"
    direction="down"
    type="linear"
    transitionDelay="50"
    showIcon="pi pi-spin pi-cog"
    hideIcon="pi pi-times"
    buttonClassName="p-button-outlined"
  />
</div>
<div #chartContainer class="flex" style="height: 75vh;"></div>