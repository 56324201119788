<p-tabView>
  <p-tabPanel header="Detalles" >
    <ng-template pTemplate="header">
      <div class="flex align-items-center gap-2" >
        <i class="pi pi-list"></i>
        <span class="font-bold white-space-nowrap m-0"> Inventario </span>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <app-detail-unit></app-detail-unit>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Vista Árbol">
    <ng-template pTemplate="header">
      <div class="flex align-items-center gap-2">
        <i class="pi pi-sitemap"></i>
        <span class="font-bold white-space-nowrap m-0"> Árbol </span>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <app-info-monitor-go-js
        [items]="items"
        [infoUnit]="infoUnit"
      ></app-info-monitor-go-js>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Mapa" >
    <ng-template pTemplate="header">
      <div class="flex align-items-center gap-2">
        <i class="pi pi-map"></i>
        <span class="font-bold white-space-nowrap m-0"> Mapa </span>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <app-info-monitor-map
        [items]="items"
        [infoUnit]="infoUnit"
        (selectionchange)="onChange($event)"
      ></app-info-monitor-map>
    </ng-template>
  </p-tabPanel>
</p-tabView>
