import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {RestApiService} from "../../../shared/rest-api.service";
import {Type} from "../../create/create-asset/Type";
import {InputTemplateData} from "./InputTemplateData";
import {ConfirmDialogComponent} from "../../../_dialog/confirm-dialog/confirm-dialog.component";
import {InputTemplateDataInfo} from "./InputTemplateInfo";

@Component({
  selector: 'app-show-template-asset',
  templateUrl: './show-template-asset.component.html',
  styleUrl: './show-template-asset.component.css'
})
export class ShowTemplateAssetComponent implements OnInit {
  @ViewChild('dataTable') dataTable?: MatTable<InputTemplateData>;
  selectedTipo?: string =""
  isDisabledEdit:boolean = true
  inputTemplateData: InputTemplateData[] = []; // Define the campos array to store the column values
  displayedColumns: string[] = ['name', 'type', 'obligatory', 'isHidden', 'notation']; // Define the displayedColumns array to store the column names
  tipos: Type[] = []; // Define a property to store the options
  nombre: string = '';
  tipo: string = ''; // Define a property to store the selected value
  visible: boolean = false; // Define a property to store the checkbox value
  templateId :string = ''
  @ViewChild(MatTable) table?: MatTable<any>;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  dataSource = new MatTableDataSource<InputTemplateData>(this.inputTemplateData);
  constructor(private restApiService: RestApiService,
              private snackBar: MatSnackBar,
              private router: Router,
              private dialog: MatDialog,
              private activatedRouter: ActivatedRoute) {
    this.activatedRouter.params.subscribe(params => {
       this.templateId = params['id'];
      this.loadData(this.templateId)
    });
  }
  ngOnInit() {
    this.fetchTipos();
    this.dataSource.data = this.inputTemplateData;
  }

  fetchTipos() {
    this.restApiService.getAllActiveType().subscribe({
      next: (response) => {
        if (response.success){
           this.tipos = response.payload
        }else{
          this.displayError()
        }
      },
      error: (error) => {
        this.displayError()
      }
    });
  }
  drop(event: CdkDragDrop<MatTableDataSource<InputTemplateData, MatPaginator>, any>) {
    const previousIndex = this.dataSource.data.findIndex(row => row === event.item.data);
    moveItemInArray(this.dataSource.data,previousIndex, event.currentIndex);
    this.dataTable?.renderRows();
  }

  public loadData(id: string) {
    this.restApiService.getTemplateAsset(id).subscribe({
      next: (response) => {
        if (response.success) {
          this.inputTemplateData = response.payload.inputTemplate ?? []
          this.selectedTipo = response.payload.type?._id
          this.nombre = response.payload.name ?? ""
          this.dataSource.data =  this.inputTemplateData;
        } else {
          this.displayError()
        }
      },
      error: (error) => {
        this.displayError()
      }
    });
  }
  titleHeaderCell(column:string){
    switch (column) {
      case 'name': return 'NOMBRE'
      case 'obligatory': return 'OBLIGATORIO'
      case 'isHidden': return 'OCULTA'
      case 'notation': return 'ANOTACION'
      case 'action': return 'ACCION'
      default: return ''
    }
  }
  textButtonEdit(){
    if(this.isDisabledEdit){
      return "Editar Datos"
    }
    return "Guardar Cambios"
  }
  didTapSaveChanged(){
    if(this.isDisabledEdit){
      this.snackBar.open('Puede editar los datos', '', {
        duration: 3000, verticalPosition: 'bottom',
        panelClass: ['blue-snackbar']
      });
      this.isDisabledEdit = !this.isDisabledEdit
    } else {

      let template = this.extractTableValues()
      if (this.nombre.length === 0) {
        this.snackBar.open('Falta Definir Nombre', '', {
          duration: 3000, verticalPosition: 'top',
          panelClass: 'notif-success'
        });
      } else if (this.selectedTipo === undefined) {
        this.snackBar.open('Falta definir tipo de plantilla', '', {
          duration: 3000, verticalPosition: 'top',
          panelClass: 'notif-success'
        });
      } else if (template.length === 0) {
        this.snackBar.open('Falta agregar campos', '', {
          duration: 3000, verticalPosition: 'top',
          panelClass: 'notif-success'
        });
      } else {
        const confirmCreateRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            title: 'Confirmar',
            body: '¿Desea actualizar plantilla: ' + this.nombre + ' ?',
            confirm: 'Actualizar',
            confirmClass: 'danger-button',
            cancel: 'Cancelar'
          }
        });
        confirmCreateRef.afterClosed().subscribe(result => {
          if (result) {
            this.restApiService.editTemplateAsset(this.templateId,this.dataSource.data, this.selectedTipo ?? "", this.nombre).subscribe({
              next: (response) => {
                if (response.success) {
                  this.snackBar.open('Se agrego plantilla', '', {
                    duration: 3000, verticalPosition: 'bottom',
                    panelClass: ['blue-snackbar']
                  });
                  this.router.navigate(['/lista-plantillas']);
                } else {
                  this.displayError()
                }
              },
              error: (error) => {
                this.displayError()
              }
            });
          }
        });
      }
    }
  }
  extractTableValues() {
    let tableValues: any[];
    tableValues = [];
    this.dataSource.data.forEach((row) => {
      const rowValues = {};
      this.displayedColumns.forEach((column) => {
        // @ts-ignore
        rowValues[column] = row[column];
      });
      tableValues.push(rowValues);
    });
    return tableValues;
  }
  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
}
