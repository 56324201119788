import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DetailsUnit } from '../../unit/detail-unit/model/detailsUnit';
import { Units } from '../../unit/units/model/units';
import { OrgChart } from 'd3-org-chart';
import { OrgChartData } from './model/OrgChartData';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ViewAssetDialogComponent } from 'src/app/_dialog/view-asset-dialog/view-asset-dialog.component';

@Component({
  selector: 'app-info-monitor-go-js',
  templateUrl: './info-monitor-go-js.component.html',
  styleUrl: './info-monitor-go-js.component.css',
  encapsulation: ViewEncapsulation.None,
  providers : [DialogService]
})

export class InfoMonitorGoJSComponent implements OnChanges {
  @Input() infoUnit?: Units;
  @Input() items: DetailsUnit[] = [];
  @Input() showAssetInfo?: boolean = true;
  @Input() highlightBim?: boolean = false;
  chart?: OrgChart<OrgChartData>;
  @ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef;
  menuItems!: MenuItem[] | null;
  ref: DynamicDialogRef | undefined;

  constructor(public dialogService: DialogService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.menuItems = [
      {
        tooltipOptions: {
          tooltipLabel: 'Vista Horizontal',
        },
        icon: 'pi pi-ellipsis-h',
        command: () => {
          this.didTapHorizontal();
        },
      },
      {
        tooltipOptions: {
          tooltipLabel: 'Vista Vertical',
        },
        icon: 'pi pi-ellipsis-v',
        command: () => {
          this.didTapVertical();
        },
      },
      {
        tooltipOptions: {
          tooltipLabel: 'Auto Zoom',
        },
        icon: 'pi pi-search',
        command: () => {
          this.didTapAutoZoom();
        },
      },
      {
        tooltipOptions: {
          tooltipLabel: 'Acercar',
        },
        icon: 'pi pi-search-plus',
        command: () => {
          this.didTapZoomIn();
        },
      },
      {
        tooltipOptions: {
          tooltipLabel: 'Alejar',
        },
        icon: 'pi pi-search-minus',
        command: () => {
          this.didTapZoomOut();
        },
      },
    ];

    const data: OrgChartData[] = [
      {
        is_active: 'off',
        id: 'node_' + this.infoUnit?._id ?? '',
        parentId: '',
        name: this.infoUnit?.name ?? '',
        imageUrl: 'LIND_icon_unit',
        root: true,
        has_bim: false,
      },
    ];

    this.chart = new OrgChart<OrgChartData>()
      .container(this.chartContainer.nativeElement)
      .data(data)
      .nodeHeight((d) => 35 + 25)
      .nodeWidth((d) => 220 + 2)
      .childrenMargin((d) => 50)
      .compactMarginBetween((d) => 35)
      .compactMarginPair((d) => 30)
      .neighbourMargin((a, b) => 20)
      .defaultFont('Poppins')
      .nodeContent(function (d: any, i, arr, state) {
        return `
            <div class="p-component surface-card shadow-1 hover:shadow-4 border-round border-2 ${
              d.data.is_active === 'off'
                ? 'border-cyan-400 border-dashed text-cyan-400'
                : 'border-primary text-primary font-semibold'
            }  flex align-items-center justify-content-center">
                <img class="inline-block mr-3" src="/../assets/icons/${
                  d.data.imageUrl
                }.svg" width="25px"/>

                <p class="text-base"> 
                ${d.data.name} 
                </p>
           </div>`;
      })
      .onNodeClick((d) => {
        if (this.showAssetInfo) {
          const assetID = d.data.id.split('node_')[1];
          this.showDynamicElement(
          assetID,
          d.data.name,
          d.data.is_active,
          d.data.root
          );
        }
        if(this.highlightBim && d.data.has_bim){
          const assetID = d.data.id.split('node_')[1];
          this.didTapBIM(assetID);
        }
      });

    this.chart.render();
    for (const fila of this.items) {
      let key_id_activo = fila._id;
      let activo_padre_id = fila.belong_to;
      if (activo_padre_id === this.infoUnit?._id) {
        activo_padre_id = this.infoUnit?._id;
      }
      if (fila.is_active === 'off') {
        this.chart.addNode({
          is_active: 'off',
          id: 'node_' + key_id_activo,
          parentId: 'node_' + activo_padre_id,
          name: fila.name ?? '',
          imageUrl: 'LIND_icon_zone',
          root: false,
          has_bim: fila.hasBIM || false,
        });
      } else if (fila.is_active === 'on') {
        this.chart.addNode({
          is_active: 'on',
          id: 'node_' + key_id_activo,
          parentId: 'node_' + activo_padre_id,
          name: fila.name ?? '',
          imageUrl: 'LIND_icon_asset',
          root: false,
          has_bim: fila.hasBIM || false,
        });
      }
      if (this.highlightBim && fila.hasBIM) {
        this.chart.setHighlighted('node_' + key_id_activo);
      }    
    }
    this.chart.compact(false).render();
    this.chart?.allowedNodesCount(true);
    this.chart?.setCentered('node_' + this.infoUnit?._id).render();
    this.chart?.svgHeight(window.innerHeight - window.innerHeight * 0.2);
    this.chart?.expandAll().fit();
  }

  didTapHorizontal() {
    this.chart?.compact(false).render().fit();
  }

  didTapVertical() {
    this.chart?.compact(true).render().fit();
  }

  didTapAutoZoom() {
    this.chart?.fit();
  }

  didTapZoomIn() {
    this.chart?.zoomIn();
  }
  didTapZoomOut() {
    this.chart?.zoomOut();
  }

  showDynamicElement(
    assetID: string,
    name: string,
    is_active: string,
    root: boolean
  ) {
    if (root) {
      return;
    }

    const headerDialog = 'Detalle de '.concat(
      is_active === 'on' ? 'Activo' : 'Zona'
    );

    this.ref = this.dialogService.open(ViewAssetDialogComponent, {
      header: headerDialog,
      width: '90vw',
      height: '90vh',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      modal: true,
      data: {
        assetID: assetID,
        fromMap: false,
      },
    });
  }

  didTapBIM(assetId: string) {
    const url = "https://2024001-01-visor-ifc-git-main-linddevelopers-projects.vercel.app/editor.html?id=" + assetId;
    window.open(url, "_blank");
  }
}
