import { Component, Input, OnChanges, OnInit } from '@angular/core';
import L, { Icon, icon, latLng, Map } from 'leaflet';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ViewAssetDialogComponent } from 'src/app/_dialog/view-asset-dialog/view-asset-dialog.component';

@Component({
  selector: 'app-assets-map',
  templateUrl: './assets-map.component.html',
  styleUrl: './assets-map.component.css',
  providers: [DialogService]
})
export class AssetsMapComponent implements OnChanges {
  initMap?: Map;
  zoomLevel: number = 5;
  @Input() data: any;
  loading: boolean = true;
  markers: any = [];
  ref: DynamicDialogRef | undefined;

  streetMaps = L.tileLayer(
    'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    {
      detectRetina: true,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }
  );
  arcgisMaps = L.tileLayer(
    'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    {
      detectRetina: true,
      attribution:
        '&copy; <a href="http://www.esri.com/">Esri</a>, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community ',
    }
  );

  layersControl = {
    baseLayers: {
      'ArcGIS Map': this.arcgisMaps,
      'Street Map': this.streetMaps,
    },
    overlays: {},
  };

  options = {
    layers: [this.arcgisMaps],
    zoom: 5,
    popupAnchor: [13, 0],
    center: latLng(-35.675147, -71.542969)
  };

  constructor(public dialogService: DialogService) { }

  ngOnChanges() {
    this.loading = true;
    this.refreshMap();
    this.setMarkers();
    this.loading = false;
  }
  
  onMapReady(map: Map) {
    this.initMap = map;
    this.setMarkers();
    this.loading = false;
  }

  refreshMap() {
    if (this.initMap) {
      setTimeout(() => {
        this.initMap!.invalidateSize();
      }, 200); 
    }
  }

  setMarkers() {
    if (this.data && this.initMap) {
      this.markers.forEach((marker: any) => {
        if (this.initMap) {
          this.initMap.removeLayer(marker);
        }
      });
      this.markers = [];
      
      const bounds = L.latLngBounds([]);
      
      this.data.forEach((element: any) => {
        const marker = L.marker([element.latitude, element.longitude],
          {
            icon: icon({
              ...Icon.Default.prototype.options,
              iconUrl: 'assets/leaflet/marker-icon.png',
              iconRetinaUrl: 'assets/leaflet/marker-icon-2x.png',
              shadowUrl: 'assets/leaflet/marker-shadow.png',
            }),
          })
          .bindTooltip(`${element.name}`, {
            permanent: true,
            direction: 'bottom',
            className: 'marker-tooltip',
            offset: L.point(-5, 25)
          })
          .on('click', () => {
            this.showDynamicElement(element._id);
          })
          .addTo(this.initMap!);
        this.markers.push(marker);
        
        bounds.extend(marker.getLatLng());
      });

      if (this.markers.length > 0) {
        this.initMap.fitBounds(bounds, { padding: [30, 30], maxZoom: 16 });
      }
    }
  }

  showDynamicElement(assetID: string) {
    const headerDialog = 'Detalle de Activo';

    this.ref = this.dialogService.open(ViewAssetDialogComponent, {
      header: headerDialog,
      width: '90vw',
      height: '90vh',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      modal: true,
      data: {
        assetID: assetID,
        fromMap: true,
      },
    });
  }


}
