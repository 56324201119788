import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BehaviorSubject, Observable} from 'rxjs';
import {AuthenticationService} from "../../shared/authentication.service";
import {Router} from "@angular/router";
import {User} from "../user/user-list/model/User";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  private currentUserSubject?: BehaviorSubject<User>;
  public currentUser?: Observable<User>;
  error = '';
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private snackBar: MatSnackBar,
  ) {
    this.loginForm = this.formBuilder.group({
      email: [''],
      password: ['']
    });

    if (localStorage.getItem('currentUser')){
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser') ?? ""));
      this.currentUser = this.currentUserSubject.asObservable();
    }
  }
  ngOnInit(): void {
  }
  onSubmit(){
    this.submitted = true;
    // tslint:disable-next-line:max-line-length
    const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const email: string = this.loginForm?.get('email')?.value ?? ""
    const password: string = this.loginForm?.get('password')?.value ?? ""
    const isValidEmail = regEx.test(email)
    if (isValidEmail) {
      this.loading = true;
      this.authenticationService.login(email, password).subscribe({
        next: (response) => {
          this.loading = false;
          if (response.success){
            this.router.navigate(['/inicio']);
          }else{
            this.snackBar.open('Email  o Contraseña incorrecta', '', {
              duration: 3000, verticalPosition: 'bottom',
              panelClass: 'notif-success'
            });
          }
        },
        error: (error) => {
          this.error = error;
          this.loading = false;
          this.snackBar.open('Error intentarlo de nuevo', '', {
            duration: 3000, verticalPosition: 'bottom',
            panelClass: ['blue-snackbar']
          });
          this.router.navigate(['/logear']);
        }
      });

    } else {
      this.snackBar.open('Email incorrecto', '', {
        duration: 3000, verticalPosition: 'bottom',
        panelClass: 'notif-success'
      });
    }
  }
}
