import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./Views/login/login.component";
import {MainViewComponent} from "./Views/main-view/main-view.component";
import {UserListComponent} from "./Views/user/user-list/user-list.component";
import {NgxPermissionsGuard} from "ngx-permissions";
import {NoAccessComponent} from "./Views/no-access/no-access.component";
import {EmbedUrlMonitorComponent} from "./Views/monitor/embed-url-monitor/embed-url-monitor.component";
import {InfoMonitorComponent} from "./Views/info-monitor/info-monitor.component";
import {UnitsComponent} from "./Views/unit/units/units.component";
import {CreateTemplateAssetComponent} from "./Views/create/create-template-asset/create-template-asset.component";
import {ListTemplatesAssetComponent} from "./Views/list-templates-asset/list-templates-asset.component";
import {ShowTemplateAssetComponent} from "./Views/show/show-template-asset/show-template-asset.component";
import {CreateAssetComponent} from "./Views/create/create-asset/create-asset.component";
import {ShowDetailUnitComponent} from "./Views/show/show-detail-unit/show-detail-unit.component";
import {ShowTemplateActiveComponent} from "./Views/show/show-template-active/show-template-active.component";
import {InfoMonitorGoJSComponent} from "./Views/info-monitor/info-monitor-go-js/info-monitor-go-js.component";
import {CompaniesComponent} from "./Views/companies/companies.component";
import {MonitorsComponent} from "./Views/monitor/monitors/monitors.component";
import {UbicationsComponent} from "./Views/ubications/ubications.component";
import {authGuardGuard} from "./guard/auth-guard.guard";
import { DashboardComponent } from './Views/dashboard/dashboard.component';
import { BimComponent } from './Views/bim/bim.component';
import { WIPComponent } from './Views/wip/wip.component';
import { BimViewerComponent } from './Views/bim-viewer/bim-viewer.component';
import { BimLoaderComponent } from './Views/bim-loader/bim-loader.component';

const routes: Routes = [
  { path: '',    component: MainViewComponent,
    children: [
      {
        path: 'bim',
        component: BimComponent,
        canActivate: [authGuardGuard],
        data: {
          permissions: { only: ['ADMIN', 'MASTER','USER'], redirectTo: '/no-acceso' }
        },
      },
      {
        path: 'bim-viewer',
        component: BimViewerComponent,
        canActivate: [authGuardGuard],
        data: {
          permissions: { only: ['ADMIN', 'MASTER','USER'], redirectTo: '/no-acceso' }
        },
      },
      {
        path: 'bim-loader',
        component: BimLoaderComponent,
        canActivate: [authGuardGuard],
        data: {
          permissions: { only: ['ADMIN', 'MASTER','USER'], redirectTo: '/no-acceso' }
        },
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [authGuardGuard],
        data: {
          permissions: { only: ['ADMIN', 'MASTER', 'VISIT', 'USER'], redirectTo: '/no-acceso' }
        },
      },
      { path: 'monitor/:id', component: EmbedUrlMonitorComponent ,
        pathMatch: 'full'},
      { path: 'usuarios', component: UserListComponent ,
        canActivate: [authGuardGuard],
        data: {
          permissions: { only: ['ADMIN', 'MASTER'], redirectTo: '/no-acceso' }
        }, pathMatch: 'full'},
      { path: 'empresas',
        component: CompaniesComponent ,
        canActivate: [authGuardGuard],
        data: {
          permissions: { only: ['ADMIN', 'MASTER'] , redirectTo: '/no-acceso'}
        },
        pathMatch: 'full' },
      { path: 'monitores', component: MonitorsComponent ,
        canActivate: [authGuardGuard],
        data: {
          permissions: { only: ['ADMIN', 'MASTER', 'VISIT', 'USER']}
        }, pathMatch: 'full'},

      { path: 'info-monitores', component: InfoMonitorComponent ,
        canActivate: [authGuardGuard],
        data: {
          permissions: { only: ['ADMIN', 'MASTER', 'VISIT', 'USER']}
        }, pathMatch: 'full'},

      { path: 'ubicaciones',
        component: UbicationsComponent ,
        canActivate: [authGuardGuard],
        data: {
          permissions: {
            only: ['ADMIN', 'MASTER'], redirectTo: '/no-acceso'
          }
        },
        pathMatch: 'full'},
      { path: 'crear-plantilla',
        component: CreateTemplateAssetComponent ,
        canActivate: [authGuardGuard],
        data: {
          permissions: {
            only: ['ADMIN', 'MASTER'], redirectTo: '/no-acceso'
          }
        },
        pathMatch: 'full'},
      { path: 'lista-plantillas',
        component: ListTemplatesAssetComponent,
        canActivate: [authGuardGuard],
        data: {
          permissions: {
            only: ['ADMIN', 'MASTER'], redirectTo: '/no-acceso'
          }
        },
        pathMatch: 'full'},
      {
        path: 'info-plantilla/:id',
        component: ShowTemplateAssetComponent,
        canActivate: [authGuardGuard],
        data: {
          permissions: {
            only: ['ADMIN', 'MASTER'], redirectTo: '/no-acceso'
          }
        },
        pathMatch: 'full'},
      {
        path: 'lista-unidades',
        component: UnitsComponent,
        canActivate: [authGuardGuard],
        data: {
          permissions: {
            only: ['ADMIN', 'MASTER'], redirectTo: '/no-acceso'
          }
        },
        pathMatch: 'full'},

      {
        path: 'detalle-unidad/:id',
        component:  InfoMonitorComponent,
        canActivate: [authGuardGuard],
        data: {
          permissions: {
            only: ['ADMIN', 'MASTER'], redirectTo: '/no-acceso'
          }
        },
        pathMatch: 'full'},
      {
        path: 'crear-activo/:id',
        component: CreateAssetComponent,
        canActivate: [authGuardGuard],
        data: {
          permissions: {
            only: ['ADMIN', 'MASTER'], redirectTo: '/no-acceso'
          }
        },
        pathMatch: 'full'},
      {
        path: 'mostrar-detalle-unidad/:id',
        component: ShowDetailUnitComponent,
        canActivate: [authGuardGuard],
        data: {
          permissions: {
            only: ['ADMIN', 'MASTER'], redirectTo: '/no-acceso'
          }
        },
        pathMatch: 'full'},
      {
        path: 'mostrar-plantilla-activo/:id',
        component: ShowTemplateActiveComponent,
        canActivate: [authGuardGuard],
        data: {
          permissions: {
            only: ['ADMIN', 'MASTER'], redirectTo: '/no-acceso'
          }
        },
        pathMatch: 'full'},
      {
        path: 'show-gojs',
        component: InfoMonitorGoJSComponent,
        canActivate: [authGuardGuard],
        data: {
          permissions: {
            only: ['ADMIN', 'MASTER'], redirectTo: '/no-acceso'
          }
        },
        pathMatch: 'full'},
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      { path: 'wip' , component: WIPComponent, pathMatch: 'full'}
    ]},
  { path: 'no-acceso', component: NoAccessComponent , pathMatch: 'full'},
  { path: 'logear', component: LoginComponent , pathMatch: 'full'},
  { path: '**', redirectTo: '' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
