import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Monitor} from "../monitors/model/Monitor";
import {RestApiService} from "../../../shared/rest-api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-embed-url-monitor',
  templateUrl: './embed-url-monitor.component.html',
  styleUrls: ['./embed-url-monitor.component.css']
})
export class EmbedUrlMonitorComponent implements OnInit {
  itemId?: string;
  item?:Monitor
  showIframe:Boolean = false
  monitorUrl?: string
  constructor(private restApiService: RestApiService,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute) { }
  ngOnInit() {
    this.itemId = this.route.snapshot.paramMap.get('id') ?? "";
    this.restApiService.getMonitor(this.itemId).subscribe({
      next: (response) => {
        if (response.success){
          this.item = response.payload
          this.showIframe = true
          this.monitorUrl = this.item.url
        }else{
          this.displayError()
        }
      },
      error: (error) => {
       this.displayError()
      }
    });
  }
  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
}
