<!DOCTYPE html>
<html>
<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/css/bootstrap.min.css">
</head>
<body>
<mat-dialog-content>
    <div class="m-3">
        <div fxFlex="95" fxLayout="column" style="margin: 5px;font-family: Roboto, sans-serif!important;font-size:20px !important;">
            <div fxFlex="95" fxLayout="column">
                <h3 class="mat-h1">Selecionar plantilla para Activo</h3>
                <mat-form-field class="mr-2">
                    <mat-label>Seleccionar plantilla</mat-label>
                    <mat-select [(value)]="templateSelectedId" (selectionChange)="onSelect($event)">
                        <mat-option *ngFor="let tipo of templates" [value]="tipo">
                            {{ tipo.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div>
                    <div *ngFor="let campo of inputTemplateData; let i = index ">
                        <div *ngIf="campo.type === 'Selector'">
                            <mat-form-field class="m-2">
                                <mat-label>{{campo.name}}</mat-label>
                                <mat-select (selectionChange)="onChangeSelector($event,campo)">
                                    <mat-option *ngFor="let option of createOptionSelect(campo)" [value]="option">
                                        {{ option }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="campo.type === 'Checkbox'">
                            <mat-checkbox color="primary" class="m-2" (change)="onChangeCheckBox($event,campo)">{{campo.name}}</mat-checkbox>
                        </div>
                        <div *ngIf="campo.type === 'Number'">
                            <mat-form-field class="m-2">
                                <mat-label>{{campo.name}}</mat-label>
                                <input type="number" matInput  class="form-control" placeholder=""  name="name"  required (change)="onTextChange($event,campo)">
                            </mat-form-field>
                        </div>
                        <div *ngIf="campo.type === 'Text'">
                            <mat-form-field class="m-2">
                                <mat-label>{{campo.name}}</mat-label>
                                <input type="text" matInput  class="form-control" placeholder=""  name="name"  required (change)="onTextChange($event,campo)">
                            </mat-form-field>
                        </div>
                        <div *ngIf="campo.type === 'Photos'">
                            <div  fxLayout="column" >
                                <h4  class ="m-2  title m-t-3 "  fxFlex="none"> {{ campo.name?.toUpperCase() }} 📷</h4>

                                <div (click)="uploadFile.click()" class="m-2" >
                                    <button mat-raised-button style="width: 250px;  height:50px; border-radius: 15px; margin-left: 10px;background-color: #2196F3; color:white">
                                        <mat-icon class = "svg" svgIcon="upload-outline"></mat-icon>
                                        Subir imagen 📷</button>
                                    <input #uploadFile (change)="upload($event,campo,i)" type='file' accept="image/*" style="display:none"/>
                                </div>
                            </div>
                            <div fxLayout="column" >
                                <ng-container *ngIf="inputTemplateData && inputTemplateData[i].dataSourcePhotoFilesDetails">
                                    <app-photo-gallery [galleryId]= String(i) [photos]="inputTemplateData[i].dataSourcePhotoFilesDetails.data"/>
                                    <table mat-table [dataSource]="inputTemplateData[i].dataSourcePhotoFilesDetails" class="mat-elevation-z0" >
                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef>Nombre</th>
                                            <td mat-cell *matCellDef="let user">{{ getNameFromReference(assertItemTypePhotoSaved(user).reference) }}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="createdAt">
                                            <th mat-header-cell *matHeaderCellDef>Fecha</th>
                                            <td mat-cell *matCellDef="let user">{{ assertItemTypePhotoSaved(user).createdAt | date:"medium" }}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef>{{ 'Acciones' }}</th>
                                            <mat-cell mat-cell *matCellDef="let user">
                                                <div>
                                                    <button mat-button (click)="didTapOpenPhoto(user)">
                                                        <mat-icon class="svg" svgIcon="comment-edit-outline"></mat-icon>
                                                        Ver
                                                    </button>
                                                </div>
                                                <div>
                                                    <button mat-button (click)="didTapDeletePhoto(user,i)">
                                                        <mat-icon class="svg" svgIcon="delete-outline"></mat-icon>
                                                        Borrar
                                                    </button>
                                                </div>
                                            </mat-cell>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                </ng-container>
                                <div class="m-2" style="font-size: 15px" *ngIf="campo.photos?.length === 0">Sin Fotos ⛔</div>
                            </div>
                        </div>
                        <div *ngIf="campo.type === 'Files'">
                            <div  fxLayout="column" >
                                <h4  class ="m-2  title m-t-3 " fxFlex="none"> {{ campo.name?.toUpperCase() }} 🗂️</h4>
                                <div fxFlex="100%" style="margin-left: 10px;">
                                    <div (click)="uploadFile.click()" class="m-2">
                                        <button mat-raised-button style="width: 250px;  height:50px; border-radius: 15px; margin-left: 10px;background-color: #2196F3; color:white">
                                            <mat-icon class = "svg" svgIcon="file-upload-outline"></mat-icon>
                                            Subir Documento 📃</button>
                                        <input #uploadFile (change)="uploadOnlyFile($event,campo,i)" type='file' accept=".doc,.docx,.pdf,.xls,.xlsx" style="display:none"/>
                                    </div>
                                </div>
                                <ng-container *ngIf="inputTemplateData && inputTemplateData[i].dataSourcePhotoFilesDetails">
                                    <table mat-table [dataSource]="inputTemplateData[i].dataSourcePhotoFilesDetails" class="mat-elevation-z0">
                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef> Nombre </th>
                                            <td mat-cell *matCellDef="let user"> {{getNameFromReference(assertItemTypeFileSaved(user).reference)}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="createdAt">
                                            <th mat-header-cell *matHeaderCellDef>Fecha</th>
                                            <td mat-cell *matCellDef="let user">{{ assertItemTypePhotoSaved(user).createdAt | date:"medium" }}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef > Acciones </th>
                                            <mat-cell *matCellDef="let user" >
                                                <div>
                                                    <button mat-button  (click)="didTapOpenFile(user)">
                                                        <mat-icon class = "svg" svgIcon="comment-edit-outline"></mat-icon>
                                                        Ver
                                                    </button>
                                                </div>
                                                <div>
                                                    <button mat-button  (click)="assertItemTypeFileDelete(user,i)">
                                                        <mat-icon class = "svg" svgIcon="delete-outline"></mat-icon>
                                                        Borrar
                                                    </button>
                                                </div>
                                            </mat-cell>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                </ng-container>
                                <div class="m-2" style="font-size: 15px" *ngIf="campo.files?.length === 0">Sin Archivos ⛔</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="100" fxLayoutAlign="left" >
                    <button (click)="didTapSaveAsset()" mat-raised-button style=" margin-top: 10px;margin-bottom: 10px;
                     background-color: #AC393B;height: 50px; border-radius: 25px;width: 175px;    color: white;
                         font-size: medium;" >
                        {{ "Guardar" }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
</body>

</html>







