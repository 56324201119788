export const environment = {
  clients: true,
  permissionsAll: ['ADMIN','MASTER'],
  permissionsUserVisit: ['USER','VISIT'],
  self: true,
  production: false,
  tokenPassword : 'cAV2phzBxnD6LSgr',
  keyAES: 'encrypt!135790',
  apiServer: '',
  userApiServer: '',
  photoUrl: '',
  docsUrl: '',
  userApi: {
    getUser: '',
    getUserByEmail: '/users/u='
  },
  apiUrl: 'https://api.qa.lind.global',
  UrlMonitor: 'https://qa.home.lind.global/monitor/',
};

