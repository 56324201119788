import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Company} from "./model/company";
import {RestApiService} from "../../shared/rest-api.service";
import {
  InputEditDataCompanyDialogComponent
} from "../../_dialog/input-edit-data-company-dialog/input-edit-data-company-dialog.component";
import {ConfirmDialogComponent} from "../../_dialog/confirm-dialog/confirm-dialog.component";


@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})

export class CompaniesComponent implements OnInit  {
  @ViewChild('dataTable') dataTable?: MatTable<Company>;
  items: Company[] = [];
  dataSource: MatTableDataSource<Company> = new MatTableDataSource<Company>(this.items) ;
  displayedColumns: string[] = [ 'id', 'name','descripcion', 'actions'];

  constructor(private restApiService: RestApiService,
              private router :Router,
              private dialog: MatDialog,
              private snackBar: MatSnackBar) {}
  ngOnInit(): void {
    this.loadData();
  }
  public loadData() {
    this.restApiService.getCompanies().subscribe({
      next: (response) => {
        if (response.success){
          this.items = response.payload;
          this.dataSource.data = this.items;
        }else{
         this.displayError()
        }
      },
      error: (error) => {
        this.displayError()
      }
    });
  }
  onSelectEditCompany( item: Company) {
    const confirmRefUpdateClient = this.dialog.open(InputEditDataCompanyDialogComponent, {
      data: {
        companyId:item?.id,
        description: item.description,
        name:item.name,
        type: 'ACTUALIZAR'
      }
    });
    confirmRefUpdateClient.afterClosed().subscribe(result => {
      if(result !== undefined){
        this.snackBar.open('Ubicación Actualizada', '', {
          duration: 3000, verticalPosition: 'bottom',
          panelClass: 'notif-success'
        });
        this.reloadRouter()
      }
    });
  }
  onSelectCreateCompany() {
    const confirmRefUpdateClient = this.dialog.open(InputEditDataCompanyDialogComponent, {
      data: {
        ubicationId: "",
        companyId: "",
        description: "",
        name: "",
        type: 'REGISTRAR'
      }
    });
    confirmRefUpdateClient.afterClosed().subscribe(result => {
      if(result !== undefined){
        this.snackBar.open('Compañia Actualizada', '', {
          duration: 3000, verticalPosition: 'bottom',
          panelClass: 'notif-success'
        });
        this.reloadRouter()
      }
    });
  }
  onDelete( path: Company) {
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirmar',
        body: '¿Está seguro de eliminar a: ' + path.name + ' ?',
        confirm: 'Borrar',
        confirmClass: 'danger-button',
        cancel: 'Cancelar'
      }
    });
    confirmRef.afterClosed().subscribe(result => {
      if (result) {
        this.restApiService.deleteCompany(path.id).subscribe(
          data => {

            if (data.success){
              this.snackBar.open('elimino la compañia: '+path.name, '', {
                duration: 3000, verticalPosition: 'bottom',
                panelClass: ['blue-snackbar']
              });
              this.deleteRowData(path);
            }else {
              this.displayError()
            }
          }
        );
      }
    });
  }
  deleteRowData(rowobj: Company) {
    const index: number = this.items.findIndex(d => d === rowobj);
    this.items.splice(index, 1);
    this.dataSource = new MatTableDataSource<Company>(this.items);
  }
  assertItemType(item: Company): Company {
    return item;
  }
  reloadRouter() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl);
    });
  }
  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
}
