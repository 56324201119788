import {Component, OnInit} from '@angular/core';
import {RestApiService} from "../../../shared/rest-api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Monitor} from "./model/Monitor";
import {MatTableDataSource} from "@angular/material/table";
import {ConfirmDialogComponent} from "../../../_dialog/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {InputEditDataMonitorDialogComponent} from "../../../_dialog/input-edit-data-monitor-dialog/input-edit-data-monitor-dialog.component";
import {Company} from "../../companies/model/company";
@Component({
  selector: 'app-monitors',
  templateUrl: './monitors.component.html',
  styleUrls: ['./monitors.component.css']
})
export class MonitorsComponent implements  OnInit{
  displayedColumns: string[] = ['id', 'image','status','version', 'description','company', 'ubication', 'actions'];
  items:Monitor[] = []
  dataSource: MatTableDataSource<Monitor> = new MatTableDataSource<Monitor>();
  constructor(private restApiService: RestApiService,
              private dialog: MatDialog,
              private router: Router,
              private snackBar: MatSnackBar) {
  }
  ngOnInit(): void {
    this.restApiService.getMonitors().subscribe({
      next: (response) => {
        if (response.success){
         this.items = response.payload
          this.dataSource.data = response.payload
        }else{
          this.displayError()
        }
      },
      error: (error) => {
        this.displayError()
      }
    });
  }
  didTapOpenUrl(item: Monitor) {
    this.router.navigate(['monitor', item.id]);
  }
  didTapDeleteItem(item: Monitor) {
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirmar',
        body: '¿Está seguro eliminar monitor empresa ' + item.company?.name + ' en ubicación '+ item.ubication?.name + '?',
        confirm: 'Borrar',
        confirmClass: 'danger-button',
        cancel: 'Cancelar'
      }
    });
    confirmRef.afterClosed().subscribe(result => {
      if (result) {
        this.restApiService.deleteMonitor(item.id).subscribe(
          data => {
            if (data.success){
              this.snackBar.open('Monitor eliminado', '', {
                duration: 3000, verticalPosition: 'bottom',
                panelClass: 'notif-success'
              });
              this.deleteRowData(item);
            }
          }
        );
      }
    });
  }
  assertItemType(item: Monitor): Monitor {
    return item;
  }
  assertItemTypeStatusMonitos(item: Monitor): string {
   switch (item.statusMonitor?.status) {
     case 1: return '🟢'
     case 2: return '🟡'
     case 3: return '🔴'
     default: return '⚪'
   }
  }
  deleteRowData(rowobj: Monitor) {
    const index: number = this.items.findIndex(d => d === rowobj);
    this.items.splice(index, 1);
    this.dataSource = new MatTableDataSource<Company>(this.items);
  }
  didTapEditMonitor (item: Monitor) {
    const confirmRefUpdateClient = this.dialog.open(InputEditDataMonitorDialogComponent, {
      data: {
        monitorId:item.id,
        url:item.url,
        image:item.image,
        version:item.version,
        description:item.description,
        ubicationId: item.ubication?.id,
        companyId:  item.company?.id,
        companyMongoId: item.company?._idMongoDB,
        _activeUnit : item._activeUnit,
        type: 'ACTUALIZAR'
      }
    });
    confirmRefUpdateClient.afterClosed().subscribe(result => {
      if(result !== undefined){
        this.reloadRouter()
        this.snackBar.open('Monitor Actualizado', '', {
          duration: 3000, verticalPosition: 'bottom',
          panelClass: 'notif-success'
        });
      }
    });
  }
  didTapCreateMonitor() {
    const confirmRefUpdateClient = this.dialog.open(InputEditDataMonitorDialogComponent, {
      data: {
        ubicationId: 1,
        companyId:  1,
        type: 'REGISTRAR'
      }
    });
    confirmRefUpdateClient.afterClosed().subscribe(result => {
      if(result !== undefined){
        this.snackBar.open('Monitor Creado', '', {
          duration: 3000, verticalPosition: 'bottom',
          panelClass: 'notif-success'
        });
        this.reloadRouter()
      }
    });
  }
  reloadRouter() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl);
    });
  }
  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
}
