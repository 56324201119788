import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {InputTemplateData} from "../../show/show-template-asset/InputTemplateData";
import {Type} from "../create-asset/Type";
import {RestApiService} from "../../../shared/rest-api.service";
import {ConfirmDialogComponent} from "../../../_dialog/confirm-dialog/confirm-dialog.component";
import {Router} from "@angular/router";
import {InputTemplate} from "../../show/show-template-asset/InputTemplate";

@Component({
  selector: 'app-create-template-asset',
  templateUrl: './create-template-asset.component.html',
  styleUrl: './create-template-asset.component.css'
})
export class CreateTemplateAssetComponent implements OnInit {
  @ViewChild('dataTable') dataTable?: MatTable<InputTemplateData>;
  selectedTipo?: string;
  selectedTemplate?: string;
  inputTemplateSelected?: InputTemplate;
  campos: InputTemplateData[] = [];
  displayedColumns: string[] = ['name', 'type', 'obligatory', 'isHidden', 'notation', 'action']; // Define the displayedColumns array to store the column names
  tipos: Type[] = [];
  templates: InputTemplate[] = []
  nombre: string = '';
  tipo: string = '';

  @ViewChild(MatTable) table?: MatTable<any>;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  dataSource = new MatTableDataSource<InputTemplateData>(this.campos);
  constructor(private restApiService: RestApiService,
              private dialog: MatDialog,
              private router: Router,
              private snackBar: MatSnackBar,) {}
  ngOnInit() {
    this.fetchTipos();
    this.campos = [
      { name: 'Campo 1', type: 'Text', obligatory: true, isHidden: false, notation: 'A',      photos:[],
        files:[],dataSourcePhotoFilesDetails:new MatTableDataSource<any> },
      { name: 'Campo 2', type: 'Number', obligatory: false, isHidden: true, notation: 'B',      photos:[],
        files:[],dataSourcePhotoFilesDetails:new MatTableDataSource<any> },
      { name: 'Campo 3', type: 'Checkbox', obligatory: true, isHidden: true, notation: 'C' ,      photos:[],
        files:[],dataSourcePhotoFilesDetails:new MatTableDataSource<any>},
    ];
    this.dataSource.data = this.campos;
  }
  extractTableValues() {
    let tableValues: any[];
    tableValues = [];
    this.dataSource.data.forEach((row) => {
      const rowValues = {};
      this.displayedColumns.forEach((column) => {
        // @ts-ignore
        rowValues[column] = row[column];
      });
      tableValues.push(rowValues);
    });
    return tableValues;
  }

  createTemplate() {
    let  template = this.extractTableValues()

    if(this.inputTemplateSelected?.name?.trim() === this.nombre.trim()){
      this.snackBar.open('Se repite el nombre de la plantilla', '', {
        duration: 3000, verticalPosition: 'top',
        panelClass: 'notif-success'
      });
   }else if(this.nombre.length === 0){
      this.snackBar.open('Falta Definir Nombre', '', {
        duration: 3000, verticalPosition: 'top',
        panelClass: 'notif-success'
      });
    }else if(this.selectedTipo === undefined){
      this.snackBar.open('Falta definir tipo de plantilla', '', {
        duration: 3000, verticalPosition: 'top',
        panelClass: 'notif-success'
      });
    } else if(template.length ===0){
      this.snackBar.open('Falta agregar campos', '', {
        duration: 3000, verticalPosition: 'top',
        panelClass: 'notif-success'
      });
    } else {
      const confirmRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirmar',
          body: '¿Está seguro de crear planilla: ' + this.nombre + ' ?',
          confirm: 'Crear',
          confirmClass: 'danger-button',
          cancel: 'Cancelar'
        }
      });
      confirmRef.afterClosed().subscribe(result => {
        if (result) {
          this.restApiService.createTemplateAsset(template,this.selectedTipo ?? "", this.nombre).subscribe({
            next: (response) => {
              if (response.success) {
                this.snackBar.open('Se agrego plantilla', '', {
                  duration: 3000, verticalPosition: 'bottom',
                  panelClass: ['blue-snackbar']
                });
                this.router.navigate(['/lista-plantillas']);
              } else {
                this.displayError()
              }
            },
            error: (error) => {
              this.displayError()
            }
          });
        }
      });
    }
  }

  fetchTipos() {
    this.restApiService.getAllActiveTypeandTemplate().subscribe({
      next: (response) => {
        if (response.success){
          this.templates = response.payload.templatesByCompany
          this.tipos = response.payload.allTypes
        }else{
          this.displayError()
        }
      },
      error: (error) => {
        this.displayError()
      }
    });
  }
  addCampo(): void {
    // Add logic to add a new campo object to the campos array
    const newCampo: InputTemplateData = {
      name: 'Nombre',
      type: '',
      obligatory: false,
      isHidden: false,
      notation: '',
      photos:[],
      files:[],
      dataSourcePhotoFilesDetails: new MatTableDataSource<any>
    };
    this.campos.push(newCampo);
    this.dataSource.data = this.campos;
  }
  drop(event: CdkDragDrop<MatTableDataSource<InputTemplateData, MatPaginator>, any>) {
    const previousIndex = this.dataSource.data.findIndex(row => row === event.item.data);
    moveItemInArray(this.dataSource.data,previousIndex, event.currentIndex);
    this.dataTable?.renderRows();
  }
  didTapDelete(item: InputTemplateData){
    const index: number = this.campos.findIndex(d => d === item);
    this.campos.splice(index, 1);
    this.dataSource = new MatTableDataSource<InputTemplateData>(this.campos);
  }
  titleHeaderCell(column:string){
    switch (column) {
      case 'name': return 'NOMBRE'
      case 'obligatory': return 'OBLIGATORIO'
      case 'isHidden': return 'OCULTA'
      case 'notation': return 'ANOTACION'
      case 'action': return 'ACCION'
      default: return ''
    }
  }
  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
  onSelectTemplate(template: InputTemplate) {
    this.nombre = template.name ?? ""
    this.inputTemplateSelected = template
    this.campos = template.inputTemplate   || [
      { name: 'Campo 1', type: 'Text', obligatory: true, isHidden: false, notation: 'A',      photos:[],
        files:[],dataSourcePhotoFilesDetails:new MatTableDataSource<any> },
      { name: 'Campo 2', type: 'Number', obligatory: false, isHidden: true, notation: 'B',      photos:[],
        files:[],dataSourcePhotoFilesDetails:new MatTableDataSource<any> },
      { name: 'Campo 3', type: 'Checkbox', obligatory: true, isHidden: true, notation: 'C' ,      photos:[],
        files:[],dataSourcePhotoFilesDetails:new MatTableDataSource<any>},
    ];
  this.dataSource = new MatTableDataSource<InputTemplateData>(this.campos);
  }
}
