import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DetailsUnit} from "../../unit/detail-unit/model/detailsUnit";
import {RestApiService} from "../../../shared/rest-api.service";

@Component({
  selector: 'app-show-detail-unit',
  templateUrl: './show-detail-unit.component.html',
  styleUrl: './show-detail-unit.component.css'
})
export class ShowDetailUnitComponent {
  items: DetailsUnit[] = [];
  constructor(private restApiService: RestApiService,
              private snackBar: MatSnackBar,
              private activatedRouter: ActivatedRoute) {
    this.activatedRouter.params.subscribe(params => {
      const id = params['id'];
      this.restApiService.showTemplateDetailsActiveUnits(id).subscribe({
        next: (response) => {
          if (response.success){
            this.items = response.payload
          }else{
            this.displayError()
          }
        },
        error: (error) => {
          this.displayError()
        }
      });
    });
  }
  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
}
