<h3 mat-dialog-title>{{ data.title }}</h3>
<mat-dialog-content [formGroup]="form" style="width: 500px;">
  <mat-form-field [hidden]= "!data.showtextEdit" style="width: 450px">
    <input  matInput  formControlName="text" [placeholder]="data.placeHolder" name="nombre" type="text" >
  </mat-form-field>
    <mat-form-field [hidden]= "!data.showtextEdit1" style="width: 450px">
        <input  matInput  formControlName="text1" [placeholder]="data.placeHolder2" name="descripcion" type="text" >
    </mat-form-field>

    <div [hidden]="!data.showtypeActive">
        <mat-label class="elegant-label">
                        <span class="icon-label">
                            <mat-icon class="svg" svgIcon="format-list-numbered"></mat-icon>
                            Tipo de Activo
                        </span>
        </mat-label><br>
        <mat-form-field class="full-width m-2">
            <mat-label class="m-4">
                            <span class="icon-label">
                                Tipo
                            </span>
            </mat-label>
            <mat-select class="full-width" id="Tipo" name="Tipo" [(value)]="typeActive">
                <mat-option *ngFor="let tipo of tipos" [value]="tipo._id">{{ tipo.name }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="close()">{{ data.cancel }}</button>
  <button mat-button [mat-dialog-close]="true" (click)="save()"  class="{{ data.confirmClass }}">{{ data.confirm }}</button>
</mat-dialog-actions>
