<div class="grid p-1 m-1">
  <div class="col-12">
    <div class="flex flex-column sm:flex-row align-items-center gap-3">
      <div class="flex flex-column sm:flex-row align-items-center gap-3">
        <div class="flex flex-column align-items-center sm:align-items-start">
          <span class="text-900 font-bold text-2xl">Módulo BIM</span>
          <span class="text-500 text-md">Visualización de Modelos BIM</span>
        </div>
      </div>
      <div
        *ngIf="showUnitSelector"
        class="flex gap-2 sm:ml-auto align-items-center"
      >
        <span class="flex-none text-500 font-semibold text-md">
          Unidades:
        </span>
        <p-dropdown
          [options]="data"
          [loading]="loading"
          [(ngModel)]="selectedUnit"
          placeholder="-- No Seleccionada --"
          [filter]="true"
          filterBy="label"
          [style]="{ display: 'flex', width: '20vw' }"
          (onChange)="onSelectUnit($event)">
        </p-dropdown>
      </div>
    </div>
  </div>
  <p-card class="col-12">
    <app-info-monitor-go-js
      [items]="items"
      [infoUnit]="infoUnit"
      [showAssetInfo]="false"
      [highlightBim]="true"
    ></app-info-monitor-go-js>
  </p-card>
</div>
