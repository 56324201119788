<h1  class ="m-3"> Lista de monitores 🖥️️</h1>
<ngx-loading-bar></ngx-loading-bar>
<div class=" contain mat-elevation-z0">
  <div fxFlex="95"fxLayout="column" style="margin: 5px;font-family: Roboto, sans-serif!important;font-size:20px !important;">
    <div class="centered-div" *ngxPermissionsOnly = "['MASTER','ADMIN']">
      <button mat-button (click)="didTapCreateMonitor()"  mat-flat-button>
        <mat-icon inline=true>add</mat-icon>
        Crear Monitor
      </button>
    </div>
<table mat-table [dataSource]="dataSource" matSort>

  <!-- Position Column -->
  <ng-container   matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
    <td mat-cell class ="ml-3" *matCellDef="let element"> {{assertItemType(element).id}} </td>
    <td mat-footer-cell *matFooterCellDef> id  </td>
  </ng-container>

  <ng-container matColumnDef="image">
    <th mat-header-cell *matHeaderCellDef> Imagen </th>
    <td mat-cell *matCellDef="let element">
      <img  src="{{assertItemType(element).image}}" alt="Image" class="table-image">
    </td>
  </ng-container>
  <ng-container   matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
    <td mat-cell class="ml-3" *matCellDef="let element">
      <span class="large-emoji">{{ assertItemTypeStatusMonitos(element) }}</span>
    </td>
    <td mat-footer-cell *matFooterCellDef> status  </td>
  </ng-container>

  <ng-container   matColumnDef="version">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Versión </th>
    <td mat-cell class ="ml-3" *matCellDef="let element"> {{assertItemType(element).version}} </td>
    <td mat-footer-cell *matFooterCellDef> version  </td>
  </ng-container>
  <!-- Position Column -->
  <ng-container matColumnDef="description" class="">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
    <td mat-cell *matCellDef="let element"> {{assertItemType(element).description}}</td>
    <td mat-footer-cell *matFooterCellDef>description</td>
  </ng-container>


  <!-- Position Column -->
  <ng-container matColumnDef="company" class="">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Empresa </th>
    <td mat-cell *matCellDef="let element"> {{assertItemType(element).company?.name}}</td>
    <td mat-footer-cell *matFooterCellDef>company</td>
  </ng-container>

  <ng-container matColumnDef="ubication" class="">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Ubicación </th>
    <td mat-cell *matCellDef="let element"> {{assertItemType(element).ubication?.name}}</td>
    <td mat-footer-cell *matFooterCellDef>ubication</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef > Acciones</th>
    <mat-cell *matCellDef="let element" >
      <button mat-button (click)="didTapOpenUrl(element)">
        <mat-icon svgIcon="open-in-new" ></mat-icon>
      </button>

      <div *ngxPermissionsOnly = "['MASTER', 'ADMIN']">
        <button mat-button (click)="didTapEditMonitor(element)">
          <mat-icon class = "svg" svgIcon="pencil-outline"></mat-icon>
        </button>
      </div>
      <div *ngxPermissionsOnly = "['MASTER']">
        <button mat-button (click)="didTapDeleteItem(element)" >
          <mat-icon svgIcon="trash-can-outline"></mat-icon>
        </button>
      </div>
    </mat-cell>
    <td mat-footer-cell *matFooterCellDef>  </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
      <div class="m-2" style="font-size: 15px" *ngIf="items.length === 0">Sin Monitores ⛔</div>
  </div>
</div>
