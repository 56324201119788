import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/authentication.service';
import {
  COMPANY_MASTER
} from 'src/app/shared/constants';
import { RestApiService } from 'src/app/shared/rest-api.service';

@Component({
  selector: 'app-bim',
  templateUrl: './bim.component.html',
  styleUrl: './bim.component.css',
})
export class BimComponent implements OnInit {
  items: any[] = [];
  infoUnit: any;
  data: any[] = [];
  selectedUnit: string = '';
  showUnitSelector: boolean = false;
  loading: boolean = true;
  userInfo = this.authService.getCurrentUser();

  constructor(
    private restApiService: RestApiService,
    private authService: AuthenticationService
  ) {}

  loadUnitsSelector() {
    if (this.userInfo?.payload.company?.id === COMPANY_MASTER.id) {
      this.showUnitSelector = true;
    } 
    this.loadCompanyUnits();
  }

  loadUnitData(){
    this.loading = true;
    this.restApiService.getDetailsActiveUnits(this.selectedUnit).subscribe({
      next: (response) => {
        if (response.success) {
          this.items = response.payload.listActiveTemplateEntity;
          this.infoUnit = response.payload.unitInfo;
        } else {
          console.log('Error intentarlo de nuevo');
        }
        this.loading = false;
      },
      error: (error) => {
        console.log('Error intentarlo de nuevo');
        this.loading = false;
      },
    });
  }

  loadCompanyUnits() {
    this.restApiService.getActiveUnits().subscribe({
      next: (response) => {
        this.data = response.payload || [];
        this.data = response.payload.map((unit: any) => {
          return {
            label: unit.company.name + ' - ' + unit.name,
            value: unit._id,
          };
        });
        this.selectedUnit = this.data[0].value;
        this.loadUnitData();
        this.loading = false;
      },
      error: (error) => {
        console.error(error);
        this.data = [];
        this.loading = false;
      },
    });
  }

  ngOnInit(): void {
    this.loadUnitsSelector();
  }

  onSelectUnit(event: any) {
    this.loading = true;
    this.selectedUnit = event.value;
    this.loadUnitData();
    this.loading = false;
  }

  groupUnitsByCompany(units: any[]) {
    return units.reduce((acc, unit) => {
      const companyName = unit.company.name;
      const existingCompany = acc.find(
        (company: { label: any; }) => company.label === companyName
      );
      if (existingCompany) {
        existingCompany.items.push({ label: unit.name, value: unit.name });
      } else {
        acc.push({
          label: companyName,
          value: companyName,
          items: [{ label: unit.name, value: unit.name }],
        });
      }
      return acc;
    }, []);
  }
}
