import { Component, OnInit } from '@angular/core';
import * as THREE from 'three';
import * as OBC from '@thatopen/components';

@Component({
  selector: 'app-bim-viewer',
  templateUrl: './bim-viewer.component.html',
  styleUrl: './bim-viewer.component.css',
})
export class BimViewerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    this.createThreeJsBox();
  }

  async createThreeJsBox(): Promise<void> {
    const canvas = document.getElementById('canvas-box');

    const components = new OBC.Components();

    const worlds = components.get(OBC.Worlds);

    const world = worlds.create<
      OBC.ShadowedScene,
      OBC.SimpleCamera,
      OBC.SimpleRenderer
    >();

    world.scene = new OBC.ShadowedScene(components);
    world.renderer = new OBC.SimpleRenderer(components, canvas!);
    world.camera = new OBC.SimpleCamera(components);

    components.init();

    const grids = components.get(OBC.Grids);
    const grid = grids.create(world);

    world.camera.controls.setLookAt(1, 2, -2, -2, 0, -5);

    world.scene.three.background = null;

    const fragments = new OBC.FragmentsManager(components);

    const file = await fetch(
      'https://thatopen.github.io/engine_components/resources/small.frag'
    );
    const dataBlob = await file.arrayBuffer();
    const buffer = new Uint8Array(dataBlob);
    const model = fragments.load(buffer);
    world.scene.three.add(model);

    const plane = new THREE.Mesh(
      new THREE.PlaneGeometry(15, 25, 1),
      new THREE.MeshLambertMaterial({ color: 'white' })
    );
    plane.position.set(-2, -1, -7);
    plane.rotation.x = -Math.PI / 2;
    plane.receiveShadow = true;
    world.scene.three.add(plane);

    world.renderer.three.shadowMap.enabled = true;
    world.renderer.three.shadowMap.type = THREE.PCFSoftShadowMap;

    world.scene.setup({
      shadows: {
        cascade: 1,
        resolution: 1024,
      },
    });

    type iMesh = THREE.InstancedMesh<
      THREE.BufferGeometry,
      THREE.MeshLambertMaterial[]
    >;

    for (const child of model.children) {
      const mesh = child as iMesh;
      if (mesh.material[0].opacity === 1) {
        mesh.castShadow = true;
        mesh.receiveShadow = true;
      }
    }

    world.scene.distanceRenderer.excludedObjects.add(grid.three);

    await world.scene.updateShadows();

    world.camera.controls.addEventListener('update', async () => {
      await world.scene.updateShadows();
    });
  }
}
