import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-root-viewer-file-dialog',
  templateUrl: './viewer-file-dialog.component.html',
  styleUrls: ['./viewer-file-dialog.component.scss']
})
export class ViewerFileDialogComponent {
  viewer = 'google';
  constructor( @Inject(MAT_DIALOG_DATA) public data: any) {}
}

