<p-table
  [value]="data"
  styleClass="p-datatable-striped p-datatable-sm"
  [scrollable]="true"
  scrollHeight="50vh"
  [rows]="10"
  [showCurrentPageReport]="true"
  [paginator]="true"
  selectionMode="single"
  dataKey="_id"
  [(selection)]="selectedData"
  (onRowSelect)="onRowSelect($event)"
  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Activos"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Nombre</th>
      <th>Descripción</th>
      <th>Acciones</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-asset let-rowIndex="rowIndex">
    <tr [pSelectableRow]="asset" [pSelectableRowIndex]="rowIndex">
      <td>{{ asset.name }}</td>
      <td>{{ asset.description }}</td>
      <td>
        <p-button
          *ngxPermissionsOnly="['MASTER', 'ADMIN', 'USER', 'VISIT']"
          icon="pi pi-eye"
          class="mr-2 border-round"
          [outlined]="true"
          size="small"
          label="Ver"
          severity="primary"
          (click)="didTapViewAsset(asset._id)"
        >
        </p-button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td>No se encontraron Activos.</td>
    </tr>
  </ng-template>
</p-table>
