import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {User} from "./model/User";
import {ConfirmDialogComponent} from "../../../_dialog/confirm-dialog/confirm-dialog.component";
import {RestApiService} from "../../../shared/rest-api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {InputaUpdateUserDialogComponent} from "../../../_dialog/inputs-update-user-dialog/input-rut-dialog.component";

interface Column {
  field: string;
  header: string;
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit  {
  @ViewChild('dataTable') dataTable?: MatTable<User>;
  items: User[] = [];
  dataSource: MatTableDataSource<User> = new MatTableDataSource<User>(this.items)
  data = this.dataSource.data;
  cols: Column[] = [
    { field: 'company', header: 'Compañia' },
    { field: 'firstName', header: 'Nombre' },
    { field: 'lastName', header: 'Apellido' },
    { field: 'email', header: 'Email' },
    { field: 'permission', header: 'Rol' },
    { field: 'actions', header: 'Acciones' }
  ];
  displayedColumns: string[] = [ 'firstName','lastName', 'email', 'company', 'permission', 'actions'];

  constructor(private restApiService: RestApiService,
              private router :Router,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,) {
  }
  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<User>(this.items);
    this.loadData();
  }

public loadData() {
  this.restApiService.getUsers().subscribe({
    next: (response) => {
      if (response.success){
        this.data = response.payload;
        this.items = response.payload;
        this.dataSource.data = this.items;
      }else{
        this.snackBar.open('Email o Contraseña incorrecta', '', {
          duration: 3000, verticalPosition: 'bottom',
          panelClass: 'notif-success'
        });
      }
    },
    error: (error) => {
      this.displayError()
    }
  });
}
didTapEditUser(infoClient: User){
  const confirmRefUpdateClient = this.dialog.open(InputaUpdateUserDialogComponent, {
    data: {
      title: 'Se necesita Rut Empresa',
      body: 'Se tiene que asociar al cliente',
      confirm: 'Editar cliente',
      hiddenShowPassword: false,
      fname: infoClient?.firstName ?? "",
      lname: infoClient?.lastName ?? "",
      company: infoClient?.company?.id,
      email: infoClient?.email ?? "",
      type_user : infoClient.permission?.id,
      user_id: infoClient?.id ?? ""
    }
  });
  confirmRefUpdateClient.afterClosed().subscribe(result => {
    if(result !== undefined){
      this.snackBar.open('Usuario Actualizado', '', {
        duration: 3000, verticalPosition: 'bottom',
        panelClass: 'notif-success'
      });
      this.reloadRouter()
    }
  });
}
onSelectCreateUser() {
    const confirmRefUpdateClient = this.dialog.open(InputaUpdateUserDialogComponent, {
    data: {
      title: 'Se necesita Rut Empresa',
      body: 'Se tiene que asociar al cliente',
      confirm: 'Editar cliente',
      confirmClass: 'danger-button',
      cancel: 'Cancelar ',
      fname:  "",
      lname:  "",
      email: "",
      user_id:  "",
      hiddenShowPassword: false
    }
  });
  confirmRefUpdateClient.afterClosed().subscribe(result => {
    if(result !== undefined){
      this.snackBar.open('Usuario Creado', '', {
        duration: 3000, verticalPosition: 'bottom',
        panelClass: 'notif-success'
      });
      this.reloadRouter()
    }
  });
}
didTapDeleteUser( path: User) {
  const confirmRef = this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: 'Confirmar',
      body: '¿Está seguro de eliminar a: ' + path.firstName + ' ?',
      confirm: 'Borrar',
      confirmClass: 'danger-button',
      cancel: 'Cancelar'
    }
  });
  confirmRef.afterClosed().subscribe(result => {
    if (result) {
      this.restApiService.deleteUser(path.id).subscribe(
        response => {
          this.deleteRowData(path);
        }
      );
    }
  });
}
deleteRowData(rowobj: User) {
  const index: number = this.items.findIndex(d => d === rowobj);
  this.items.splice(index, 1);
  this.dataSource = new MatTableDataSource<User>(this.items);
}
  assertItemType(item: User): User {
    return item;
  }
  reloadRouter() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl);
    });
  }
  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
}
