<!DOCTYPE html>
<html>
<head>
    <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap" rel="stylesheet">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/css/bootstrap.min.css">
</head>
<body>
<div class="m-3 form-group col">
    <div fxLayout="column" fxFlex="100" fxLayoutAlign="center">
        <h3  class ="logo  title m-3" fxFlex="none">{{type }} Compañia</h3>
        <div fxLayout="column" fxFlex="100">
            <form class="form-group" [formGroup]="loginForm">
                <div fxLayout="column" fxFlex="100" fxLayoutAlign="start"  >
                    <mat-form-field class="m-2">
                        <mat-label>Nombre</mat-label>
                        <input  matInput  formControlName="name" class="form-control" placeholder=""  name="name"  required>
                    </mat-form-field>
                    <mat-form-field class="m-2">
                        <mat-label>Descripción</mat-label>
                        <input  matInput  formControlName="description" class="form-control" placeholder="" name="description"  required>
                    </mat-form-field>
                </div>
            </form>
        </div>
        <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center" >
            <button mat-raised-button (click)="register()" style="margin-bottom: 10px;  background-color: #0E3083;height: 50px; border-radius: 25px;width: 175px;    color: white;
    font-size: medium;" >
                <span *ngIf="registering" class="spinner-border spinner-border-sm mr-1"></span>
                {{ type }}
            </button>
        </div>
    </div>
</div>
<script src="//ajax.googleapis.com/ajax/libs/jquery/1.12.0/jquery.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/js/bootstrap.min.js"></script>
</body>
</html>
