<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content [formGroup]="form" style="width: 500px;">
  <mat-form-field style="width: 450px">
    <input  matInput  formControlName="textAdd" [placeholder]="placeHolder" name="textAdd" type="text" >
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="close()">{{ data.cancel }}</button>
  <button mat-button [mat-dialog-close]="true" (click)="save()"  class="{{ data.confirmClass }}">{{ data.confirm }}</button>
</mat-dialog-actions>
