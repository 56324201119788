<!DOCTYPE html>
<html>

<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/css/bootstrap.min.css">-->
</head>

<body>

<div class="col-md-6 offset-md-3 mt-5">
  <div class="login">

    <div class="login-logo ">
      <img ngSrc="../../../assets/images/not_access.png" alt="Logo Lind" height="146" width="350">
    </div>

    <div class="login-form">
      <div class="form-group">
        <button mat-raised-button   style="background-color: #0E3083;height: 50px; border-radius: 25px;width: 185px;    color: white;
    font-size: medium;" (click)="handleClick()" >
          Regresar
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Placed at the end of the document so the pages load faster -->
<script src="//ajax.googleapis.com/ajax/libs/jquery/1.12.0/jquery.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/js/bootstrap.min.js"></script>
</body>
</html>
