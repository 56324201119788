import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/authentication.service';
import {
  COMPANY_MASTER,
  DEFAULT_COMPANY_DASHBOARD_ID,
} from 'src/app/shared/constants';
import { RestApiService } from 'src/app/shared/rest-api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css',
})
export class DashboardComponent implements OnInit {
  loading: boolean = true;
  userInfo = this.authService.getCurrentUser();
  showCompanySelector: boolean = false;
  selectedCompany: any = null;
  data: any = [];
  alerts24: number = 0;
  alertsMonth: number = 0;
  assets: any = [];
  assetsUnderMonitoring: any = 0;
  lastUpdated: Date | null = null;
  private refreshInterval: any;


  constructor(
    private authService: AuthenticationService,
    private restApiService: RestApiService
  ) {}

  ngOnInit() {
    this.loadCompanySelector();

    this.refreshInterval = setInterval(() => {
      this.refreshDashboard();
    }, 60000);
  }

  ngOnDestroy() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  refreshDashboard() {
    this.loading = true;
    if (this.selectedCompany) {
      this.loadAssetsInfo(this.selectedCompany);
      this.loadAlerts(this.selectedCompany);
    }
    this.lastUpdated = new Date();
    this.loading = false;
  }

  loadAssetsInfo(selectedCompany: string) {
    this.restApiService.getAssetsByCompany(selectedCompany).subscribe({
      next: (response) => {
        this.assets = response.payload || [];
        this.assetsUnderMonitoring = this.assets.filter(
          (asset: any) => asset.hasMD || asset.hasMI
        ).length;
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  loadCompanySelector() {
    if (this.userInfo?.payload.company?.id === COMPANY_MASTER.id) {
      this.restApiService.getCompaniesForSelector().subscribe({
        next: (response) => {
          this.data = response.payload || [];
          this.data = response.payload.map((company: any) => {
            return { label: company.name, value: company._idMongoDB };
          });
          this.selectedCompany = DEFAULT_COMPANY_DASHBOARD_ID;
          this.showCompanySelector = true;
          this.refreshDashboard(); // Cargar datos la primera vez
          this.loading = false;
        },
        error: (error) => {
          console.error(error);
          this.data = [];
          this.loading = false;
        },
      });
    } else {
      this.selectedCompany = this.userInfo?.payload.company?._idMongoDB;
      this.refreshDashboard(); // Cargar datos la primera vez
      this.showCompanySelector = false;
      this.loading = false;
    }
  }

  onSelectCompany(event: any) {
    this.loading = true;
    this.selectedCompany = event.value;
    this.refreshDashboard();
    this.loading = false;
  }

  loadAlerts(selectedCompany: string) {
    this.restApiService.getInitialRiskAlerts(selectedCompany).subscribe({
      next: (response) => {
        this.parseAlerts(response.payload);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  parseAlerts(alerts: any) {
    this.alerts24 = alerts.find((alert: any) => alert.period === '24h')?.alert_count || 0;
    this.alertsMonth = alerts.find((alert: any) => alert.period === 'month')?.alert_count || 0;
  }
}
