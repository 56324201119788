<div class="min-h-screen flex surface-ground">
  <div
    id="app-sidebar-1"
    class="surface-section h-screen flex-shrink-0 fixed sticky left-0 top-0 z-1 border-right-1 surface-border select-none p-2"
    style="width: 280px"
  >
    <div class="flex flex-column h-full">
      <div class="flex flex-column sm:flex-row align-items-center gap-2">
        <img
        src="../../../assets/images/LIND_LogoB.png"
        alt="Logo"
        height="30"
        (click) = didTapDashboard()
        class="cursor-pointer ml-3"
      />
        <div class="flex gap-2 ml-auto">
           <p-button
          text="true"
          rounded="true"
          severity="contrast"
          pStyleClass="#app-sidebar-1"
          enterFromClass="hidden"
          enterActiveClass="fadeinleft"
          leaveToClass="hidden"
          leaveActiveClass="fadeoutleft"
          [hideOnEscape]="true"
          aria-label="Cerrar"
          icon="pi pi-times"
        ></p-button>
        </div>
    </div>
      <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
      <div class="overflow-y-auto p-2">
        <p-panelMenu
          [model]="menuUserSelected"
          styleClass="w-full"
          multiple="false"
        >
          <ng-template pTemplate="item" let-item>
            <ng-container *ngIf="item.route; else urlRef">
              <a
                [routerLink]="item.route"
                class="flex align-items-center cursor-pointer text-color px-3 py-2 no-underline"
              >
                <span [class]="item.icon" aria-hidden="true"></span>
                <span class="ml-2 text-color">{{ item.label }}</span>
              </a>
            </ng-container>
            <ng-template #urlRef>
              <a
                *ngIf="item.url; else noLink"
                [href]="item.url"
                class="flex align-items-center cursor-pointer text-color px-3 py-2 no-underline"
              >
                <span [class]="item.icon" aria-hidden="true"></span>
                <span class="ml-2">{{ item.label }}</span>
              </a>
              <ng-template #noLink>
                <span
                  class="flex align-items-center cursor-pointer text-color px-3 py-2"
                >
                  <span [class]="item.icon" aria-hidden="true"></span>
                  <span class="ml-2">{{ item.label }}</span>
                  <i
                    *ngIf="item.items"
                    class="pi pi-angle-down text-primary ml-auto"
                    aria-hidden="true"
                  ></i>
                </span>
              </ng-template>
            </ng-template>
          </ng-template>
        </p-panelMenu>
      </div>
      <div class="mt-auto">
        <hr class="mb-1 mx-3 border-top-1 border-none surface-border" />
        <a
          class="m-3 flex align-items-center cursor-pointer border-round text-700 hover:surface-100 transition-duration-150 transition-colors"
          aria-label="Usuario"
        >
          <p-avatar
            icon="pi pi-user"
            styleClass="mr-2"
            shape="circle"
          ></p-avatar>
          <div class="block">
            <div class="text-900 font-medium">
              {{ userInfo["payload"]["username"] }}
            </div>
            <span class="text-600 font-medium text-sm">{{
              userInfo["payload"]["company"]["name"]
            }}</span>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="min-h-screen flex flex-column relative flex-auto">
    <div
      class="flex justify-content-between align-items-center px-2 surface-0 border-bottom-1 surface-border relative lg:static"
      style="height: 60px"
    >
      <div class="flex">
        <p-button
          text="true"
          severity="contrast"
          pStyleClass="#app-sidebar-1"
          enterFromClass="hidden"
          enterActiveClass="fadeinleft"
          leaveToClass="hidden"
          leaveActiveClass="fadeoutleft"
          [hideOnEscape]="true"
          aria-label="Menú"
          class="lg:inline-flex mr-3"
          label="Menú"
          icon="pi pi-bars"
          >
        </p-button>
      </div>
      <ul
        class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static"
      >
        <li class="border-top-1 surface-border lg:border-top-none">
          <p-button
            rounded="true"
            text="true"
            severity="contrast"
            (click)="closeSession()"
            aria-label="Cerrar Sesión"
          >
            <i
              class="pi pi-sign-out text-base lg:text-2xl"
              aria-hidden="true"
            ></i>
            <span class="block ml-2">Cerrar Sesión</span>
          </p-button>
        </li>
      </ul>
    </div>
    <div class="flex flex-column flex-auto content">
      <router-outlet/>
    </div>
  </div>
</div>
