import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {MatSnackBar} from "@angular/material/snack-bar";
import {RestApiService} from "../../shared/rest-api.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-root-input-edit-data-company-dialog',
  templateUrl: './input-edit-data-company-dialog.component.html',
  styleUrls: ['./input-edit-data-company-dialog.component.scss']
})

export class InputEditDataCompanyDialogComponent implements OnInit {
  loginForm!: FormGroup;
  constructor(
      private breakpointObserver: BreakpointObserver,
      private dialogRef: MatDialogRef<InputEditDataCompanyDialogComponent>,
      private formBuilder: FormBuilder,
      private snackBar: MatSnackBar,
      private restApiService: RestApiService,
      @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  flex_reduce_size  = 'row';
  registering = false;
  permission = 'ADMIN';
  type = 'REGISTRAR' ;
  ngOnInit(): void {
    this.breakpointObserver
        .observe(['(min-width: 720px)'])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.flex_reduce_size  = 'row';
          } else {
            this.flex_reduce_size  = 'column';
          }
        });
    if (this.data.type == 'ACTUALIZAR') {
      this.type  = 'ACTUALIZAR' ;
      this.loginForm = this.formBuilder.group({
        name: [this.data.name, Validators.required],
        description: [this.data.description, Validators.required]
      });

    } else {
      this.data.type = 'REGISTRAR' ;
      this.type  = 'REGISTRAR' ;
      this.loginForm = this.formBuilder.group({
        name: ['', Validators.required],
        description: ['', Validators.required]
      });
    }
  }
  register() {
    const name: string = this.loginForm?.get('name')?.value ?? ""
    const description: string = this.loginForm?.get('description')?.value ?? ""
    if(name.length === 0){
      this.snackBar.open('Nombre no definido', '', {
        duration: 3000, verticalPosition: 'top',
        panelClass: 'notif-success'
      });
    } else if(name.length === 0){
      this.snackBar.open('Nombre no definido', '', {
        duration: 3000, verticalPosition: 'top',
        panelClass: 'notif-success'
      });
    } else {
      this.registering = true

      if ( this.type  == 'REGISTRAR') {
        this.restApiService.createCompany(name,description).subscribe({
          next: (response) => {
            if (response.success) {
              this.snackBar.open('NUEVA empresa', '', {
                duration: 3000, verticalPosition: 'bottom',
                panelClass: ['blue-snackbar']
              });
              this.dialogRef.close(this.loginForm?.value);
            } else if (response.status === 'duplicate') {

              this.snackBar.open('Existe empresa', '', {
                duration: 3000, verticalPosition: 'bottom',
                panelClass: ['blue-snackbar']
              });
            }
            this.registering = false;
          },
          error: (error) => {
            this.registering = false
            this.displayError()
          }
        });
      }else {
        this.restApiService.updateCompany(this.data.companyId, name,description).subscribe({
          next: (response) => {
            this.registering = false;
            if (response.success) {
              this.snackBar.open('Se Actualizo la empresa', '', {
                duration: 3000, verticalPosition: 'bottom',
                panelClass: ['blue-snackbar']
              });
              this.dialogRef.close(this.loginForm?.value);
            } else {
              this.displayError()
            }
          },
          error: (error) => {
            this.registering = false
            this.displayError()
          }
        });
      }
    }
  }
  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
}
