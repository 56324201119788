<div id="bg-login">
  <div class="surface-section md:w-4 p-8 md:p-8 h-full border-round-right" style="background-color: rgba(255, 255, 255, 0.247)">
    <div class="mt-8 mb-5 ">
      <img
        src="../../../assets/images/LIND_engineering.png"
        alt="Logo Lind"
        class="mb-5 w-full h-full"
      />
      <div class="text-900 text-3xl font-medium mb-3">Bienvenido</div>
    </div>

    <div>
      <div class="login-form">
        <form [formGroup]="loginForm">
          <label for="email" class="block text-900 font-medium mb-2"
            >Email</label
          ><input
            id="email"
            type="text"
            placeholder="Email"
            pinputtext=""
            formControlName="email"
            class="p-inputtext p-component p-element w-full mb-3 p-3"
          /><label for="password" class="block text-900 font-medium mb-2"
            >Contraseña</label
          ><input
            id="password"
            type="password"
            placeholder="Contraseña"
            pinputtext=""
            formControlName="password"
            class="p-inputtext p-component p-element w-full mb-3 p-3"
          />
          <div class="flex align-items-center justify-content-between mb-6">
            <a
              class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
              >Olvidó su contraseña?</a
            >
          </div>

          <p-button
            styleClass="w-full p-3"
            label="Iniciar sesion"
            icon="pi pi-user"
            [loading]="loading"
            (onClick)="onSubmit()"
          >
          </p-button>
        </form>
      </div>
    </div>
  </div>
</div>
