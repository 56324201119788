import { Component, Input, OnChanges } from '@angular/core';
import { Chart } from 'chart.js';
import { ChartModule } from 'primeng/chart';
import { RestApiService } from 'src/app/shared/rest-api.service';
import moment from 'moment-timezone';

@Component({
  selector: 'app-historical-alert-chart',
  standalone: true,
  imports: [ChartModule],
  templateUrl: './historical-alert-chart.component.html',
  styleUrl: './historical-alert-chart.component.css',
})
export class HistoricalAlertChartComponent implements OnChanges {
  @Input() data: any;
  chartData: any;
  alertData: any;
  options: any;

  constructor(
    private restApiService: RestApiService
  ) {
    Chart.register({
      id: 'NoData',
      afterDraw: function (chart) {
        if (
          chart.data.datasets
            .map((d) => d.data.length)
            .reduce((p, a) => p + a, 0) === 0
        ) {
          const ctx = chart.ctx;
          const width = chart.width;
          const height = chart.height;
          chart.clear();
    
          ctx.save();
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = `1.5rem ${window.getComputedStyle(document.body).fontFamily}`;
          ctx.fillStyle = 'black';
          ctx.fillText('No hay datos para mostrar', width / 2, height / 2);          
        }
      },
    });
  }

  ngOnChanges(changes: any) {
    if (changes.data && changes.data.currentValue) {
      this.getAlertValues(changes.data.currentValue);

    }
  }

  getAlertValues(companyId: string) {
    const startDate = moment().tz('America/Santiago').subtract(7, 'days').startOf('day');
    const startDateString = startDate.format('YYYY-MM-DDTHH:mm:ssZ');

    const endDate = moment().tz('America/Santiago').endOf('day');
    const endDateString = endDate.format('YYYY-MM-DDTHH:mm:ssZ');

    this.restApiService.getRiskAlertsByDate(companyId, startDateString, endDateString).subscribe({
      next: (response) => {
        this.alertData = response.payload || [];
        
        const formattedLabels = this.alertData.labels.map((label: string) => {
          return moment(label).tz('America/Santiago').format('DD/MM/YYYY');
        });
  
        const formattedData = { ...this.alertData, labels: formattedLabels };
        this.generateChart(formattedData);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  generateChart( alertData: any){
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');
      const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
      const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

      this.chartData = {
          labels: alertData.labels,
          datasets: alertData.datasets
      };
      
      this.options = {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
              legend: {
                  labels: {
                      color: textColor
                  }
              }
          },
          scales: {
            x: {
                stacked: true,
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            },
            y: {
                stacked: true,
                ticks: {
                  beginAtZero: true,
                  stepSize: 1, 
                  callback: function(value: any) { 
                    return Number(value).toFixed(0);
                  }
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            }
        }
      };
  }
  
}
