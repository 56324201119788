import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {FormGroup} from "@angular/forms";

@Injectable()
export class DataService {
    private messageSource = new BehaviorSubject<any>(localStorage.getItem('formValues')); // Use a proper initial value
    currentMessage = this.messageSource.asObservable();
    constructor() { }
    changeMessage(formGroup: FormGroup) { // Use a proper type for the message parameter
        const formValues = JSON.stringify(formGroup.value);
        localStorage.setItem('formValues',formValues );
        this.messageSource.next(formValues)
    }
}
