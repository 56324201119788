import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GALLERY_CONFIG, GalleryComponent, GalleryConfig, GalleryItem, GalleryModule, ImageItem} from "ng-gallery";
import {PhotoSaved} from "../Views/show/show-template-active/entities/Photo";
import {GallerizeDirective, LightboxModule} from "ng-gallery/lightbox";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-photo-gallery',
  standalone: true,
  imports: [GalleryModule, GallerizeDirective, LightboxModule, GalleryComponent, NgIf],
  templateUrl: './photo-gallery.component.html',
  styleUrl: './photo-gallery.component.css',
  providers:[{
    provide: GALLERY_CONFIG,
    useValue: {
      autoHeight: true,
      imageSize: 'cover'
    } as GalleryConfig
  }]
})
export class PhotoGalleryComponent implements OnChanges,OnInit {
  @Input() galleryId = "";
  @Input() photos?: PhotoSaved[] = [];
  @Input() thumbWidth = 70;
  @Input() thumbHeight = 50;
  @Input() showThumbs = true;
  images: GalleryItem[] | [] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.images = this.photos?.map(photo => new ImageItem({
      src: photo.reference,
      thumb: photo.reference,
      args:photo.fileName
    })) || [];

    }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['photos']) {
      const photos = changes['photos'].currentValue as PhotoSaved[];
      const previousPhotos = changes['photos'].previousValue as PhotoSaved[];
      // Check if an item was added or deleted
      if (photos && previousPhotos) {
        const addedPhotos = photos.filter(photo => !previousPhotos.includes(photo));
        const deletedPhotos = previousPhotos.filter(photo => !photos.includes(photo));

        if (addedPhotos.length > 0) {
          this.images = photos?.map(photo => new ImageItem({
            src: photo.reference,
            thumb: photo.reference,
            args:photo.fileName
          })) || [];
        }
        if (deletedPhotos.length > 0) {
          this.images = photos?.map(photo => new ImageItem({
            src: photo.reference,
            thumb: photo.reference,
            args:photo.fileName
          })) || [];
        }
      }
    }else {
      // Update the images array based on the new photos array
      this.images = this.photos?.map(photo => new ImageItem({
        src: photo.reference,
        thumb: photo.reference,
      })) || [];
    }
    // Mark the component for change detection and trigger change detection
    this.cdr.detectChanges();
  }
}
