<!DOCTYPE html>
<html>
<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/css/bootstrap.min.css">
    <script src="//ajax.googleapis.com/ajax/libs/jquery/1.12.0/jquery.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/js/bootstrap.min.js"></script>
</head>
<body>
<ngx-loading-bar></ngx-loading-bar>

<div class="m-3">
    <div fxFlex="95" fxLayout="column">

        <h3  class ="logo  title m-3" fxFlex="none"> Mostrar Plantilla 📋</h3>
        <mat-form-field class="example-full-width">
            <mat-label >Nombre</mat-label>
            <input matInput class="form-control" placeholder="Nombre" id="Nombre" name="nombre_plantilla" [(ngModel)]="nombre" type="text" [disabled]="isDisabledEdit" [readonly]="isDisabledEdit">
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Tipo</mat-label>
            <mat-select [(value)]="selectedTipo" [disabled]="isDisabledEdit" >
                <mat-option *ngFor="let tipo of tipos" [value]="tipo._id">
                    {{ tipo.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="mat-elevation-z0 small-table">
            <table mat-table #dataTable [dataSource]="dataSource"
                   cdkDropList
                   [cdkDropListData]="dataSource"
                   (cdkDropListDropped)="drop($event)">
                <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                    <th mat-header-cell *matHeaderCellDef>{{  titleHeaderCell(column) }}</th>
                    <td mat-cell *matCellDef="let campo" >
                        <ng-container *ngIf="column !== 'obligatory' && column !== 'isHidden'; else checkboxOrText" >
                  <span *ngIf="column === 'type'">
                      <select class="form-select" style="width:100%"  [(ngModel)]="campo[column]" [disabled]="isDisabledEdit">
                        <option value="" disabled selected>Seleccione un tipo</option>
                        <option value="Text">Texto</option>
                        <option value="Number">Numero</option>
                        <option value="Checkbox">Checkbox</option>
                        <option value="Selector">Selector</option>
                        <option value="Photos">Fotos</option>
                        <option value="Files">Archivos</option>
                      </select>
                    </span>
                            <span *ngIf="column === 'name' || column === 'notation'">
                      <input matInput type="text" [(ngModel)]="campo[column]" [disabled]="isDisabledEdit" [readonly]="isDisabledEdit">
                    </span>
                        </ng-container>
                        <ng-template #checkboxOrText>
                            <mat-checkbox color="primary" [(ngModel)]="campo[column]" [disabled]="isDisabledEdit" ></mat-checkbox>
                        </ng-template>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </table>
        </div>
        <div class="mt-4 m-4">
            <button  style="margin-bottom: 50px; width: 250px;  height:50px; border-radius: 15px; margin-left: 10px;background-color: #008f39; color:white" mat-raised-button class="btn boton" id="añadir_planilla" (click)="didTapSaveChanged()" >
                {{textButtonEdit() }}</button>
        </div>
    </div>
</div>
</body>

</html>
