import {NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID} from '@angular/core';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BaseChartDirective } from 'ng2-charts';
import { AppComponent } from './app.component';
import { LoginComponent } from './Views/login/login.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {NgxPermissionsModule, NgxPermissionsService} from "ngx-permissions";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatTableModule} from "@angular/material/table";
import {MatChipsModule} from "@angular/material/chips";
import {MatCardModule} from "@angular/material/card";
import {MatSliderModule} from "@angular/material/slider";
import {MatSelectModule} from "@angular/material/select";
import {MatIconModule, MatIconRegistry} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatListModule} from "@angular/material/list";
import {MatDialogModule} from "@angular/material/dialog";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {NgxSpinnerModule} from 'ngx-spinner';
import {RouterOutlet } from "@angular/router";
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSidenavModule} from "@angular/material/sidenav";
import {SidenavService} from "./_services/SidenavService";
import {MatLineModule} from "@angular/material/core";
import { MainViewComponent } from './Views/main-view/main-view.component';
import { UserListComponent } from './Views/user/user-list/user-list.component';
import { MonitorsComponent } from './Views/monitor/monitors/monitors.component';
import {MatSortModule} from "@angular/material/sort";
import {ConfirmDialogComponent} from "./_dialog/confirm-dialog/confirm-dialog.component";
import {TokenInterceptor} from "./interceptor/TokenInterceptor";
import {ErrorInterceptor} from "./interceptor/error.interceptor";
import {LoadingBarHttpClientModule} from "@ngx-loading-bar/http-client";
import {LOADING_BAR_CONFIG} from "@ngx-loading-bar/core";
import {LoadingBarRouterModule} from "@ngx-loading-bar/router";
import { NoAccessComponent } from './Views/no-access/no-access.component';
import {AuthenticationService} from "./shared/authentication.service";
import { EmbedUrlMonitorComponent } from './Views/monitor/embed-url-monitor/embed-url-monitor.component';
import {PipesModule} from "./safe/safe.module";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {InfoMonitorComponent} from "./Views/info-monitor/info-monitor.component";
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import {MatTabsModule} from "@angular/material/tabs";
import { GojsAngularModule } from 'gojs-angular';
import {InfoMonitorGeneralComponent} from "./Views/info-monitor/info-monitor-general/info-monitor-general.component";
import {InfoMonitorGoJSComponent} from "./Views/info-monitor/info-monitor-go-js/info-monitor-go-js.component";
import {InfoMonitorMapComponent} from "./Views/info-monitor/info-monitor-map/info-monitor-map.component";
import { MatGridListModule } from '@angular/material/grid-list';
import {provideCharts, withDefaultRegisterables} from "ng2-charts";
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatCheckbox} from "@angular/material/checkbox";
import {CdkDrag, CdkDropList} from "@angular/cdk/drag-drop";
import {DataService} from "./_services/DataService";
import {ImgSliderDialogComponent} from "./_dialog/img-slider-dialog/img-slider-dialog.component";
import {SliderComponent} from "./_dialog/img-slider-dialog/slider/slider.component";
import {ImgDialogComponent} from "./_dialog/img-dialog/img-dialog.component";
import {ViewerFileDialogComponent} from "./_dialog/viewer-file-dialog/viewer-file-dialog.component";
import {UnitsComponent} from "./Views/unit/units/units.component";
import {DetailUnitComponent} from "./Views/unit/detail-unit/detail-unit.component";
import {InputaUpdateUserDialogComponent} from "./_dialog/inputs-update-user-dialog/input-rut-dialog.component";
import {InputDialogComponent} from "./_dialog/input-dialog/input-dialog.component";
import {
  InputEditDataGeneralDialogComponent
} from "./_dialog/input-edit-data-general-dialog/input-edit-data-general-dialog.component";
import {
  InputEditDataCompanyDialogComponent
} from "./_dialog/input-edit-data-company-dialog/input-edit-data-company-dialog.component";
import {ShowTemplateAssetComponent} from "./Views/show/show-template-asset/show-template-asset.component";
import {CreateTemplateAssetComponent} from "./Views/create/create-template-asset/create-template-asset.component";
import {ListTemplatesAssetComponent} from "./Views/list-templates-asset/list-templates-asset.component";
import {ShowDetailUnitComponent} from "./Views/show/show-detail-unit/show-detail-unit.component";
import {CreateAssetComponent} from "./Views/create/create-asset/create-asset.component";
import {ShowTemplateActiveComponent} from "./Views/show/show-template-active/show-template-active.component";
import {InputCommentDialogComponent} from "./_dialog/input-comment-dialog/input-comment-dialog.component";
import {
  InputEditDataUnitDialogComponent
} from "./_dialog/input-edit-data-unit-dialog/input-edit-data-unit-dialog.component";
import {InputTextDialogComponent} from "./_dialog/input-text-dialog/input-text-dialog.component";
import {AddItemDialogComponent} from "./_dialog/add-Item-dialog/add-Item-dialog.component";
import {
  SelectTemplateUnitDialogComponent
} from "./_dialog/select-template-unit-dialog/select-template-unit-dialog.component";
import {
  InputEditDataMonitorDialogComponent
} from "./_dialog/input-edit-data-monitor-dialog/input-edit-data-monitor-dialog.component";
import {LeafletMarkerClusterModule} from "@asymmetrik/ngx-leaflet-markercluster";
import {CompaniesComponent} from "./Views/companies/companies.component";
import {UbicationsComponent} from "./Views/ubications/ubications.component";
import {GALLERY_CONFIG,GalleryConfig, GalleryModule} from "ng-gallery";
import {LightboxModule} from "ng-gallery/lightbox";
import {PhotoGalleryComponent} from "./photo-gallery/photo-gallery.component";
import {NgxDocViewerModule} from "ngx-doc-viewer";
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { AccordionModule } from 'primeng/accordion';
import { StyleClassModule } from 'primeng/styleclass';
import { AvatarModule } from 'primeng/avatar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TabViewModule } from 'primeng/tabview';
import { TabMenuModule } from 'primeng/tabmenu';
import { SpeedDialModule } from 'primeng/speeddial';
import { ChartModule } from 'primeng/chart';
import { DashboardComponent } from './Views/dashboard/dashboard.component';
import { ChipModule } from 'primeng/chip';
import { KnobModule } from 'primeng/knob';
import { SkeletonModule } from 'primeng/skeleton';
import { ToastModule } from 'primeng/toast';
import { CurrentRiskChartComponent } from "./Views/dashboard/current-risk-chart/current-risk-chart.component";
import { AssetsMapComponent } from "./Views/dashboard/assets-map/assets-map.component";
import { AssetsListComponent } from "./Views/dashboard/assets-list/assets-list.component";
import { HistoricalAlertChartComponent } from "./Views/dashboard/historical-alert-chart/historical-alert-chart.component";
import { BimComponent } from './Views/bim/bim.component';
import { WIPComponent } from './Views/wip/wip.component';
import { BimViewerComponent } from './Views/bim-viewer/bim-viewer.component';
import { BimLoaderComponent } from './Views/bim-loader/bim-loader.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [

    AppComponent,
    LoginComponent,
    MainViewComponent,
    UserListComponent,
    CompaniesComponent,
    MonitorsComponent,
    ConfirmDialogComponent,
    NoAccessComponent,
    UbicationsComponent,
    EmbedUrlMonitorComponent,
    InfoMonitorComponent,
    InputEditDataGeneralDialogComponent,
    InputEditDataCompanyDialogComponent,
    InfoMonitorGeneralComponent,
    InfoMonitorGoJSComponent,
    InfoMonitorMapComponent,
    CreateTemplateAssetComponent,
    ShowTemplateAssetComponent,
    ListTemplatesAssetComponent,
    UnitsComponent,
    InputaUpdateUserDialogComponent,
    InputDialogComponent,
    InputEditDataMonitorDialogComponent,
    DetailUnitComponent,
    ShowDetailUnitComponent,
    CreateAssetComponent,
    ShowTemplateActiveComponent,
    ImgSliderDialogComponent,
    SliderComponent,
    ImgDialogComponent,
    ViewerFileDialogComponent,
    InputCommentDialogComponent,
    InputEditDataUnitDialogComponent,
    InputTextDialogComponent,
    AddItemDialogComponent,
    SelectTemplateUnitDialogComponent,
    DashboardComponent,
    AssetsMapComponent,
    AssetsListComponent,
    BimComponent,
    BimViewerComponent,
    BimLoaderComponent
    
  ],
    imports: [
    CommonModule,
    BaseChartDirective,
    MatTabsModule,
    LeafletModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    AppRoutingModule,
    MatToolbarModule,
    MatDialogModule,
    MatButtonModule,
    MatSnackBarModule,
    MatMenuModule,
    MatFormFieldModule,
    MatBottomSheetModule,
    MatSelectModule,
    MatSliderModule,
    MatCardModule,
    MatChipsModule,
    BrowserModule,
    HttpClientModule,
    LeafletMarkerClusterModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatDialogModule,
    MatListModule,
    MatSnackBarModule,
    MatIconModule,
    MatMenuModule,
    MatBottomSheetModule,
    NgxSpinnerModule,
    MatSelectModule,
    MatSliderModule,
    MatTableModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    NgOptimizedImage,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    RouterOutlet,
    MatSidenavModule,
    MatLineModule,
    MatSortModule,
    PipesModule,
    MatProgressSpinnerModule,
    GojsAngularModule,
    MatGridListModule,
    MatPaginatorModule,
    MatCheckbox,
    CdkDrag,
    CdkDropList,
    BrowserModule,
    PhotoGalleryComponent,
    GalleryModule,
    LightboxModule,
    NgxDocViewerModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextModule,
    PasswordModule,
    CheckboxModule,
    CardModule,
    DropdownModule,
    OrganizationChartModule,
    ButtonModule,
    TableModule,
    AccordionModule,
    StyleClassModule,
    AvatarModule,
    PanelMenuModule,
    TabViewModule,
    TabMenuModule,
    SpeedDialModule,
    ChartModule,
    ChipModule,
    KnobModule,
    SkeletonModule,
    CurrentRiskChartComponent,
    HistoricalAlertChartComponent,
    WIPComponent,
    ToastModule
],
  providers: [

      {
          provide: GALLERY_CONFIG,
          useValue: {
              autoHeight: true,
              imageSize: 'cover'
          } as GalleryConfig
      },
      provideCharts(withDefaultRegisterables()),
    { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 100 } },
    SidenavService,DataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor
      , multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private permissionsService: NgxPermissionsService,
              private authService: AuthenticationService,
              private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    let perm = this.authService.getPermissions()
    this.permissionsService.loadPermissions(perm);
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );
  }
}
