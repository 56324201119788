<ngx-loading-bar></ngx-loading-bar>

<p-table
  #dt
  [value]="items"
  [columns]="columns"
  styleClass="m-2 p-datatable-sm"
  dataKey="columns._id"
  [rowHover]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50]"
  [loading]="loading"
  [paginator]="true"
  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Unidades"
  [filterDelay]="0"
  [globalFilterFields]="[
    'name',
    'country.name',
    'representative.name',
    'status'
  ]"
>
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-content-between">
      <span class="text-primary text-lg">Unidades</span>
      <p-button
        icon="pi pi-plus"
        label="Crear Unidad"
        [outlined]="true"
        size="small"
        (onClick)="didTapCreateUnit()"
      />
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns">
        {{ col.header }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr>
      <td>{{ item.name }}</td>
      <td>{{ item.company.name }}</td>
      <td>{{ item.description }}</td>
      <td>
        <p-button
          *ngxPermissionsOnly="['MASTER', 'ADMIN', 'USER','VISIT']"
          icon="pi pi-eye"
          class="mr-2 border-round"
          [outlined]="true"
          size="small"
          label="Ver"
          severity="primary"
          (onClick)="didTapOpen(item)"
        >
        </p-button>
        <p-button
          *ngxPermissionsOnly="['MASTER', 'ADMIN']"
          icon="pi pi-pencil"
          class="mr-2 border-round"
          [outlined]="true"
          size="small"
          label="Editar"
          severity="primary"
          (onClick)="didtapEdit(item)"
        />
        <p-button
          *ngxPermissionsOnly="['MASTER', 'ADMIN']"
          icon="pi pi-trash"
          class="border-round"
          [outlined]="true"
          size="small"
          label="Eliminar"
          severity="danger"
          (onClick)="didTapDelete(item)"
        />
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8">No se encontraron Unidades.</td>
    </tr>
  </ng-template>
</p-table>
