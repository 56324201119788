<ngx-loading-bar></ngx-loading-bar>
<div class="col-12 mt-3 border-1 surface-border p-0 w-full bg-cover border-round" 
  id="map"
    leaflet
    [leafletOptions]="options"
    [leafletLayersControl]="layersControl"
    (leafletMouseOver)="refreshMap()"
    (leafletMapReady)="onMapReady($event)"
>
</div>

