
<!DOCTYPE html>
<html>

<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/css/bootstrap.min.css">
</head>
<ngx-loading-bar></ngx-loading-bar>
<body>
<div fxFlex="95" fxLayout="column" style="margin: 5px;font-family: Roboto, sans-serif!important;font-size:10px !important;">
  <h1  class ="logo  title m-3" fxFlex="none"> Lista de usuarios 🧑</h1>
  <div  class="centered-div" *ngxPermissionsOnly ="['MASTER', 'ADMIN']">
    <button mat-button (click)="onSelectCreateUser()"  mat-flat-button class="mat-button-register">
      <mat-icon inline=true>add</mat-icon>
      Crear usuario
    </button>
  </div>
  <table mat-table #dataTable  [dataSource]="dataSource" class="mat-elevation-z0">
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef> Nombre </th>
      <td mat-cell *matCellDef="let user"> {{assertItemType(user).firstName}} </td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef> Apellido </th>
      <td mat-cell *matCellDef="let user"> {{assertItemType(user).lastName}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let user"> {{assertItemType(user).email}} </td>
    </ng-container>
    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef> Compañia </th>
      <td mat-cell *matCellDef="let user"> {{assertItemType(user).company?.name}} </td>
    </ng-container>
    <ng-container matColumnDef="permission">
      <th mat-header-cell *matHeaderCellDef> Permiso </th>
      <td mat-cell *matCellDef="let user"> {{assertItemType(user).permission?.name}} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Acciones </th>
      <mat-cell *matCellDef="let user" >
        <div  *ngxPermissionsOnly = "['MASTER', 'ADMIN']">
          <button mat-button (click)="didTapEditUser(user)">
            <mat-icon class = "svg" svgIcon="pencil-outline"></mat-icon>
            Editar
          </button>
        </div>
        <div *ngxPermissionsOnly="['MASTER','ADMIN']">
          <button mat-button  (click)="didTapDeleteUser(user)">
            <mat-icon class = "svg" svgIcon="delete-outline"></mat-icon>
            Borrar
          </button>
        </div>
      </mat-cell>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">

    </tr>
  </table>
</div>
<!-- Placed at the end of the document so the pages load faster -->
<script src="//ajax.googleapis.com/ajax/libs/jquery/1.12.0/jquery.min.js"></script>
<script src="//maps.googleapis.com/maps/api/js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/js/bootstrap.min.js"></script>


</body>

</html>

