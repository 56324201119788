import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {MatSnackBar} from "@angular/material/snack-bar";
import {RestApiService} from "../../shared/rest-api.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Company} from "../../Views/companies/model/company";

@Component({
  selector: 'app-root-input-edit-data-general-dialog',
  templateUrl: './input-edit-data-general-dialog.component.html',
  styleUrls: ['./input-edit-data-general-dialog.component.scss']
})

export class InputEditDataGeneralDialogComponent implements OnInit {
  constructor(
      private breakpointObserver: BreakpointObserver,
      private dialogRef: MatDialogRef<InputEditDataGeneralDialogComponent>,
      private restApiService: RestApiService,
      private formBuilder: FormBuilder,
      private snackBar: MatSnackBar,
      @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  loginForm!: FormGroup;
  companies: Company[] = [];
  companyId = -1;
  flex_reduce_size  = 'row';
  registering = false;
  permission = 'ADMIN';
  type = 'REGISTRAR' ;
  ngOnInit(): void {
    this.breakpointObserver
        .observe(['(min-width: 720px)'])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.flex_reduce_size  = 'row';
          } else {
            this.flex_reduce_size  = 'column';
          }
        });
    if (this.data.type === 'ACTUALIZAR' ) {
      this.type = 'ACTUALIZAR' ;
      this.companyId = this.data.companyId
      this.loginForm = this.formBuilder.group({
        name: [this.data.name, Validators.required],
        description: [this.data.description, Validators.required]
      });

    } else {
      this.type = 'REGISTRAR' ;
      this.loginForm = this.formBuilder.group({
        name: ['', Validators.required],
        description: ['', Validators.required]
      });
    }
    this.restApiService.getCompanies().subscribe({
      next: (response) => {
        if (response.success) {
          this.companies = response.payload
        } else {
          this.displayError()
        }
      },
      error: (error) => {
        this.displayError()
      }
    });
  }
  register() {
    this.registering = true
    const name: string = this.loginForm?.get('name')?.value ?? ""
    const description: string = this.loginForm?.get('description')?.value ?? ""
   if(name.length === 0){
      this.snackBar.open('Nombre no definido', '', {
        duration: 3000, verticalPosition: 'top',
        panelClass: 'notif-success'
      });
    }
    else if(description.length === 0){
      this.snackBar.open('Descripción no definida', '', {
        duration: 3000, verticalPosition: 'top',
        panelClass: 'notif-success'
      });
    } else {
      if (this.type == 'REGISTRAR') {
        this.restApiService.createubication(name,description, this.companyId.toString()).subscribe({
          next: (response) => {
            this.registering = false;
            if (response.success) {
              this.dialogRef.close(this.loginForm?.value);
            } else if (response.status === 'duplicate') {

              this.snackBar.open('Existe ubicación', '', {
                duration: 3000, verticalPosition: 'bottom',
                panelClass: ['blue-snackbar']
              });
            }

          },
          error: (error) => {
            this.registering = false;
            this.displayError()
          }
        });
      }else {
        this.restApiService.updateUbication(this.data.ubicationId, name,description,this.companyId.toString()).subscribe({
          next: (response) => {
            if (response.success) {
              this.registering = false;
            }
          },
          error: (error) => {
            this.registering = false;
            this.displayError()
          }
        });
      }
    }
  }
  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
}
