import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-wip',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './wip.component.html',
  styleUrl: './wip.component.css'
})
export class WIPComponent {

  constructor(private location: Location) { }

  didTapReturn() {
    this.location.back();
  }

}
