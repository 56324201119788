import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {NgxPermissionsService} from 'ngx-permissions';
import * as CryptoJS from 'crypto-js';
import {environment} from "../../environments/environment";
import {Login} from "../Views/login/models/login";
import {User} from "../Views/user/user-list/model/User";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject?: BehaviorSubject<User>;
  public currentUser?: Observable<User>;
  constructor(private permissionsService: NgxPermissionsService, private http: HttpClient) {
    if (localStorage.getItem('currentUser')) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse( localStorage.getItem('currentUser') ?? ""));
      this.currentUser = this.currentUserSubject.asObservable();
    }
  }
  login(email:string, passwordNormal:string) {
    let password = CryptoJS.AES.encrypt(passwordNormal, environment.tokenPassword).toString();
    return this.http.post<Login>(`${environment.apiUrl}/user/login/`, { email, password})
      .pipe(map(user => {
        if (user) {
          let perm: string[] = [user.payload.permission?.name ?? ""];
          this.permissionsService.loadPermissions(perm);
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('access_token', user.access_token);
          localStorage.setItem('permissions', JSON.stringify(perm));
          return user;
        }
        return user;
      }));
  }
  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('permissions');
    localStorage.removeItem('access_token');
    this.permissionsService.flushPermissions();
  }
  getPermissions(){
    const permission: string[] = JSON.parse(localStorage.getItem('permissions') ?? "[]");
    return permission;
  }
  getCurrentUser(){
    return JSON.parse(localStorage.getItem('currentUser') ?? "");
  }
}
