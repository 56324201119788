<p-table
  #dt
  [value]="items"
  [columns]="columns"
  styleClass="p-datatable-sm"
  dataKey="columns._id"
  [rowHover]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50]"
  [loading]="loading"
  [paginator]="true"
  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Activos"
  [filterDelay]="0"
  [globalFilterFields]="[
    'name',
    'country.name',
    'representative.name',
    'status'
  ]"
>
  <ng-template pTemplate="caption">
    <div class="flex align -items-center justify-content-between ">
      <span class="text-primary text-lg" *ngIf="infoUnit">{{
        infoUnit.name
      }}</span>
      <p-button
        icon="pi pi-plus"
        label="Crear Activo"
        [outlined]="true"
        size="small"
        (onClick)="didTapCreateAsset()"
      />
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns">
        {{ col.header }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr>
      <td>{{ assertItemType(item).name }}</td>
      <td>{{ assertItemType(item).description }}</td>
      <td>
        <p-button
          *ngxPermissionsOnly="['MASTER', 'ADMIN', 'USER', 'VISIT']"
          icon="pi pi-eye"
          class="mr-2 border-round"
          [outlined]="true"
          size="small"
          label="Ver"
          severity="primary"
          (onClick)="onTapOpen(item)"
        >
        </p-button>
        <p-button
          *ngxPermissionsOnly="['MASTER', 'ADMIN']"
          icon="pi pi-trash"
          class="mr-2 border-round"
          [outlined]="true"
          size="small"
          label="Borrar"
          severity="danger"
          (onClick)="didTapDelete(item)"
        >
        </p-button>
      </td>
      <td *ngIf="assertItemType(item).is_active === 'on'">
        <p-button
          *ngxPermissionsOnly="['MASTER', 'ADMIN', 'USER', 'VISIT']"
          icon="pi pi-camera"
          class="mr-2 border-round"
          [outlined]="true"
          size="small"
          label="Vision Artificial"
          severity="primary"
          (onClick)="didTapOpenVA(item)"
          [disabled]="checkEnableVA(item)"
        >
        </p-button>
        <p-button
          *ngxPermissionsOnly="['MASTER', 'ADMIN', 'USER', 'VISIT']"
          icon="pi pi-box"
          class="mr-2 border-round"
          [outlined]="true"
          size="small"
          label="BIM"
          severity="primary"
          (onClick)="didTapOpenBim(item)"
          [disabled]="checkEnableBim(item)"
        >
        </p-button>
        <p-button
          *ngxPermissionsOnly="['MASTER', 'ADMIN', 'USER', 'VISIT']"
          icon="pi pi-map-marker"
          class="mr-2 border-round"
          [outlined]="true"
          size="small"
          label="Monitoreo Indirecto"
          severity="primary"
          (onClick)="didTapOpenMI(item)"
          [disabled]="checkEnableMI(item)"
        >
        </p-button>
        <p-button
          *ngxPermissionsOnly="['MASTER', 'ADMIN', 'USER', 'VISIT']"
          icon="pi pi-chart-bar"
          class="mr-2 border-round"
          [outlined]="true"
          size="small"
          label="Monitoreo Directo"
          severity="primary"
          (onClick)="didTapOpenMD(item)"
          [disabled]="checkEnableMD(item)"
        >
        </p-button>
      </td>
      <td *ngIf="assertItemType(item).is_active === 'off'">
      </td>
    </tr>
  </ng-template>
</p-table>
