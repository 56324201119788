import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DialogService,
  DynamicDialogComponent,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import L, { Icon, icon } from 'leaflet';
import { RestApiService } from 'src/app/shared/rest-api.service';
import { ResultDetailsActive } from 'src/app/Views/unit/units/model/list_units';
import { InputTemplateData } from 'src/app/Views/show/show-template-asset/InputTemplateData';
import { FieldsetModule } from 'primeng/fieldset';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { PanelModule } from 'primeng/panel';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';

@Component({
  selector: 'app-view-asset-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FieldsetModule,
    ButtonModule,
    TooltipModule,
    PanelModule,
    GalleriaModule,
    ImageModule,
  ],
  templateUrl: './view-asset-dialog.component.html',
  styleUrl: './view-asset-dialog.component.css',
})
export class ViewAssetDialogComponent implements OnInit {
  instance?: DynamicDialogComponent;
  assetID: any;
  items?: ResultDetailsActive;
  inputTemplateData?: InputTemplateData[] = [];
  private map!: L.Map;
  fromMap: false;
  marker?: L.Marker = L.marker([0, 0]);

  constructor(
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
    private apiService: RestApiService
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
    this.assetID = this.instance?.data.assetID;
    this.fromMap = this.instance?.data.fromMap;
  }

  ngOnInit(): void {
    this.apiService.getDetailsTemplateActive(this.assetID).subscribe({
      next: (data) => {
        this.items = data.payload;
        this.inputTemplateData = this.items?.resultInput;
        if (!this.fromMap) {
          this.initializeMap();
          this.addMarker();
          this.centerMap();
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  private initializeMap() {
    const baseMapURl =
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
    this.map = L.map('mapAsset');
    this.map.options.maxZoom = 17;
    this.map.options.minZoom = 14;
    L.tileLayer(baseMapURl).addTo(this.map);
  }

  private addMarker() {
    this.marker = L.marker([
      this.items?.result.latitude ?? 0,
      this.items?.result.longitude ?? 0,
    ], {
      icon: icon({
        ...Icon.Default.prototype.options,
        iconUrl: 'assets/marker-icon.png',
        iconRetinaUrl: 'assets/marker-icon-2x.png',
        shadowUrl: 'assets/marker-shadow.png'
      })
    });
    this.marker.addTo(this.map);
  }

  private centerMap() {
    this.map.setView(this.marker?.getLatLng() ?? [0, 0], 16);
  }

  openBIM() {
    window.open(
      'https://2024001-01-visor-ifc-git-main-linddevelopers-projects.vercel.app/editor.html?id=' +
        this.items?.result._id,
      '_blank'
    );
  }

  openVA() {
    window.open(
      'https://2024001-04-vision-artificial-front.vercel.app/' +
        this.items?.result._id,
      '_blank'
    );
  }

  openMD() {
    const correspondingMonitor = this.items?.monitorsDefine.find(
      (monitor) => monitor._activeUnit === this.items?.result._id
    );
    if (correspondingMonitor) {
      const url = correspondingMonitor.url;
      window.open(url, '_blank');
    }
  }
  openMI() {
    const correspondingMonitor = this.items?.monitorsDefine.find(
      (monitor) => monitor._activeUnit === this.items?.result._id
    );
    if (correspondingMonitor) {
      const url = correspondingMonitor.url;
      window.open(url, '_blank');
    }
  }
}
