import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Chart, TooltipItem } from 'chart.js';
import { ChartModule } from 'primeng/chart';
import { SkeletonModule } from 'primeng/skeleton';
import { RestApiService } from 'src/app/shared/rest-api.service';
import { formatDate } from 'src/app/shared/utils/date-utils';

@Component({
  selector: 'app-current-risk-chart',
  standalone: true,
  imports: [ChartModule, SkeletonModule, CommonModule],
  templateUrl: './current-risk-chart.component.html',
  styleUrl: './current-risk-chart.component.css',
})
export class CurrentRiskChartComponent implements OnChanges {
  @Input() data: any;
  chartData: any;
  chartRiskData: any;
  options: any;
  plugins: any;

  constructor(
    private restApiService: RestApiService
  ) {
    Chart.register({
      id: 'NoData',
      afterDraw: function (chart) {
        if (
          chart.data.datasets
            .map((d) => d.data.length)
            .reduce((p, a) => p + a, 0) === 0
        ) {
          const ctx = chart.ctx;
          const width = chart.width;
          const height = chart.height;
          chart.clear();
    
          ctx.save();
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = `1.5rem ${window.getComputedStyle(document.body).fontFamily}`;
          ctx.fillStyle = 'black';
          ctx.fillText('No hay datos para mostrar', width / 2, height / 2);          
        }
      },
    });
  }

  ngOnChanges(changes: any) {
    if (changes.data && changes.data.currentValue) {
      this.getLatestRiskValues(changes.data.currentValue);
    }
  }

  getLatestRiskValues(companyId: string) {
    this.restApiService.getLatestWorstRisks(companyId).subscribe({
      next: (response) => {
        this.chartRiskData = response;
        this.generateChart();
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  generateChart(){


    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    
    this.chartData = {
      labels: this.chartRiskData.payload.labels, 
      datasets: [
        {
          label: 'Umbral Riesgo Alto',
          borderColor: documentStyle.getPropertyValue('--red-500'),
          fill: false,
          pointBackgroundColor: documentStyle.getPropertyValue('--red-500'),
          pointBorderColor: documentStyle.getPropertyValue('--red-500'),
          pointHoverBackgroundColor: textColor,
          pointHoverBorderColor: documentStyle.getPropertyValue('--red-500'),
          data: this.chartRiskData.payload.datasets.find((ds: { label: string; }) => ds.label === 'Umbral Riesgo Alto')?.data.map((d: { value: any; }) => d.value) || [],
        },
        {
          label: 'Umbral Riesgo Medio',
          borderColor: documentStyle.getPropertyValue('--orange-500'),
          fill: false,
          pointBackgroundColor: documentStyle.getPropertyValue('--orange-500'),
          pointBorderColor: documentStyle.getPropertyValue('--orange-500'),
          pointHoverBackgroundColor: textColor,
          pointHoverBorderColor: documentStyle.getPropertyValue('--orange-500'),
          data: this.chartRiskData.payload.datasets.find((ds: { label: string; }) => ds.label === 'Umbral Riesgo Medio')?.data.map((d: { value: any; }) => d.value) || [],
        },
        {
          label: 'Valor Riesgo',
          borderColor: documentStyle.getPropertyValue('--primary-500'),
          pointBackgroundColor: documentStyle.getPropertyValue('--primary-500'),
          pointBorderColor: documentStyle.getPropertyValue('--primary-500'),
          pointHoverBackgroundColor: textColor,
          pointHoverBorderColor: documentStyle.getPropertyValue('--primary-500'),
          data: this.chartRiskData.payload.datasets.find((ds: { label: string; }) => ds.label === 'Valor Riesgo')?.data.map((d: { value: any; }) => d.value) || [],
        },
      ],
    };

    this.options = {
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem: TooltipItem<'radar'>) => {
              const dataset = this.chartData.datasets[tooltipItem.datasetIndex];
              const index = tooltipItem.dataIndex;
              const value = dataset.data[index];
              const prev_label = dataset.label;
              if (dataset.label === 'Valor Riesgo') {
                const timestamp = this.chartRiskData.payload.datasets[tooltipItem.datasetIndex].data[index].timestamp;
                const date = formatDate(timestamp);
                return [`Riesgo: ${value}%`, `Fecha: ${date}`];
              } else {
                return `${prev_label}: ${value}%`;
              }
            }
          }
        
        },
        legend: {
          labels: {
            color: textColor,
            font: {
              family: 'Poppins'
            }
          },
        },
      },
      scales: {
        r: {
          grid: {
            color: textColorSecondary,
          },
          pointLabels: {
            color: textColorSecondary,
          },
        },
      },
    };
  }

  validateChartData(data: any) {
    return data.payload.labels.length > 0;
  }

}
