import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RestApiService} from "../../shared/rest-api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Permission} from "../../Views/user/register-user/model/Permission";
import {Ubication} from "../../Views/ubications/model/Ubication";

@Component({
  selector: 'app-root-input-rut-dialog',
  templateUrl: './inputs-update-user-dialog.component.html',
  styleUrls: ['./inputs-update-user-dialog.component.scss']
})
export class InputaUpdateUserDialogComponent implements OnInit {
  // @ts-ignore
  form: FormGroup;
  fname: string;
  user_id:string
  lname: string;
  email: string;
  companies: Ubication[] = [];
  permissions: Permission[] = [];
  selectedCompanyId: number = 1;
  loading = false;
  hiddenShowPassword = true;
  loadingUpdate = false;
  selectedTypeUser = 4
  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private restApiService:RestApiService,
    private dialogRef: MatDialogRef<InputaUpdateUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.fname = data.fname
    this.lname = data.lname
    this.email = data.email
    this.user_id = data.user_id
    this.selectedTypeUser = data.type_user ?? 4
    this.selectedCompanyId = data.company ?? 1
    this.hiddenShowPassword = data.hiddenShowPassword ?? true
  }
  ngOnInit(): void {
    this.form = this.fb.group({
      fname: [this.fname, []],
      lname: [this.lname, []],
      email: [this.email, []],
      password: ['', Validators.required],
      repeat_password: ['', Validators.required]
    });


    this.restApiService.getCompaniesAndPermission().subscribe({
      next: (response) => {
        if (response) {
          this.companies = response.payload.companies
          this.permissions = response.payload.permissions
        } else {
          this.displayError()
        }
      },
      error: (error) => {
        this.displayError()
      }
    });
  }
  editClient(){
    const fname: string = this.form?.get('fname')?.value ?? ""
    const lname: string = this.form?.get('lname')?.value ?? ""
    const password: string = this.form?.get('password')?.value ?? ""
    const email: string = this.form?.get('email')?.value ?? ""
    const repeat_password: string = this.form?.get('repeat_password')?.value ?? ""

    if(fname.length === 0){
      this.snackBar.open('Nombre no definido', '', {
        duration: 3000, verticalPosition: 'top',
        panelClass: 'notif-success'
      });
    } else if(lname.length === 0){
      this.snackBar.open('Apellido no definido', '', {
        duration: 3000, verticalPosition: 'top',
        panelClass: 'notif-success'
      });
    } else if(email.length === 0){
      this.snackBar.open('Email no definida', '', {
        duration: 3000, verticalPosition: 'top',
        panelClass: 'notif-success'
      });
    } else {
      if (this.hiddenShowPassword) {
        this.loadingUpdate = true
        this.restApiService.updateClientAdminPassword(this.statusFromUser(), this.user_id, fname, lname, email, password, this.selectedCompanyId.toString(), this.selectedTypeUser.toString()).subscribe({
          next: (response) => {
            this.loadingUpdate = false
            if (response.success) {
              if (response.status === "edit") {
                this.dialogRef.close(this.form?.value);
              } else {
                this.dialogRef.close(this.form?.value);
              }
            } else {
              this.loadingUpdate = false
              this.displayError()
            }
          },
          error: (error) => {
            this.loadingUpdate = false
            this.displayError()
          }
        });
      } else {
        if (password === repeat_password) {
          this.loadingUpdate = true
          this.restApiService.updateClientAdminPassword(this.statusFromUser(), this.user_id, fname, lname, email, password, this.selectedCompanyId.toString(), this.selectedTypeUser.toString()).subscribe({
            next: (response) => {
              this.loadingUpdate = false
              if (response.success) {
                if (response.status === "edit") {
                  this.dialogRef.close(this.form?.value);
                } else {
                  this.dialogRef.close(this.form?.value);
                }
              } else {
                this.loadingUpdate = false
                this.displayError()
              }
            },
            error: (error) => {
              this.loadingUpdate = false
              this.displayError()
            }
          });
        } else {
          this.loadingUpdate = false
          this.snackBar.open('Contraseñas no coinciden', '', {
            duration: 3000, verticalPosition: 'bottom',
          });
        }
      }
    }
  }
  textbuttonEditClient (){
    if( this.user_id.length === 0){
      return "Crear"
    } else {
      return "Actualizar"
    }
  }
  statusFromUser (){
    if( this.user_id?.length === 0 || this.user_id === undefined){
      return "new"
    } else {
      return "edit"
    }
  }
  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
}
