import { Component, Input, OnChanges } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ViewAssetDialogComponent } from 'src/app/_dialog/view-asset-dialog/view-asset-dialog.component';

@Component({
  selector: 'app-assets-list',
  templateUrl: './assets-list.component.html',
  styleUrl: './assets-list.component.css',
  providers: [DialogService]
})

export class AssetsListComponent implements OnChanges {
  @Input() data: any;
  selectedData: any;
  ref: DynamicDialogRef | undefined;

  constructor(public dialogService: DialogService) {}

  ngOnChanges(changes: any) {

    if (changes.data && changes.data.currentValue) {
      this.data = changes.data.currentValue;
    }
  }

  didTapViewAsset(assetID: string) {
    this.showDynamicElement(assetID);
  }

  showDynamicElement(assetID: string,) {
    const headerDialog = 'Detalle de Activo';

    this.ref = this.dialogService.open(ViewAssetDialogComponent, {
      header: headerDialog,
      width: '90vw',
      height: '90vh',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      modal: true,
      data: {
        assetID: assetID,
        fromMap: false,
      },
    });
  }

  onRowSelect(event: any) {
    this.selectedData = event.data;
    this.showDynamicElement(this.selectedData._id);
  }
}
