import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {InputTemplate} from "../../Views/show/show-template-asset/InputTemplate";
import {RestApiService} from "../../shared/rest-api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DataService} from "../../_services/DataService";
import {MatSelectChange} from "@angular/material/select";
import {InputTemplateData} from "../../Views/show/show-template-asset/InputTemplateData";
import {FileSaved} from "../../Views/show/show-template-active/entities/File";
import {MatTableDataSource} from "@angular/material/table";
import {PhotoSaved} from "../../Views/show/show-template-active/entities/Photo";
import {ImgDialogComponent} from "../img-dialog/img-dialog.component";
import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";
import {ResultDetailsActive} from "../../Views/unit/units/model/list_units";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {InputTemplateDataInfo} from "../../Views/show/show-template-asset/InputTemplateInfo";
import {DatePipe} from "@angular/common";
import {ViewerFileDialogComponent} from "../viewer-file-dialog/viewer-file-dialog.component";


@Component({
  selector: 'app-root-select-template-unit-dialog',
  templateUrl: './select-template-unit-dialog.component.html',
  providers:[DatePipe],
  styleUrls: ['./select-template-unit-dialog.component.scss']
})
export class SelectTemplateUnitDialogComponent implements OnInit {
  displayedColumns: string[] = ['name','createdAt', 'actions'];
  unidadId?: string;
  selectedActiveId?:string;
  typeId?: string;
  templateSelectedId?:InputTemplate
  template?: InputTemplate
  enterpriseId?:string
  formGroup?: FormGroup;
  inputTemplate?:InputTemplate
  items?: ResultDetailsActive
  inputTemplateData?:InputTemplateData[] = [];
  inputTemplateDataInfo?:InputTemplateDataInfo[] = [];
  photos?: PhotoSaved[] = []
  files?: FileSaved[] = []
  templates: InputTemplate[] = [];
  constructor(private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<SelectTemplateUnitDialogComponent>,
              private restApiService: RestApiService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private dataService: DataService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.dataService.currentMessage.subscribe(jsonString => {
      const jsonData = JSON.parse(jsonString);
      const formControls = {};
      for (let key in jsonData) {
        if (jsonData.hasOwnProperty(key)) {
          // @ts-ignore
          formControls[key] = [jsonData[key], Validators.required];
        }
      }
      this.formGroup = this.formBuilder.group(formControls);
      this.typeId =  this.data.typeId
      this.unidadId =  this.data.unidadId
      this.enterpriseId = this.data.enterpriseId
      this.selectedActiveId = this.data.selectedActiveId
      this.loadData(this.typeId)
    })
  }
  loadData(tipeId:any){
    this.restApiService.getTemplateAssetByType(tipeId).subscribe({
      next: (response) => {
        if (response.success) {
          this.templates = response.payload
        } else {
          this.snackBar.open('Error intentarlo de nuevo', '', {
            duration: 3000, verticalPosition: 'bottom',
            panelClass: 'notif-success'
          });
        }
      },
      error: (error) => {
        this.snackBar.open('Error intentarlo de nuevo', '', {
          duration: 3000, verticalPosition: 'bottom',
          panelClass: ['blue-snackbar']
        });
      }
    });
  }
  onSelect(event: MatSelectChange) {
    this.inputTemplateDataInfo = []
    this.inputTemplate = event.value as InputTemplate
    this.inputTemplateData = this.inputTemplate.inputTemplate
    if (this.inputTemplateData){
      for (let i = 0; i < this.inputTemplateData?.length; i++) {
        if( this.inputTemplateData[i].type === "Photos"){
          this.inputTemplateData[i].photos = []
          this.inputTemplateData[i].dataSourcePhotoFilesDetails = new MatTableDataSource<any>(this.inputTemplateData[i].photos)
        }
        if(this.inputTemplateData[i].type === "Files"){
          this.inputTemplateData[i].files =  []
          this.inputTemplateData[i].dataSourcePhotoFilesDetails = new MatTableDataSource<any>(this.inputTemplateData[i].files)
        }
      }
    }
  }

  createOptionSelect(item: InputTemplateData):string[] {
    const trimmedOptions = item.notation?.replace(/,\s+/g, ',') ?? ""
    return trimmedOptions.split(',');
  }

  didTapSaveAsset(){
    let nombre = this.formGroup?.value['nombre_activo']
    let es_activo = "on"
    let hasBIM = false
    let hasMD = false
    let hasMI = false
    let hasVA = false
    let plantilla = this.inputTemplate?._id
    let tipo_de_activo = this.typeId
    let descripcion = this.formGroup?.value['descripcion']
    let empresa = this.enterpriseId
    let URLMD = "https://md.lind.global"
    let unidad = this.unidadId
    let latitud = Number(this.formGroup?.value['latitud'])
    let longitud = Number(this.formGroup?.value['longitud'])

    this.restApiService.createTemplateActiveSelector(nombre, es_activo, hasBIM,
        hasMD, hasMI, hasVA, plantilla, tipo_de_activo, descripcion, empresa, URLMD,
        unidad, latitud, longitud,this.selectedActiveId, this.inputTemplateDataInfo ?? [] ,
        this.photos,this.files).subscribe({
      next: (response) => {
        if (response.success){
          this.dialogRef.close(response);
        }else{
          this.snackBar.open('Error Intentarlo de nuevo', '', {
            duration: 3000, verticalPosition: 'bottom',
            panelClass: 'notif-success'
          });
        }
      },
      error: (error) => {
        this.snackBar.open('Error intentarlo de nuevo', '', {
          duration: 3000, verticalPosition: 'bottom',
          panelClass: ['blue-snackbar']
        });
      }
    });
  }


  uploadOnlyFile(event: any,item: InputTemplateData,i:number) {
    const files = event.target.files;
    if (files.length > 0) {
      const formData = new FormData();
      formData.append("file", files[0])
      this.restApiService.uploadFile(formData, this.selectedActiveId ?? "", item._id ?? "",this.templateSelectedId?._id ?? "").subscribe({
        next: (response) => {
          if (response.success) {
            let newFile:FileSaved = {
              _id:response.uploadFile._id,
              reference:response.uploadFile.reference,
              createdAt:response.uploadFile.createdAt,
              fileName:response.uploadFile.fileName
            }

            if (this.inputTemplateData) {
              const index = this.inputTemplateData?.findIndex(data => data.type === "Files" && data._id === item._id);
              if (index !== -1) {
                this.inputTemplateData[index].files?.push(newFile);
                this.files?.push(newFile)
                this.inputTemplateData[index].dataSourcePhotoFilesDetails = new MatTableDataSource<any>(this.inputTemplateData[index].files)
              }
            }
            this.snackBar.open('Se subio la archivo!', '', {
              duration: 3000, verticalPosition: 'bottom',
              panelClass: 'notif-success'
            });
          } else {
            this.snackBar.open('Error intentarlo de nuevo', '', {
              duration: 3000, verticalPosition: 'bottom',
              panelClass: 'notif-success'
            });
          }
        },
        error: (error) => {
          this.snackBar.open('Error intentarlo de nuevo', '', {
            duration: 3000, verticalPosition: 'bottom',
            panelClass: ['blue-snackbar']
          });
        }
      });
    }
  }
  upload(event:Event,item: InputTemplateData,i:number){
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files) {
      const uploadPhoto = inputElement.files[0];
      let formData = new FormData();
      formData.append('file', uploadPhoto);
      this.restApiService.uploadFile(formData, this.selectedActiveId ?? "",item._id ?? "", this.templateSelectedId?._id ?? "").subscribe({
        next: (response) => {
          if (response.success) {
            let newPhoto:PhotoSaved = {
              _id:response.uploadFile._id,
              reference:response.uploadFile.reference,
              createdAt:response.uploadFile.createdAt,
              fileName:response.uploadFile.fileName
            }
            if (this.inputTemplateData) {
              const index = this.inputTemplateData?.findIndex(data => data.type === "Photos" && data._id === item._id);
              if (index !== -1) {
                const updatedPhotos = [...this.inputTemplateData[i].photos ?? []];
                updatedPhotos.push(newPhoto)
                this.inputTemplateData[i].photos = updatedPhotos
                this.inputTemplateData[i].dataSourcePhotoFilesDetails = new MatTableDataSource<any>(this.inputTemplateData[i].photos)
              }
            }
            this.snackBar.open('Se subio la imagen!', '', {
              duration: 3000, verticalPosition: 'bottom',
              panelClass: 'notif-success'
            });
          } else {
            this.snackBar.open('Error intentarlo de nuevo', '', {
              duration: 3000, verticalPosition: 'bottom',
              panelClass: 'notif-success'
            });
          }
        },
        error: (error) => {
          this.snackBar.open('Error intentarlo de nuevo', '', {
            duration: 3000, verticalPosition: 'bottom',
            panelClass: ['blue-snackbar']
          });
        }
      });
    }
  }

  didTapOpenPhoto(item: PhotoSaved) {
    const confirmRef = this.dialog.open(ImgDialogComponent, {
      data: {
        lng: 26,
        lat: 26,
        url: item.reference,
        confirm: 'Descargar',
        confirmClass: 'danger-button',
        cancel: 'Cerrar'
      }
    });
    confirmRef.afterClosed().subscribe(result => {});
  }
  didTapDeletePhoto(item: PhotoSaved,i: number) {
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirmar',
        body: '¿Está seguro de eliminar foto: ' + this.getNameFromReference(item.reference) + ' ?',
        confirm: 'Borrar',
        confirmClass: 'danger-button',
        cancel: 'Cancelar'
      }
    });
    confirmRef.afterClosed().subscribe(result => {
      if (result) {
        this.restApiService.deleteImage(this.selectedActiveId ?? "", item._id || 0).subscribe({
          next: (response) => {
            if (response.success) {
              if (this.inputTemplateData) {
                const index: number = this.inputTemplateData[i].photos?.findIndex(d => d === item) ?? -1
                if (index !== -1) {
                  const updatedPhotos = [...this.inputTemplateData[i].photos ?? []];
                  updatedPhotos.splice(index, 1);
                  this.inputTemplateData[i].photos = updatedPhotos;
                  this.inputTemplateData[i].dataSourcePhotoFilesDetails = new MatTableDataSource<any>(updatedPhotos);
                }
              }
              this.snackBar.open('Se eliminó imagen: ' + this.getNameFromReference(item.reference), '', {
                duration: 3000, verticalPosition: 'bottom',
                panelClass: 'notif-success'
              });
            } else {
              this.snackBar.open('Error intentarlo de nuevo', '', {
                duration: 3000, verticalPosition: 'bottom',
                panelClass: 'notif-success'
              });
            }
          },
          error: (error) => {
            this.snackBar.open('Error intentarlo de nuevo', '', {
              duration: 3000, verticalPosition: 'bottom',
              panelClass: ['blue-snackbar']
            });
          }
        });
      }
    });
  }
  didTapOpenFile(item: FileSaved) {
    const confirmRef = this.dialog.open(ViewerFileDialogComponent, {
      data: {
        title: "Documento: "+item.fileName,
        url:item.reference,
        confirm: 'OK',
        confirmClass: 'danger-button',
        cancel: 'Cancelar'
      }
    });
    confirmRef.afterClosed().subscribe(result => {});
  }
  getNameFromReference(url:any){
    if (url ){
      const fileName = url.split('/').pop();
      if(fileName){
        const [name, extension] = fileName.split('.');
        return `${name}.${extension}`
      } else {
        return url
      }
    }else {
      return "Sin información"
    }
  }
  assertItemTypeFileDelete(item: FileSaved, i:number) {
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirmar',
        body: '¿Está seguro de eliminar foto: ' + this.getNameFromReference(item.reference) + ' ?',
        confirm: 'Borrar',
        confirmClass: 'danger-button',
        cancel: 'Cancelar'
      }
    });
    confirmRef.afterClosed().subscribe(result => {
      if (result) {
        this.restApiService.deleteFile(this.selectedActiveId ?? "", item._id || 0).subscribe({
          next: (response) => {
            if (response.success) {
              if (this.inputTemplateData) {
                const index: number = this.inputTemplateData[i].files?.findIndex(d => d === item) ?? -1
                this.inputTemplateData[i].files?.splice(index, 1);
                this.inputTemplateData[i].dataSourcePhotoFilesDetails =  new MatTableDataSource<any>(this.inputTemplateData[index].files)
              }
              this.snackBar.open('Se eliminó archivo: ' + this.getNameFromReference(item.reference), '', {
                duration: 3000, verticalPosition: 'bottom',
                panelClass: 'notif-success'
              });
            } else {
              this.snackBar.open('Error intentarlo de nuevo', '', {
                duration: 3000, verticalPosition: 'bottom',
                panelClass: 'notif-success'
              });
            }
          },
          error: (error) => {
            this.snackBar.open('Error intentarlo de nuevo', '', {
              duration: 3000, verticalPosition: 'bottom',
              panelClass: ['blue-snackbar']
            });
          }
        });
      }
    });
  }
  assertItemTypePhotoSaved(item: PhotoSaved): PhotoSaved {
    return item;
  }
  assertItemTypeFileSaved(item: FileSaved): FileSaved {
    return item;
  }
  onTextChange(event: any, item:InputTemplateData) {
    const newInputTemplateDataInfo = new InputTemplateDataInfo()
    newInputTemplateDataInfo.template = this.inputTemplate?._id
    newInputTemplateDataInfo.input = item._id
    newInputTemplateDataInfo.value = event.target.value
    this.inputTemplateDataInfo?.push(newInputTemplateDataInfo)
  }
  onChangeCheckBox(ob: MatCheckboxChange,item:InputTemplateData) {
    const newInputTemplateDataInfo = new InputTemplateDataInfo()
    newInputTemplateDataInfo.template = this.inputTemplate?._id
    newInputTemplateDataInfo.input = item._id
    newInputTemplateDataInfo.value = ob.checked
    this.inputTemplateDataInfo?.push(newInputTemplateDataInfo)
  }
  onChangeSelector(event: any, item:InputTemplateData): void {
    const newInputTemplateDataInfo = new InputTemplateDataInfo()
    newInputTemplateDataInfo.template = this.inputTemplate?._id
    newInputTemplateDataInfo.input = item._id
    newInputTemplateDataInfo.value = event.value
    this.inputTemplateDataInfo?.push(newInputTemplateDataInfo)
  }

  protected readonly String = String;
}
