<div class="grid nested-grid">
    <div class="col-12">
        <div class="flex flex-column sm:flex-row align-items-center gap-4">
            <div class="flex flex-column sm:flex-row align-items-center gap-3">
                <img *ngIf="items?.result?.is_active === 'on'" src="../../../assets/icons/LIND_icon_asset.svg" class="w-4rem h-4rem flex-shrink-0">
                <img *ngIf="items?.result?.is_active === 'off'" src="../../../assets/icons/LIND_icon_zone.svg" class="w-4rem h-4rem flex-shrink-0">
                <div class="flex flex-column align-items-center sm:align-items-start">
                    <span class="text-900 font-bold text-4xl">{{items?.result?.name}}</span>
                    <p class="text-600 m-0">{{items?.result?.description}}</p>
                </div>
            </div>
            <div *ngIf="items?.result?.is_active === 'on'" class="flex gap-3 sm:ml-auto">
                <p-button pTooltip="Visualización 3D" tooltipPosition="bottom" icon="pi pi-box" rounded="true" outlined="true" [disabled]="!items?.result?.hasBIM" (onClick)="openBIM()"></p-button>
                <p-button pTooltip="Visión Artificial" tooltipPosition="bottom" icon="pi pi-eye" rounded="true" outlined="true" [disabled]="!items?.result?.hasVA" (onClick)="openVA()"></p-button>
                <p-button pTooltip="Monitoreo Directo" tooltipPosition="bottom" icon="pi pi-chart-bar" rounded="true" outlined="true" [disabled]="!items?.result?.hasMD" (onClick)="openMD()"></p-button>
                <p-button pTooltip="Monitoreo Indirecto" tooltipPosition="bottom" icon="pi pi-map-marker" rounded="true" outlined="true" [disabled]="!items?.result?.hasMI" (onClick)="openMI()"></p-button>
            </div>
        </div>
    </div>
</div>
<p-fieldset *ngIf="!fromMap" [toggleable]="true" class="mb-2"> 
    <ng-template pTemplate="header">
        <div class="flex align-items-center gap-2 px-2">
            <span class="font-bold">Ubicación</span>
        </div>
    </ng-template>
    <div class="grid nested-grid">
        <div class="col-10">
            <div class="border border-round-sm " id="mapAsset"></div>
        </div>
        <div class="col">
            <div class="grid">
                <div class="col-12">
                    <div class="surface-50 font-bold px-3 py-1 border-round">
                        <p class="font-semibold">Latitud</p>
                        <p class="font-normal">{{items?.result?.latitude}}</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="surface-50 font-bold px-3 py-1 border-round">
                        <p class="font-semibold">Longitud</p>
                        <p class="font-normal">{{items?.result?.longitude}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-fieldset>

<p-fieldset *ngIf="items?.resultInput" [toggleable]="true"> 
    <ng-template pTemplate="header">
        <div class="flex align-items-center gap-2 px-2">
            <span class="font-bold">Información Plantilla {{ items?.result?.template?.name }}</span>
        </div>
    </ng-template>
    <div class="flex flex-row flex-wrap gap-3 align-items-start justify-content-start">
        <ng-container *ngFor="let input of items?.resultInput">
            <ng-container [ngSwitch]="input.type">
                <div *ngSwitchCase="'Text'" class="flex-auto align-items-center justify-content-center surface-50 font-bold px-3 py-1 border-round" style="min-width: 15vw;">
                    <p class="font-semibold">{{input.name}}</p>
                    <p class="font-normal">{{input.inputData?.value ? input.inputData?.value : "Sin Información" }}</p>
                </div>
                <div *ngSwitchCase="'Number'" class="flex-auto  align-items-center justify-content-center surface-50 font-bold px-3 py-1 border-round" style="min-width: 10vw;">
                    <p class="font-semibold">{{input.name}}</p>
                    <p class="font-normal">{{input.inputData?.value ? input.inputData?.value : "Sin Información" }}</p>
                </div>
                <div *ngSwitchCase="'Checkbox'" class="flex-auto flex-column align-items-center justify-content-center surface-50 font-bold px-3 border-round" style="min-width: 10vw;">
                    <p class="font-semibold">{{input.name}}</p>
                    <p *ngIf="input.inputData?.value" class="pi pi-check-circle font-bold"></p>
                    <p *ngIf="!input.inputData?.value" class="pi pi-times-circle font-bold"></p>
                </div>
                <div *ngSwitchCase="'Selector'" class="flex-auto align-items-center justify-content-center surface-50 font-bold px-3 py-1 border-round" style="min-width: 15vw;">
                    <p class="font-semibold">{{input.name}}</p>
                    <p class="font-normal ">{{input.inputData?.value ? input.inputData?.value : "Sin Información" }}</p>
                </div>
                <div *ngSwitchCase="'Date'" class="flex-auto align-items-center justify-content-center surface-50 font-bold px-3 py-1 border-round" style="min-width: 15vw;">
                    {{ input.inputData?.value }}
                </div>
                <div *ngSwitchCase="'Time'" class="flex-auto flex align-items-center justify-content-center surface-50 font-bold px-3 py-1 border-round" style="min-width: 15vw;">
                    {{ input.inputData?.value }}
                </div>
                <div *ngSwitchCase="'Files'" class="flex-order-6 flex-auto align-items-center justify-content-center surface-50 font-bold px-3 py-1 border-round">
                    <p class="font-semibold">{{input.name}}</p>
                    <p class="font-normal ">{{input.inputData?.value ? input.inputData?.value : "Sin Información" }}</p>
                </div>
                <div *ngSwitchCase="'Photos'" class="flex-order-5 flex-auto align-items-center justify-content-center surface-50 font-bold px-3 py-1 border-round" style="min-width: 40vw;">
                    <p class="font-semibold">{{input.name}}</p>
                    <p-galleria 
                    [value]="input.photos" 
                    [containerStyle]="{ 'max-width': '100vw' }" 
                    [showIndicators]="true" 
                    [showThumbnails]="false" 
                    [showItemNavigators]="true"
                    [circular]="true"
                    [numVisible]="5">
                        <ng-template pTemplate="item" let-item>
                            <p-image src="{{ item.reference }}" alt="{{ item.fileName }}" width="600" [preview]="true" previewImageSizes="max-width: 600px" ></p-image>
                            <ng-template pTemplate="indicator">
                                <i class="pi pi-zoom"></i>
                            </ng-template>
                        </ng-template>
                </p-galleria>
                </div>
                
                <p *ngSwitchDefault>
                    NADA
                </p>
            </ng-container>
        </ng-container>
    </div>
</p-fieldset>
