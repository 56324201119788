
<!DOCTYPE html>
<html>

<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/css/bootstrap.min.css">
    <script src="//ajax.googleapis.com/ajax/libs/jquery/1.12.0/jquery.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/js/bootstrap.min.js"></script>

</head>
    <body>
        <ngx-loading-bar></ngx-loading-bar>
        <div fxFlex="95"fxLayout="column" style="margin: 5px;font-family: Roboto, sans-serif!important;font-size:20px !important;">
          <h1  class ="logo  title m-3" fxFlex="none"> Lista de compañias 📌</h1>
          <div class="centered-div" *ngxPermissionsOnly="['MASTER', 'ADMIN']">
            <button mat-button (click)="onSelectCreateCompany()"  mat-flat-button class="mat-button-register-user">
              <mat-icon inline=true>add</mat-icon>
              Crear empresa
            </button>
          </div>
          <table mat-table #dataTable  [dataSource]="dataSource" class="mat-elevation-z0">
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef> #id </th>
              <td mat-cell *matCellDef="let user"> {{assertItemType(user).id}} </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Nombre </th>
              <td mat-cell *matCellDef="let user"> {{assertItemType(user).name}} </td>
            </ng-container>
            <ng-container matColumnDef="descripcion">
              <th mat-header-cell *matHeaderCellDef> Descripción </th>
              <td mat-cell *matCellDef="let user"> {{assertItemType(user).description}} </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef > Acciones </th>
              <mat-cell *matCellDef="let user" >
                <div *ngxPermissionsOnly="['MASTER', 'ADMIN']">
                  <button mat-button (click)="onSelectEditCompany(user)">
                    <mat-icon class = "svg" svgIcon="pencil-outline"></mat-icon>
                    Editar
                  </button>
                </div>
                <div *ngxPermissionsOnly="['MASTER']">
                  <button mat-button  (click)="onDelete(user)">
                    <mat-icon class = "svg" svgIcon="delete-outline"></mat-icon>
                    Borrar
                  </button>
                </div>
              </mat-cell>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
          </table>
            <div class="m-2" style="font-size: 15px" *ngIf="items.length === 0">Sin Compañias ⛔</div>
        </div>
    </body>

</html>


