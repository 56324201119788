import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Icon, icon, latLng, Map } from 'leaflet';

import { DetailsUnit } from '../../unit/detail-unit/model/detailsUnit';
import { Units } from '../../unit/units/model/units';
import * as L from 'leaflet';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ViewAssetDialogComponent } from 'src/app/_dialog/view-asset-dialog/view-asset-dialog.component';

@Component({
  selector: 'app-info-monitor-map',
  templateUrl: './info-monitor-map.component.html',
  styleUrl: './info-monitor-map.component.css',
  providers: [DialogService],
})
export class InfoMonitorMapComponent implements OnChanges {
  init?: Map;
  @Input() items: DetailsUnit[] = [];
  @Input() infoUnit?: Units;
  ref: DynamicDialogRef | undefined;

  streetMaps = L.tileLayer(
    'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    {
      detectRetina: true,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }
  );
  arcgisMaps = L.tileLayer(
    'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    {
      detectRetina: true,
      attribution:
        '&copy; <a href="http://www.esri.com/">Esri</a>, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community ',
    }
  );

  layersControl = {
    baseLayers: {
      'ArcGIS Map': this.arcgisMaps,
      'Street Map': this.streetMaps,
    },
    overlays: {},
  };

  options = {
    layers: [this.arcgisMaps],
    zoom: 5,
    popupAnchor: [13, 0],
    center: latLng(-35.675147, -71.542969),
  };

  constructor(public dialogService: DialogService) {}

  onMapReady(map: L.Map) {
    this.init = map;
  }

  refreshMap() {
    if (this.init) {
      this.init.invalidateSize();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.addUnitBox();
      this.addMarkers();
    }, 100);
  }

  addUnitBox() {
    if (this.infoUnit) {
      let textbox = L.Control.extend({
        unitName: this.infoUnit.name,
        options: {
          position: 'bottomleft',
        },
        onAdd: function () {
          let div = L.DomUtil.create('div');
          div.innerHTML = `<div class="flex-auto align-items-center shadow-2 opacity-80 justify-content-center bg-primary-reverse text-xl px-4 py-1 border-round" >
          <p class="font-semibold">${this.unitName} </p></div>`;
          return div;
        },
      });
      this.init?.addControl(new textbox());
    }
  }

  createPopupContent(infoUnit: DetailsUnit): HTMLElement {
    const div = document.createElement('div');
    div.className = 'flex flex-column align-items-center justify-content-center bg-primary-reverse text-lg px-1 border-round';

    const nameLabel = document.createElement('span');
    nameLabel.className = 'flex-grow-1 text-bold my-1';
    nameLabel.textContent = infoUnit.name ?? '';
    div.appendChild(nameLabel);

    const button = document.createElement('button');
    button.className = 'p-button p-component p-button-primary p-button-rounded p-button-outlined p-button-sm';
    button.innerHTML = '<i class="pi pi-search mr-2" style="font-size: 1rem"></i> Ver detalles ';
    button.onclick = () => this.showDynamicElement(infoUnit._id ?? '', infoUnit.is_active ?? 'off');
    div.appendChild(button);

    return div;
  }

  addMarkers() {
    const mcg = L.markerClusterGroup({});
    for (const item of this.items) {
      let marker: L.Marker = L.marker(
        [item.latitude ?? -35.675147, item.longitude ?? -71.542969],
        {
          icon: icon({
            ...Icon.Default.prototype.options,
            iconUrl: 'assets/leaflet/marker-icon.png',
            iconRetinaUrl: 'assets/leaflet/marker-icon-2x.png',
            shadowUrl: 'assets/leaflet/marker-shadow.png',
          }),
        }
      );

      const popupContent = this.createPopupContent(item);
      marker.bindPopup(popupContent);
      mcg.addLayer(marker);
    }
    this.init?.addLayer(mcg);
    this.init?.fitBounds(mcg.getBounds(), { maxZoom: 14 });
    this.refreshMap();
  }

  showDynamicElement(assetID: string, is_active: string) {
    const headerDialog = 'Detalle de '.concat(
      is_active === 'on' ? 'Activo' : 'Zona'
    );

    this.ref = this.dialogService.open(ViewAssetDialogComponent, {
      header: headerDialog,
      width: '90vw',
      height: '90vh',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      modal: true,
      data: {
        assetID: assetID,
        fromMap: true,
      },
    });
  }
}
