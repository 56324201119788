<ngx-loading-bar></ngx-loading-bar>

<div fxFlex="95" fxLayout="column" style="margin: 5px;font-family: Roboto, sans-serif!important;font-size:20px !important;">
    <div fxLayout="row" fxLayoutAlign="space-between">
        <div>
            <h3 class="m-3">Detalle de la Plantilla 📄</h3>
        </div>
        <div fxLayout="row" class="px-4">
            <mat-icon class="svg-option " svgIcon="camera-outline" [hidden]="checkEnableVA()" (click)="didTapOpenMD()"></mat-icon>
            <mat-icon class="svg-option" svgIcon="cube-outline" [hidden]="checkEnableBim()" (click)="didTapOpenBim()"></mat-icon>
            <mat-icon class="svg-option" svgIcon="monitor" [hidden]="checkEnableMI()" (click)="didTapOpenMI()" ></mat-icon>
            <mat-icon class="svg-option" svgIcon="monitor-dashboard" [hidden]="checkEnableMD()" (click)="didTapOpenMD()"></mat-icon>
        </div>
    </div>
    <div class ="m-3 mb-3">
        <form class="form-group" [formGroup]="formGroup">
            <mat-form-field class="w-50">
                <mat-label >Nombre</mat-label>
                <input matInput id="activo"  formControlName="activo"  [disabled]="isDisabledEdit" [readonly]="isDisabledEdit">
            </mat-form-field>
            <div id="mapa-creacion">
                <h4  class ="m-3" > Mapa y ubicación 🗺️</h4>
                <div style="height: 600px;"
                     id="map"
                     leaflet
                     [leafletOptions]="options"
                     [leafletLayers]="layers"
                     (leafletMapReady)="onMapReady($event)">
                </div>

            </div>

            <div fxLayout="column" class="mt-4">
                <div fxLayout = "row" fxFlex="100">
                    <mat-label class="m-3 elegant-label">
                        <span class="icon-label">
                          <mat-icon class="svg" svgIcon="map-marker-outline"></mat-icon>
                          Longitud
                        </span>
                    </mat-label>

                    <mat-form-field appearance="fill" style="width: 100%">
                        <input matInput id="longitud" formControlName="longitud"  [disabled]="isDisabledEdit" [readonly]="isDisabledEdit">
                    </mat-form-field>

                    <mat-label class="m-3 elegant-label">
                    <span class="icon-label">
                      <mat-icon class="svg" svgIcon="map-marker-outline"></mat-icon>
                      Latitud
                    </span>
                    </mat-label>
                    <mat-form-field appearance="fill" style="width: 100%">
                        <input matInput id="latitud" formControlName="latitud"  [disabled]="isDisabledEdit" [readonly]="isDisabledEdit">
                    </mat-form-field>
                </div>
                </div>
                <mat-label class="elegant-label">
                    <span class="icon-label">
                      <mat-icon class="svg" svgIcon="text-short"></mat-icon>
                      Descripción
                    </span>
                </mat-label>
                <mat-form-field appearance="fill" style="width: 100%">
                    <input matInput id="descripcion" formControlName="descripcion" [disabled]="isDisabledEdit" [readonly]="isDisabledEdit">
                </mat-form-field>
                <div>

                        <div>
                            <div>
                                <mat-label class="elegant-label">
                                <span class="icon-label">
                                    <mat-icon class="svg" svgIcon="format-list-numbered"></mat-icon>
                                    Tipo de Activo
                                </span>
                                </mat-label><br>
                                <mat-form-field class="full-width m-2">
                                    <mat-label class="m-4">
                            <span class="icon-label">
                                Tipo
                            </span>
                                    </mat-label>
                                    <mat-select class="full-width" id="Tipo"  [(value)]="selectedTipe" [disabled]="isDisabledEdit">
                                        <mat-option *ngFor="let tipo of tipos" [value]="tipo._id">{{ tipo.name }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="m-1">
                            </div>
                        </div>
                <mat-label class="elegant-label">
                    <span class="icon-label">
                      <mat-icon class="svg" svgIcon="file-document-edit-outline"></mat-icon>
                      Plantilla
                    </span>
                </mat-label>
                <mat-form-field appearance="fill" style="width: 100%">
                    <input matInput  type="text" id="plantilla" formControlName="plantilla" [disabled]="true" [readonly]="true">
                </mat-form-field>
                <br>
            </div>
        </form>


        <div *ngFor="let campo of inputTemplateData ; let i = index">

                <div *ngIf="campo.type === 'Selector'" class="w-50">
                    <mat-form-field class="m-2" style="width: 100%">
                        <mat-label>{{campo.name}}</mat-label>
                        <mat-select [ngModel]="valueDefineToSelector(campo)" [disabled]="isDisabledEdit" (selectionChange)="onChangeSelector($event,campo)">
                            <mat-option *ngFor="let option of createOptionSelect(campo)" [value]="option">
                                {{ option }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            <div  fxLayout="row" >
                <div *ngIf="campo.type === 'Checkbox'">
                    <div class="m-2" >{{campo.name}}</div>
                    <mat-checkbox color = "primary" [checked]="campo.inputData?.value" [disabled]="isDisabledEdit" (change)="onChangeCheckBox($event,campo)">{{campo.name}}</mat-checkbox>
                </div>
                <div *ngIf="campo.type === 'Number'" class="w-50">
                    <mat-form-field class="m-2" style="width: 100%">
                        <mat-label>{{campo.name}}</mat-label>
                        <input matInput type="number"  class="form-control" [value]="campo.inputData?.value" name="name" required [disabled]="isDisabledEdit" [readonly]="isDisabledEdit" (change)="onTextChange($event,campo)">
                    </mat-form-field>
                </div>
                <div *ngIf="campo.type === 'Text'" class="w-50">
                    <mat-form-field class="m-2" style="width: 100%">
                        <mat-label>{{campo.name}}</mat-label>
                        <input type="text" matInput class="form-control"  name="name" [value]="campo.inputData?.value" required [disabled]="isDisabledEdit" [readonly]="isDisabledEdit" (change)="onTextChange($event,campo)">
                    </mat-form-field>
                </div>
            </div>

            <div *ngIf="campo.type === 'Photos'">
                <div class="gray-line"></div>
                <div  fxLayout="column" >
                    <h4  class ="m-2  title m-t-1 "  fxFlex="none"> {{ campo.name?.toUpperCase() }} 📷</h4>
                    <div (click)="uploadFile.click()" class="m-1" >
                        <button mat-raised-button style="width: 250px;  height:50px; border-radius: 15px; margin-left: 10px;background-color: #2196F3; color:white">
                            <mat-icon class = "svg" svgIcon="upload-outline"></mat-icon>
                            Subir imagen  📷</button>
                        <input #uploadFile (change)="upload($event,campo,i)" type='file' accept="image/*" style="display:none"/>
                    </div>
                </div>
                <div fxLayout="column" >
              <ng-container *ngIf="inputTemplateData && inputTemplateData[i]">
                  <app-photo-gallery [galleryId]= String(i) [photos]="inputTemplateData[i].dataSourcePhotoFilesDetails.data"/>

                <table mat-table [dataSource]="inputTemplateData[i].dataSourcePhotoFilesDetails" class="mat-elevation-z0" >
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>Nombre</th>
                                <td mat-cell *matCellDef="let user">{{ assertItemTypePhotoSaved(user).fileName }}</td>
                            </ng-container>
                            <ng-container matColumnDef="createdAt">
                                <th mat-header-cell *matHeaderCellDef>Fecha</th>
                                <td mat-cell *matCellDef="let user">{{ assertItemTypePhotoSaved(user).createdAt | date:"medium" }}</td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>{{ 'Acciones' }}</th>
                                <mat-cell mat-cell *matCellDef="let user">
                                    <div>
                                        <button mat-button (click)="didTapOpenPhoto(user)">
                                            <mat-icon class="svg" svgIcon="comment-edit-outline"></mat-icon>
                                            Ver
                                        </button>
                                    </div>
                                    <div>
                                        <button mat-button (click)="didTapDeletePhoto(user,i)">
                                            <mat-icon class="svg" svgIcon="delete-outline"></mat-icon>
                                            Borrar
                                        </button>
                                    </div>
                                </mat-cell>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </ng-container>
                    <div class="m-2" style="font-size: 15px" *ngIf="campo.photos?.length === 0">Sin Fotos ⛔</div>
                </div>
            </div>

            <div *ngIf="campo.type === 'Files'">
                <div  fxLayout="column" >
                <h4  class ="m-2  title m-t-3 " fxFlex="none"> {{ campo.name?.toUpperCase() }} 🗂️</h4>
                <div fxFlex="100%" style="margin-left: 10px;">
                    <div (click)="uploadFile.click()" class="m-2">
                        <button mat-raised-button style="width: 250px;  height:50px; border-radius: 15px;background-color: #2196F3; color:white">
                            <mat-icon class = "svg" svgIcon="file-upload-outline"></mat-icon>
                            Subir Archivo 📃</button>
                        <input #uploadFile (change)="uploadOnlyFile($event,campo,i)" type='file' accept=".doc,.docx,.pdf,.xls,.xlsx" style="display:none"/>
                    </div>
                </div>
                    <ng-container *ngIf="inputTemplateData && inputTemplateData[i]">

                    <table mat-table [dataSource]="inputTemplateData[i].dataSourcePhotoFilesDetails" class="mat-elevation-z0">
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Nombre </th>
                            <td mat-cell *matCellDef="let user"> {{assertItemTypeFileSaved(user).fileName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="createdAt">
                            <th mat-header-cell *matHeaderCellDef> Fecha </th>
                            <td mat-cell *matCellDef="let user"> {{assertItemTypeFileSaved(user).createdAt | date:"medium"}} </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef > Acciones </th>
                            <mat-cell *matCellDef="let user" >
                                <div>
                                    <button mat-button  (click)="didTapOpenFile(user)">
                                        <mat-icon class = "svg" svgIcon="comment-edit-outline"></mat-icon>
                                        Ver
                                    </button>
                                </div>
                                <div>
                                    <button mat-button  (click)="assertItemTypeFileDelete(user,i)">
                                        <mat-icon class = "svg" svgIcon="delete-outline"></mat-icon>
                                        Borrar
                                    </button>
                                </div>

                            </mat-cell>
                            <td mat-footer-cell *matFooterCellDef>   </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    </ng-container>
                   <div class="m-2" style="font-size: 15px" *ngIf="campo.files?.length === 0">Sin Archivos ⛔</div>
                </div>
            </div>

        </div>
    </div>
    <div class="mt-4 m-4">
        <button  style="margin-bottom: 50px; width: 250px;  height:50px; border-radius: 15px; margin-left: 10px;background-color: #008f39; color:white" mat-raised-button class="btn boton" id="añadir_planilla" (click)="didTapSaveChanged()" >
            {{textButtonEdit() }}</button>
    </div>
</div>
