<!DOCTYPE html>
<html>
<head>
  <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap" rel="stylesheet">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/css/bootstrap.min.css">
</head>
<body>
<div #modalContainer class="modal-container" (click)="onClickOutside($event)"  fxLayout="column" [style.height.px]="dataSourceItemsDetails.data.length > 0 ? 550 : null">

  <div class="centered-div-right" *ngxPermissionsOnly="['ADMIN', 'MASTER']">
    <h4  class ="m-3  title m-t-3 " style="font-size: 30px" fxFlex="none"> {{data.title}}</h4>
    <mat-form-field class="filter-input" style="margin-left: auto;">
      <mat-label>Filtro</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ingrese texto">
    </mat-form-field>
      <button mat-raised-button (click)="didtapAddActiveType()" [disabled]="loading"  style="margin-top: 10px; margin-bottom: 20px; width: 250px;  height:50px; border-radius: 15px; margin-left: 10px;background-color: #2196F3; color:white">
          <span *ngIf="loading"  class="spinner-border spinner-border-sm mr-1"></span>
          Crear Activo
      </button>
  </div>
  <div  fxFlex="100%" style="margin-left: 10px;">
    <table mat-table #dataTableServicesDetails  [dataSource]="dataSourceItemsDetails" class="mat-elevation-z0">
      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let user"> {{assertItemTypeName(user)}} </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef > Acciones </th>
        <mat-cell *matCellDef="let user" >
          <div *ngxPermissionsOnly="['ADMIN', 'MASTER']"  >
            <button mat-button  (click)="didtapDeleteActiveType(user)">
              <mat-icon class = "svg" svgIcon="delete-outline"></mat-icon>
              Borrar
            </button>
          </div>
        </mat-cell>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, dataSourceItemsDetails.data.length]" showFirstLastButtons></mat-paginator>
  </div>
</div>

</body>
</html>

