<!DOCTYPE html>
<html>
<head>
  <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap" rel="stylesheet">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/css/bootstrap.min.css">
</head>
<body>
<div class="form-group m-3" style="width: 500px">
  <div fxLayout="column" fxFlex="90" fxLayoutAlign="center"  >
    <h3  class ="logo  title m-3" fxFlex="none"> Datos cliente</h3>
    <div fxLayout="column" fxFlex="100"  >
      <form class="form-group" [formGroup]="form">
        <div fxLayout="column" fxFlex="100" fxLayoutAlign="start"  >
          <div fxLayout="row" fxFlex="100" >
            <div class="m-2">
              <mat-form-field >
                <mat-label>Nombre</mat-label>
                <input  matInput  formControlName="fname" placeholder=""  name="fname"  required autocomplete="off">
              </mat-form-field>
            </div>
            <div class="m-2" >
              <mat-form-field >
                <mat-label>Apellido</mat-label>
                <input  matInput  formControlName="lname" placeholder="" name="lname"  required autocomplete="off">
              </mat-form-field>
            </div>
          </div>

        <div fxLayout="row" fxFlex="100" >
          <mat-form-field class="m-2" >
            <mat-label>Email</mat-label>
            <input  matInput  formControlName="email"  placeholder="" name="email" autocomplete="off">
          </mat-form-field>
        </div>

          <div fxLayout="row" fxFlex="100" >
            <mat-form-field  class="m-2" style="width: 400px;">
              <mat-label>tipo de Usuario</mat-label>
              <mat-select [(value)]="selectedTypeUser">
                <mat-option *ngFor="let brand of permissions" [value]="brand.id">
                  {{ brand.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
            <div fxLayout="row" fxFlex="100" >
                <mat-form-field  class="m-2" style="width: 400px;">
                    <mat-label>Empresa</mat-label>
                    <mat-select [(value)]="selectedCompanyId">
                        <mat-option *ngFor="let brand of companies" [value]="brand.id">
                            {{ brand.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>




          <div [hidden]="hiddenShowPassword">
          <mat-form-field class="m-2" >
            <mat-label>Contraseña</mat-label>
            <input  matInput  formControlName="password" name="password" type="text"  autocomplete="off">
          </mat-form-field>
          <mat-form-field class="m-2" >
            <mat-label>Repetir Contraseña</mat-label>
            <input  matInput  formControlName="repeat_password" name="repeat_password" type="text"  autocomplete="off">
          </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center" >
      <button mat-raised-button (click)=" editClient()" style="margin-bottom: 20px; background-color: #2196F3;height: 50px; border-radius: 25px;width: 175px;color: white;
    font-size: medium;" >
        <span *ngIf="loadingUpdate" class="spinner-border spinner-border-sm mr-1"></span>
        {{ textbuttonEditClient() }}
      </button>
    </div>
  </div>
</div>
</body>
</html>
