import {AfterViewInit,Component, OnInit} from '@angular/core';
import {circle, Icon, icon, latLng, Map, marker, tileLayer} from "leaflet";
import * as L from "leaflet";
import GeocoderControl, {geocoder} from "leaflet-control-geocoder";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {Type} from "./Type";
import {DataService} from "../../../_services/DataService";
import {RestApiService} from "../../../shared/rest-api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {AddItemDialogComponent} from "../../../_dialog/add-Item-dialog/add-Item-dialog.component";
import {
    SelectTemplateUnitDialogComponent
} from "../../../_dialog/select-template-unit-dialog/select-template-unit-dialog.component";
import {Units} from "../../unit/units/model/units";
import {ConfirmDialogComponent} from "../../../_dialog/confirm-dialog/confirm-dialog.component";
import {DetailsUnit} from "../../unit/detail-unit/model/detailsUnit";

@Component({
  selector: 'app-create-asset',
  templateUrl: './create-asset.component.html',
  styleUrl: './create-asset.component.css'
})
export class CreateAssetComponent implements OnInit, AfterViewInit  {
    public formGroup!: FormGroup;
    init?: L.Map;
    items: DetailsUnit[] = [];
    unidadId? :string
    infoUnit?:Units
    loading = false;
    selectedTipo?: string;
    selectedActiveId?: string;
  tipos: Type[] = []; // Define a property to store the options
  isCheckedControl = new FormControl(false);
  geocoder?: GeocoderControl;

  markers: L.Marker[] = [];
  streetMaps = L.tileLayer(
    'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    {
      detectRetina: true,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }
  );
  arcgisMaps = L.tileLayer(
    'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    {
      detectRetina: true,
      attribution:
        '&copy; <a href="http://www.esri.com/">Esri</a>, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community ',
    }
  );

  layersControl = {
    baseLayers: {
      'ArcGIS Map': this.arcgisMaps,
      'Street Map': this.streetMaps,
    },
    overlays: {},
  };

  options = {
    layers: [this.arcgisMaps],
    zoom: 5,
    popupAnchor: [13, 0],
    center: latLng(-35.675147, -71.542969),
  };
  
  constructor( public formBuilder: FormBuilder,
               private activatedRouter: ActivatedRoute,
               private snackBar: MatSnackBar,
               private dialog: MatDialog,
               private restApiService: RestApiService,
                private dataService: DataService) {
      this.activatedRouter.params.subscribe(params => {
        this.unidadId = params['id'];
        this.loadData(this.unidadId)
      });
  }

    loadData(unidadId: any){
     this.restApiService.getActiveUnit(unidadId).subscribe({
            next: (response) => {
                if (response.success) {
                    this.infoUnit = response.payload
                    this.selectedActiveId = this.infoUnit._id
                } else {
                    this.displayError()
                }
            },
            error: (error) => {
                this.displayError()
            }
        });
        this.restApiService.getDetailsActiveUnits(unidadId).subscribe({
            next: (response) => {
                if (response.success){
                    this.items = response.payload.listActiveTemplateEntity
                }else{
                    this.displayError()
                }
            },
            error: (error) => {
                this.displayError()
            }
        });
    }
  onMapReady(map: Map) {
    this.init = map
    setTimeout(() => {
      this.init?.invalidateSize();
    }, 0);
    map.on('click', (e)=>{this.onMapClick(e)});
    this.init.setView([-35.675147, -71.542969 ], 5);
    let markerTest =  marker([ -35.675147, -71.542969 ], {
      icon: icon({
        ...Icon.Default.prototype.options,
        iconUrl: 'assets/leaflet/marker-icon.png',
        iconRetinaUrl: 'assets/leaflet/marker-icon-2x.png',
        shadowUrl: 'assets/leaflet/marker-shadow.png'
      })
    })
    markerTest.bindPopup('Ubicación.<br> Definida.')
    markerTest.openPopup();

    this.markers.push(markerTest)
    // @ts-ignore
    this.layers.push(this.markers)
    this.geocoder = geocoder({
      defaultMarkGeocode: false,
      placeholder: "Buscar..."
    }).on('markgeocode', function(e) {
          const bbox = e.geocode.bbox;
          const center = bbox.getCenter();
          const radius = center.distanceTo(bbox.getSouthEast());
          const circle = L.circle(center, {radius: radius}).addTo(map);
          map.fitBounds(circle.getBounds());

    }).addTo(map);
  }
  onMapClick(e:L.LeafletMouseEvent) {
    this.markers.forEach((marker) => {
      marker.remove();
    });
    // Set the values for longitud and latitud fields
    this.formGroup.patchValue({
      longitud: e.latlng.lng,
      latitud: e.latlng.lat
    });
    let markerTest =  marker([ e.latlng.lat, e.latlng.lng], {
      icon: icon({
        ...Icon.Default.prototype.options,
        iconUrl: 'assets/leaflet/marker-icon.png',
        iconRetinaUrl: 'assets/leaflet/marker-icon-2x.png',
        shadowUrl: 'assets/leaflet/marker-shadow.png'
      })
    })
    // @ts-ignore
    const newMarker = markerTest.addTo(this.init);
    this.markers = [newMarker];
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init?.invalidateSize();
    }, 0);
  }
  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      longitud: ["", Validators.required],
      latitud: ["", Validators.required],
      descripcion: ["", Validators.required],
        nombre_activo: ["", Validators.required]
    });
    this.fetchTipos()
  }
  fetchTipos() {
      this.restApiService.getAllActiveType().subscribe({
          next: (response) => {
              if (response.success){
                  this.tipos = response.payload
              }else{
                  this.displayError()
              }
          },
          error: (error) => {
              this.displayError()
          }
      });
  }
    didTapCreate(){
        const longitud: string = this.formGroup?.get('longitud')?.value ?? ""
        const latitud: string = this.formGroup?.get('latitud')?.value ?? ""
        const nombre_activo: string = this.formGroup?.get('nombre_activo')?.value ?? ""
         if(nombre_activo.length ===0){
            this.snackBar.open('Definir Nombre activo', '', {
                duration: 3000, verticalPosition: 'top',
                panelClass: 'notif-success'
            });
        }else if(longitud.length===0){
            this.snackBar.open('Definir longitud', '', {
                duration: 3000, verticalPosition: 'top',
                panelClass: 'notif-success'
            });
        } else if(latitud.length===0){
            this.snackBar.open('Definir latitud', '', {
                duration: 3000, verticalPosition: 'top',
                panelClass: 'notif-success'
            });
        } else if(this.infoUnit === undefined){
            this.snackBar.open('Necesita regargar la pagina', '', {
                duration: 3000, verticalPosition: 'top',
                panelClass: 'notif-success'
            });
        }else if(this.unidadId === undefined){
            this.snackBar.open('Unidad no definida', '', {
                duration: 3000, verticalPosition: 'top',
                panelClass: 'notif-success'
            });
        }else {

             if(this.isCheckedControl.value) {
                 if (this.selectedTipo === undefined) {
                     this.snackBar.open('Seleccione un tipo de plantilla', '', {
                         duration: 3000, verticalPosition: 'top',
                         panelClass: 'notif-success'
                     });
                 } else {
                     this.dataService.changeMessage(this.formGroup);
                     const confirmRef = this.dialog.open(SelectTemplateUnitDialogComponent, {
                         data: {
                             title: 'Tipo de Activos',
                             cancel: 'Cancelar',
                             unidadId: this.unidadId,
                             typeId: this.selectedTipo,
                             selectedActiveId: this.selectedActiveId,
                             enterpriseId: this.infoUnit?.company._id
                         }
                     });
                     confirmRef.afterClosed().subscribe(result => {
                         if(result !== undefined){
                             this.snackBar.open('Se guardo nuevo activo', '', {
                                 duration: 3000, verticalPosition: 'top',
                                 panelClass: 'notif-success'
                             });
                         } else {
                             this.displayError()
                         }
                     });
                 }
             } else {
                 const confirmCreateRef = this.dialog.open(ConfirmDialogComponent, {
                     data: {
                         title: 'Confirmar',
                         body: '¿Desea crear activo ' + nombre_activo + ' ?',
                         confirm: 'Crear',
                         confirmClass: 'danger-button',
                         cancel: 'Cancelar'
                     }
                 });
                 confirmCreateRef.afterClosed().subscribe(result => {
                     if (result) {
                         let nombre = this.formGroup?.value['nombre_activo']
                         let es_activo =  "off"
                         let hasBIM = false
                         let hasMD = false
                         let hasMI = false
                         let hasVA = false
                         let tipo_de_activo = this.selectedTipo
                         let descripcion = this.formGroup?.value['descripcion']
                         let empresa = this.infoUnit?.company._id
                         let URLMD = "https://md.lind.global"
                         let unidad = this.unidadId
                         let latitud = Number(this.formGroup?.value['latitud'])
                         let longitud = Number(this.formGroup?.value['longitud'])

                         this.restApiService.createTemplateActive(nombre, es_activo,   hasBIM,
                             hasMD, hasMI, hasVA, tipo_de_activo, descripcion, empresa, URLMD,
                             unidad, latitud, longitud,this.selectedActiveId, [], []).subscribe({
                             next: (response) => {
                                 if (response.success){
                                     this.snackBar.open('Se creado el activo: '+nombre_activo, '', {
                                         duration: 3000, verticalPosition: 'bottom',
                                         panelClass: 'notif-success'
                                     });
                                 }else{
                                     this.displayError()
                                 }
                             },
                             error: (error) => {
                                 this.displayError()
                             }
                         });
                     }
                 });
             }
        }
  }

  didtapShowActiveType(){
      const confirmRef = this.dialog.open(AddItemDialogComponent, {
          data: {
              title: 'Tipo de Activos',
              cancel: 'Cancelar',
              itemtoAdd: 'active_type',
              urlSearch: '/active-type/all',
              urlAdd:'/active-type/create',
              urlDelete: '/active-type/delete'
          }
      });
      confirmRef.afterClosed().subscribe(result => {
          if(result === undefined){
              this.fetchTipos()
          } else {
              this.fetchTipos()
              this.snackBar.open('Se agrego activo: '+result.name, '', {
                  duration: 3000, verticalPosition: 'bottom',
                  panelClass: 'notif-success'
              });
          }
      });
  }
    displayError(){
        this.snackBar.open('Error intentarlo de nuevo', '', {
            duration: 3000, verticalPosition: 'bottom',
            panelClass: ['blue-snackbar']
        });
    }
}
