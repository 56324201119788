import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Login} from "../Views/login/models/login";
import {environment} from "../../environments/environment";
import * as CryptoJS from 'crypto-js';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request and add the token to the headers
    const token = localStorage.getItem('access_token');
    let strCurrentUser = localStorage.getItem('currentUser');
    if(strCurrentUser){
      const currentUser: Login = JSON.parse(strCurrentUser ?? "");
      let userId: string  = currentUser.payload.id?.toString() ?? "-1"
      const modifiedRequest = request.clone({
        setHeaders: {
          'x-user-id': this.encrypt(userId),
          Authorization: `Bearer ${token}`
        }
      });
      return next.handle(modifiedRequest);
    }else {
      const modifiedRequest = request.clone({
      });
      return next.handle(modifiedRequest);
    }
  }
  public encrypt(password: string): string {
    return CryptoJS.AES.encrypt(password, environment.keyAES).toString();
  }
}
