import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PhotoSaved} from "../../Views/show/show-template-active/entities/Photo";

@Component({
  selector: 'app-root-agm-slider-dialog',
  templateUrl: './img-slider-dialog.component.html',
  styleUrls: ['./img-slider-dialog.component.scss']
})
export class ImgSliderDialogComponent implements OnInit {
  items: PhotoSaved[] = []
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit() {
    this.items = this.data.photos
  }
}
