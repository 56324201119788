import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {inject, Injectable} from "@angular/core";
@Injectable({
  providedIn: 'root'
})
class PermissionsService {
  constructor( private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      return true;
    }
    this.router.navigate(['/logear'], { queryParams: {} });
    return false;
  }
}
export const authGuardGuard: CanActivateFn = (route, state) => {
  return inject(PermissionsService).canActivate(route, state);
};
