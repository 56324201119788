import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import * as OBC from '@thatopen/components';
import * as BUI from '@thatopen/ui';
import * as CUI from '@thatopen/ui-obc';
import { MessageService } from 'primeng/api';
import { RestApiService } from 'src/app/shared/rest-api.service';
import Stats from 'stats.js';

@Component({
  selector: 'app-bim-loader',
  templateUrl: './bim-loader.component.html',
  styleUrl: './bim-loader.component.css',
  providers: [MessageService],
})
export class BimLoaderComponent
  extends OBC.Components
  implements OnInit, OnDestroy
{
  @ViewChild('bimContainer', { static: true }) bimContainer!: ElementRef;
  assets: any[] = [];
  selectedAsset: any = null;
  modelToBeCharged: any = null;

  constructor(
    private renderer: Renderer2,
    private restApiService: RestApiService,
    private messageService: MessageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createIFCLoader();
    this.loadAssets();
  }

  ngOnDestroy(): void {
    this.dispose();
  }

  async loadAssets() {
    this.restApiService.getAssetsLight().subscribe({
      next: (response) => {
        setTimeout(() => {
          this.assets = (response.payload as any[]) || [];
          this.addAssetsToDropdown();
        }, 200);
    
      },
      error: (error) => {
        console.log('Error intentarlo de nuevo');
      },
    });
  }

  addAssetsToDropdown() {
    const dropdown = document.getElementById("assets-dropdown");
    if (dropdown) {
      this.assets.forEach((asset) => {
        const option = document.createElement("bim-option");
        option.value = asset._id;
        option.label = asset.name;
        dropdown.appendChild(option);
      });
    }
  }

  async createIFCLoader(): Promise<void> {
    BUI.Manager.init();

    const components = new OBC.Components();

    const worlds = components.get(OBC.Worlds);
    const world = worlds.create();

    const sceneComponent = new OBC.SimpleScene(components);
    sceneComponent.setup();
    world.scene = sceneComponent;

    const viewport = document.createElement('bim-viewport');
    const rendererComponent = new OBC.SimpleRenderer(components, viewport);
    world.renderer = rendererComponent;

    const cameraComponent = new OBC.SimpleCamera(components);
    world.camera = cameraComponent;

    viewport.addEventListener('resize', () => {
      rendererComponent.resize();
      cameraComponent.updateAspect();
    });

    const viewerGrids = components.get(OBC.Grids);
    viewerGrids.create(world);

    components.init();

    const ifcLoader = components.get(OBC.IfcLoader);
    await ifcLoader.setup();

    const fragmentsManager = components.get(OBC.FragmentsManager);
    fragmentsManager.onFragmentsLoaded.add((model) => {
      if (world.scene) {
        world.scene.three.add(model);
      }
    });

    const [modelsList] = CUI.tables.modelsList({
      components,
      tags: { schema: true, viewDefinition: false },
      actions: { download: false },
    });

    const assetsDropdown = BUI.Dropdown.create(() => {
      return BUI.html`
        <bim-dropdown id="assets-dropdown" vertical>
        </bim-dropdown>
      `;
    });

    assetsDropdown.addEventListener('change', (event) => {
      const dropdown = event.target as BUI.Dropdown;
      this.selectedAsset = dropdown.value[0];
    });

    const linkModelToAsset = new BUI.Button();
    linkModelToAsset.label = 'Vincular Modelo';
    linkModelToAsset.icon = 'mage:link';

    linkModelToAsset.addEventListener('click', () => {
      if (this.selectedAsset && fragmentsManager.groups.size > 0) {
        const asset = this.assets.find(({ _id }) => _id === this.selectedAsset);
        if (asset) {
          console.log(asset);
        }
        this.messageService.add({
          severity: 'success',
          summary: 'Éxito',
          detail: 'Modelo vinculado correctamente',
        });

        

        let blob = fragmentsManager.groups;
        console.log(blob);

        let group = fragmentsManager.groups.get(
          fragmentsManager.groups.keys().next().value
        );
        console.log(group);

        if (group) {
          const datas = fragmentsManager.export(group);
          console.log(datas);
          const props = group.getLocalProperties();
          console.log(props);
          // Rest of the code...
        }
      } else {
        if (!this.selectedAsset)
          this.messageService.add({
            severity: 'info',
            summary: 'Atención',
            detail: 'Falta Seleccionar un Activo',
          });
        else
          this.messageService.add({
            severity: 'info',
            summary: 'Atención',
            detail: 'Falta cargar un modelo IFC',
          });
      }
    });

    const panel = BUI.Component.create(() => {
      const [loadIfcBtn] = CUI.buttons.loadIfc({ components });
      loadIfcBtn.label = 'Cargar IFC';

      return BUI.html`
       <bim-panel label="Modelos IFC">
        <bim-panel-section label="Importar">
          ${loadIfcBtn}
        </bim-panel-section>
        <bim-panel-section icon="mage:box-3d-fill" label="Modelos Cargados">
          ${modelsList}
        </bim-panel-section>
        <bim-panel-section icon="mage:building-tree-fill" label="Activo Representado">
          ${assetsDropdown}
          ${linkModelToAsset}
        </bim-panel-section>
       </bim-panel> 
      `;
    });

    const app = document.createElement('bim-grid');
    app.layouts = {
      main: {
        template: `
          "panel viewport"
          / 23rem 1fr
        `,
        elements: { panel, viewport },
      },
    };

    app.layout = 'main';
    app.style.width = '100%';
    app.style.height = '80vh';
    app.style.fontFamily = 'Poppins';
    this.renderer.appendChild(this.bimContainer.nativeElement, app);

    const stats = new Stats();
    stats.showPanel(2);
    this.renderer.appendChild(this.bimContainer.nativeElement, stats.dom);
    stats.dom.style.left = '95%';
    stats.dom.style.top = '20%';
    stats.dom.style.zIndex = '1000';
    world.renderer.onBeforeUpdate.add(() => stats.begin());
    world.renderer.onAfterUpdate.add(() => stats.end());
  }

  linkModelToAsset() {
    if (this.selectedAsset) {
      const asset = this.assets.find(({ _id }) => _id === this.selectedAsset);
      if (asset) {
        console.log(asset);
      }

      /* let blob = file.slice(0, file.size, 'application/octet-stream');
      console.log(blob);
      console.log(file);
      const newFile = new File([blob], `${id_activo}.ifc`, {
        type: 'application/octet-stream',
      });
      // Build the form data - You can add other input values to this i.e descriptions, make sure img is appended last
      let formData = new FormData();
      formData.append('file', newFile); // esto SE PUEDE ahorrar revisar..............
      //console.log(formData.get("imgfile"));
      console.log(newFile);

      if (!fragments.groups.length) return;
      const group = fragments.groups[0];
      //group.ifcMetadata.schema = "4"+"X"+"3"    ACTUALIZAR SCHEMAAAAAAAAAAAAAAAAAAAAA/////////////////////////////////////////////aaaaaaaaaaaaaaa
      console.log(group);
      const datas = fragments.export(group);

      const blobs = new Blob([datas]);
      const fragmentFile = new File([blobs], `${id_activo}.frag`, {
        type: 'application/octet-stream',
      });
      console.log(fragmentFile);

      const propFiles = new File(
        [JSON.stringify(group.properties)],
        `${id_activo}.json`,
        { type: 'application/octet-stream' }
      );
      console.log(propFiles);

      console.log(formData);
      let formData2 = new FormData();
      formData2.append('file', fragmentFile);
      let formData3 = new FormData();
      formData3.append('file', propFiles);
      console.log(formData2);
      console.log(formData3);

      //console.log(formData2.get("imgfile"));
      //console.log(formData3.get("imgfile"));
      fetch(import.meta.env.VITE_BIM_API_UPLOAD, {
        method: 'POST',
        body: formData,
      });

      fetch(import.meta.env.VITE_BIM_API_UPLOAD, {
        method: 'POST',
        body: formData2,
      });

      fetch(import.meta.env.VITE_BIM_API_UPLOAD, {
        method: 'POST',
        body: formData3,
      });

      console.log(import.meta.env.VITE_AA_API_ACTIVOS + id_activo + '/flags');
      let bodyHasBim = {
        flags: {
          hasBIM: true,
        },
      };*/
    }
  }
}
