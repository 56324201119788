<div class="flex align-items-center justify-content-center" style="height: 90vh;">
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-8">
        <div class="text-center mb-5">
            <img src="../../../assets/images/LIND_bridge.svg" alt="Image" height="150">
            <div class="text-900 font-bold text-6xl mb-4">Página en Construcción</div>
            <p class="line-height-3 mt-0 mb-5 text-700 text-xl font-medium">Esta sección está actualmente en desarrollo y estará disponible próximamente. </p>
            <p-button (onClick)="didTapReturn()" styleClass="p-button-primary font-medium p-button-raised" size="large" label="Volver"></p-button>
        </div>
    </div>
</div>
