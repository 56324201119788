<div  *ngIf="images.length > 0">
    <gallery [gallerize]="galleryId" [id]="'gallery-' + galleryId" [items]="images" [imageSize]="'cover'"
             class="photo-gallery" [thumb]="showThumbs" [thumbWidth]="thumbWidth" [thumbHeight]="thumbHeight">
        <ng-container *galleryImageDef="let item; let active = active">
            <div *ngIf="active" class="item-text" >
                {{ item?.args }}
            </div>
        </ng-container>
    </gallery>
</div>
