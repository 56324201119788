<div class="grid p-1 m-1">
    <div class="col-12">
      <div class="flex flex-column sm:flex-row align-items-center gap-3">
        <div class="flex flex-column sm:flex-row align-items-center gap-3">
          <div class="flex flex-column align-items-center sm:align-items-start">
            <span class="text-900 font-bold text-2xl">Módulo BIM</span>
            <span class="text-500 text-md">Visualización de Modelo: --Nombre--</span>
          </div>
        </div>
      </div>
    </div>
    <div id="canvas-box" class="m-0 p-0 align-items-center justify-content-center"></div>
  </div>
  
