import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
@Injectable()
export class SidenavService {
  private sidenavStatus: boolean = false
  private sidenavObserver: Subject<boolean>;

  constructor() {
    this.sidenavObserver = new Subject<boolean>();
  }

  toggleSidenav(isOpen: boolean) {
    this.sidenavStatus = isOpen;
    this.sidenavObserver.next(this.sidenavStatus);
  }

  getStatus() {
    return this.sidenavObserver.asObservable();
  }
}
