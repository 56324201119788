import {Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {RestApiService} from "../../shared/rest-api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatTableDataSource} from "@angular/material/table";
import {AdditemsModal} from "./model/AdditemsModal";
import {InputTextDialogComponent} from "../input-text-dialog/input-text-dialog.component";

@Component({
  selector: 'app-root-input-rut-dialog',
  templateUrl: './add-Item-dialog.component.html',
  styleUrls: ['./add-Item-dialog.component.scss']
})
export class AddItemDialogComponent implements OnInit {
  @ViewChild('modalContainer') modalContainer?: ElementRef;
  @Output() closeEvent = new EventEmitter<void>();
  loading = false;
  listItems:AdditemsModal[] = []
  dataSourceItemsDetails: MatTableDataSource<AdditemsModal>
  displayedColumns: string[] = [  'nombre', 'actions'];
  constructor(
    private snackBar: MatSnackBar,
    private restApiService:RestApiService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dataSourceItemsDetails = new MatTableDataSource<AdditemsModal>(this.listItems);
  }
  ngOnInit(): void {
    this.restApiService.getItems(this.data.urlSearch).subscribe({
      next: (response) => {
        if (response.success){
          if (this.data.itemtoAdd === 'active_type') {
            this.listItems = response.payload
          } else if (this.data.itemtoAdd === 'parts') {
            this.listItems = response.payload
          }
          this.dataSourceItemsDetails.data = this.listItems
        }else{
          this.displayError()
        }
      },
      error: (error) => {
        this.displayError()
      }
    });
  }
  assertItemTypeName(item: AdditemsModal) {
   switch (this.data.itemtoAdd) {
     case  'active_type':
       return item.name
     case 'parts':
       return ''
   }
   return ''
  }
  applyFilter(event: any) {
    this.dataSourceItemsDetails.filter = event.target.value.trim().toLowerCase();
  }
  didtapAddActiveType(){
    const confirmRef = this.dialog.open(InputTextDialogComponent, {
      data: {
        title: 'Activo',
        cancel: 'Cancelar',
        placeHolder: 'Nombre',
        confirm: 'Agregar'
      }
    });
    confirmRef.afterClosed().subscribe(result => {
      if (result) {
     this.restApiService.addItem(this.data.urlAdd, result.textAdd).subscribe({
          next: (response) => {
            if (response){
              if (this.data.itemtoAdd === 'active_type') {
                this.dialogRef.close(response.payload);
              }
            }else{
              this.displayError()
            }
          },
          error: (error) => {
            this.displayError()
          }
        });

      } else {
        this.displayError()
      }
    });
  }

  didtapDeleteActiveType(item: AdditemsModal){
    this.restApiService.deleteItem(this.data.urlDelete,item._id).subscribe({
      next: (response) => {
        if (response.success){
          this.snackBar.open('elimino la compañia: '+item.name, '', {
            duration: 3000, verticalPosition: 'bottom',
            panelClass: ['blue-snackbar']
          });
          this.deleteRowData(item);
        }else{
          this.displayError()
        }
      },
      error: (error) => {
        this.displayError()
      }
    });
  }
  deleteRowData(rowobj: AdditemsModal) {
    const index: number = this.listItems.findIndex(d => d === rowobj);
    this.listItems.splice(index, 1);
    this.dataSourceItemsDetails = new MatTableDataSource<AdditemsModal>(this.listItems);
  }
  onClose(): void {
    this.dialogRef.close();
    this.closeEvent.emit();
  }

  onClickOutside(event: MouseEvent): void {
    if (!this.modalContainer?.nativeElement.contains(event.target)) {
      this.onClose();
    }
  }
  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
}
