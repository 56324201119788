import {Component, ViewChild} from '@angular/core';
import {Units} from "../units/model/units";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DetailsUnit} from "./model/detailsUnit";
import {RestApiService} from "../../../shared/rest-api.service";
import {ConfirmDialogComponent} from "../../../_dialog/confirm-dialog/confirm-dialog.component";
import {environment} from "../../../../environments/environment.development";
import {Monitor} from "../../monitor/monitors/model/Monitor";
import { Column } from 'src/app/_models/columns';

@Component({
  selector: 'app-detail-unit',
  templateUrl: './detail-unit.component.html',
  styleUrl: './detail-unit.component.css'
})
export class DetailUnitComponent {
  items: DetailsUnit[] = [];
  monitors: Monitor[] = [];
  infoUnit?: Units;
  id: string | undefined
  @ViewChild(MatTable) table!: MatTable<DetailsUnit>;
  dataSource = new MatTableDataSource<DetailsUnit> ;
  displayedColumns = [ 'name','description','enterprise','actions'];
  columns!: Column[];
  loading: boolean = true;

  constructor(private restApiService: RestApiService,
              private dialog: MatDialog,
              private router: Router,
              private snackBar: MatSnackBar,
              private activatedRouter: ActivatedRoute) {
    this.columns = [
      {field: 'name', header: 'Nombre'},
      {field: 'description', header: 'Descripción'},
      {field: 'actions', header: 'Acciones'},
      {field: 'modules', header: 'Módulos'},
    ]
    this.activatedRouter.params.subscribe(params => {
      this.id = params['id'];
      this.dataSource = new MatTableDataSource<DetailsUnit>(this.items);
      this.restApiService.getDetailsActiveUnits(this.id).subscribe({
        next: (response) => {
          if (response.success){
            this.items = response.payload.listActiveTemplateEntity
            this.infoUnit = response.payload.unitInfo
            this.monitors = response.payload.monitorsDefine
            this.dataSource.data = response.payload.listActiveTemplateEntity
            this.loading = false
          }else{
            this.displayError()
          }
        },
        error: (error) => {
          this.displayError()
        }
      });

    });
  }
  didTapCreateAsset(){
    this.router.navigate(['crear-activo',this.id]);
  }

  onTapOpen (item:DetailsUnit){
    this.router.navigate(['mostrar-plantilla-activo',item._id]);
  }

  didTapOpenBim(item:DetailsUnit){
    window.open('https://2024001-01-visor-ifc-git-main-linddevelopers-projects.vercel.app/editor.html?id='+item._id, '_blank');
  }

  checkEnableBim(item:DetailsUnit){
    return !item.hasBIM
  }
  checkEnableMD(item:DetailsUnit){
    return !this.monitors.find((monitor) => item._id === monitor._activeUnit)
  }

  checkEnableVA(item:DetailsUnit){
    return !item.hasVA
  }
  checkEnableMI(item:DetailsUnit){
    return !item.hasMI
  }
  didTapOpenMD(item:DetailsUnit){
    const correspondingMonitor = this.monitors.find((monitor) => monitor._activeUnit === item._id);
    if (correspondingMonitor) {
      const url = correspondingMonitor.url;
      window.open(url, '_blank');
    }
  }
  didTapOpenMI(item:DetailsUnit){
    window.open(environment.UrlMonitor+'1', '_blank');
  }
  didTapOpenVA(item:DetailsUnit){
    window.open("https://2024001-04-vision-artificial-front.vercel.app/"+item._id, '_blank');
  }
  didTapDelete(item: DetailsUnit){
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirmar',
        body: '¿Está seguro de eliminar: ' + item.name + ' ?',
        confirm: 'Borrar',
        confirmClass: 'danger-button',
        cancel: 'Cancelar'
      }
    });
    confirmRef.afterClosed().subscribe(result => {
      if (result) {
        this.restApiService.deleteTemplateDetailsActiveUnits(item._id).subscribe({
          next: (response) => {
            if (response.success){
              this.snackBar.open('Se elimino :'+item.name, '', {
                duration: 3000, verticalPosition: 'bottom',
                panelClass: 'notif-success'
              });
              this.deleteRowData(item)
            }else{
              this.displayError()
            }
          },
          error: (error) => {
            this.displayError()
          }
        });
      }
    });
  }
  assertItemType(item: DetailsUnit): DetailsUnit {
    return item;
  }
  deleteRowData(item: DetailsUnit) {
    const index: number = this.items.findIndex(d => d === item);
    this.items.splice(index, 1);
    this.dataSource = new MatTableDataSource<DetailsUnit>(this.items);
  }

  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
}
