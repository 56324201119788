import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from "@angular/forms";
@Component({
  selector: 'app-root-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent implements OnInit {
  form!: FormGroup;
  text: string | undefined;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit(): void {
    this.form = this.fb.group({
      text: [this.text, []]
    });
  }
  save() {
    this.dialogRef.close(this.form?.value);
  }
  close() {
    this.dialogRef.close();
  }
}
