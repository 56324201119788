
<!DOCTYPE html>
<html>

<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/css/bootstrap.min.css">
</head>
<body>
<ngx-loading-bar></ngx-loading-bar>
<div fxFlex="95"fxLayout="column" style="margin: 5px;font-family: Roboto, sans-serif!important;font-size:20px !important;">
  <h1  class ="logo  title m-3" fxFlex="none"> Lista de ubicaciones 📍</h1>
  <div class="centered-div" *ngxPermissionsOnly  ="['MASTER', 'ADMIN']">
    <button mat-button (click)="onSelectCreateUbication()"  mat-flat-button class="mat-button-register-user">
      <mat-icon inline=true>add</mat-icon>
      Crear Ubicación
    </button>
  </div>
  <table mat-table #dataTable  [dataSource]="dataSource" class="mat-elevation-z0">
     <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> #id </th>
      <td mat-cell *matCellDef="let user"> {{assertItemType(user).id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nombre </th>
      <td mat-cell *matCellDef="let user"> {{assertItemType(user).name}} </td>
    </ng-container>

    <ng-container matColumnDef="descripcion">
      <th mat-header-cell *matHeaderCellDef> Descripción </th>
      <td mat-cell *matCellDef="let user"> {{assertItemType(user).description}} </td>
    </ng-container>
    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef> Compañia </th>
      <td mat-cell *matCellDef="let user"> {{assertItemType(user).company?.name}} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef > Acciones </th>
      <mat-cell *matCellDef="let user" >
        <button mat-button (click)="onSelectUbication(user)">
          <mat-icon class = "svg" svgIcon="pencil-outline"></mat-icon>
          Editar
        </button>
        <div *ngxPermissionsOnly = "['MASTER']">
          <button mat-button  (click)="onDelete(user)">
            <mat-icon class = "svg" svgIcon="delete-outline"></mat-icon>
            Borrar
          </button>
        </div>
      </mat-cell>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">

    </tr>
  </table>
</div>
<!-- Placed at the end of the document so the pages load faster -->
<script src="//ajax.googleapis.com/ajax/libs/jquery/1.12.0/jquery.min.js"></script>
<script src="//maps.googleapis.com/maps/api/js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.1/js/bootstrap.min.js"></script>


</body>

</html>


