import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import * as L from "leaflet";
import {circle, Icon, icon, latLng, Map, marker} from "leaflet";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import GeocoderControl, {geocoder} from "leaflet-control-geocoder";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {InputTemplateData} from "../show-template-asset/InputTemplateData";
import {PhotoSaved} from "./entities/Photo";
import {FileSaved} from "./entities/File";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {RestApiService} from "../../../shared/rest-api.service";
import {ConfirmDialogComponent} from "../../../_dialog/confirm-dialog/confirm-dialog.component";
import {ImgDialogComponent} from "../../../_dialog/img-dialog/img-dialog.component";
import {ResultDetailsActive} from "../../unit/units/model/list_units";
import {Type} from "../../create/create-asset/Type";
import {InputTemplateDataInfo} from "../show-template-asset/InputTemplateInfo";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {DatePipe} from "@angular/common";
import {ViewerFileDialogComponent} from "../../../_dialog/viewer-file-dialog/viewer-file-dialog.component";
import {Monitor} from "../../monitor/monitors/model/Monitor";
import {DetailsUnit} from "../../unit/detail-unit/model/detailsUnit";

@Component({
  selector: 'app-show-template-active',
  templateUrl: './show-template-active.component.html',
  styleUrl: './show-template-active.component.css',
    providers:[DatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ShowTemplateActiveComponent implements OnInit, AfterViewInit  {
    isDisabledEdit:boolean = true
    defineMonitor?:Monitor
    displayedColumns: string[] = ['name','createdAt', 'actions'];
    init?: L.Map;
    geocoder?: GeocoderControl;
    tipos: Type[] = []; // Define a property to store the options
    selectedTipe?: string =""
    id? :string
    items?: ResultDetailsActive
    inputTemplateData?:InputTemplateData[] = [];
    formGroup!: FormGroup;
    markers: L.Marker[] = [];
    inputTemplateDataInfo?:InputTemplateDataInfo[] = [];
    options = {
        layers:[
            L.tileLayer(
                'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                {
                  detectRetina: true,
                  attribution:
                    '&copy; <a href="http://www.esri.com/">Esri</a>, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community ',
                })
        ],
        zoom: 10,
        center: latLng(-35.675147, -71.542969 )
    };
    layers:L.Circle[] = [];

    @ViewChild('dataTables') table!:MatTable<PhotoSaved>
    constructor( public formBuilder: FormBuilder,
                 private restApiService: RestApiService,
                 private router :Router,
                 private dialog: MatDialog,
                 private snackBar: MatSnackBar,
                 private activatedRouter: ActivatedRoute) {
    }
    onMapReady(map: Map) {
        this.init = map
        setTimeout(() => {
            this.init?.invalidateSize();
        }, 0);

        this.geocoder = geocoder({
            defaultMarkGeocode: false,
            placeholder: "Buscar..."
        }).on('markgeocode', function(e) {
            const bbox = e.geocode.bbox;
            const center = bbox.getCenter();
            const radius = center.distanceTo(bbox.getSouthEast());
            const circle = L.circle(center, {radius: radius}).addTo(map);
            map.fitBounds(circle.getBounds());

        }).addTo(map);
    }
    onMapClick(e:L.LeafletMouseEvent) {
        this.markers.forEach((marker) => {
            marker.remove();
        });
        // Set the values for longitud and latitud fields
        this.formGroup.patchValue({
            longitud: e.latlng.lng,
            latitud: e.latlng.lat
        });
        this.init?.setView([ e.latlng.lat, e.latlng.lng ], 10);
        let markerTest =  marker([ e.latlng.lat, e.latlng.lng], {
            icon: icon({
                ...Icon.Default.prototype.options,
                iconUrl: 'assets/leaflet/marker-icon.png',
                iconRetinaUrl: 'assets/leaflet/marker-icon-2x.png',
                shadowUrl: 'assets/leaflet/marker-shadow.png'
            })
        })
        // @ts-ignore
        const newMarker = markerTest.addTo(this.init);
        this.layers  = [];
        setTimeout(() => {
            this.init?.invalidateSize();
        }, 0);
        markerTest.bindPopup('Nueva ubicación.<br> Definida.')
        markerTest.openPopup();
        this.markers = [newMarker];
        this.markers.push(markerTest)

    }

    loadInitDataMap(){
        if(this.init) {
            this.init.setView([this.items?.result.latitude ?? -35.675147, this.items?.result.longitude ?? -71.542969], 10);
            let markerTest = marker([this.items?.result.latitude ?? -35.675147, this.items?.result.longitude ?? -71.542969], {
                icon: icon({
                    ...Icon.Default.prototype.options,
                    iconUrl: 'assets/leaflet/marker-icon.png',
                    iconRetinaUrl: 'assets/leaflet/marker-icon-2x.png',
                    shadowUrl: 'assets/leaflet/marker-shadow.png'
                })
            })
            this.layers.push(circle([this.items?.result.longitude ?? -35.675147, this.items?.result.longitude ?? -71.542969], {radius: 5000}),)
            markerTest.bindPopup('Ubicación.<br> Definida.')
            markerTest.openPopup();
            const newMarker = markerTest.addTo(this.init);
            this.markers = [newMarker];
            this.markers.push(markerTest)
            // @ts-ignore
            this.layers.push(this.markers)
        }
    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.init?.invalidateSize();
        }, 0);

        this.init?.off('click');
    }
    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            longitud: ["", Validators.required],
            latitud: ["", Validators.required],
            activo: ["", Validators.required],
            descripcion: ["", Validators.required],
            partenece_a: ["", Validators.required],
            tipo_activo: ["", Validators.required],
            plantilla: ["", Validators.required],
        });
        this.activatedRouter.params.subscribe(params => {
            this.id = params['id'];
            this.restApiService.getDetailsTemplateActive(this.id).subscribe({
                next: (response) => {
                    if (response.success){
                        this.items = response.payload
                        this.inputTemplateData = this.items?.resultInput
                        this.tipos = this.items.allType
                        this.defineMonitor = this.items.monitorsDefine.find((monitor) => this.id?.trim() === monitor._activeUnit)
                        this.selectedTipe = this.items.result.active_type?._id
                        if (this.inputTemplateData){
                            for (let i = 0; i < this.inputTemplateData?.length; i++) {
                                if( this.inputTemplateData[i].type === "Photos"){
                                    this.inputTemplateData[i].dataSourcePhotoFilesDetails = new MatTableDataSource<any>(this.inputTemplateData[i].photos)
                                }
                                if(this.inputTemplateData[i].type === "Files"){
                                    this.inputTemplateData[i].dataSourcePhotoFilesDetails = new MatTableDataSource<any>(this.inputTemplateData[i].files)
                                }
                            }
                        }
                     this.formGroup = this.formBuilder.group({
                            longitud: [this.items?.result.longitude, Validators.required],
                            latitud: [this.items?.result.latitude, Validators.required],
                            activo: [this.items?.result.name, Validators.required],
                            descripcion: [this.items?.result.description, Validators.required],
                            partenece_a: [this.items?.result.company?.name, Validators.required],
                            tipo_activo: [this.items?.result.active_type?.name, Validators.required],
                            plantilla: [this.items?.result.template?.name, Validators.required]
                        });

                        this.loadInitDataMap()
                    }else{
                        this.displayError()
                    }
                },
                error: (error) => {
                    this.displayError()
                }
            });
        });
    }
    createOptionSelect(item: InputTemplateData):string[] {
        const trimmedOptions = item.notation?.replace(/,\s+/g, ',') ?? ""
        return trimmedOptions.split(',');
    }
    valueDefineToSelector(item: InputTemplateData):string{
       return item.inputData?.value
    }
    uploadOnlyFile(event: any,item: InputTemplateData,i:number) {
        const files = event.target.files;
        if (files.length > 0) {
            const formData = new FormData();
            formData.append("file", files[0])
            this.restApiService.uploadFile(formData, this.id ?? "", item._id ?? "",this.items?.result.template?._id ?? "").subscribe({
                next: (response) => {
                    if (response.success) {
                       let newFile:FileSaved = { _id:response.uploadFile._id,  reference:response.uploadFile.reference, fileName:response.uploadFile.fileName, createdAt:response.uploadFile.createdAt }
                        if (this.inputTemplateData) {
                            const index = this.inputTemplateData?.findIndex(data => data.type === "Files" && data._id === item._id);
                           if (index !== -1) {
                                this.inputTemplateData[index].files?.push(newFile);
                               this.inputTemplateData[index].dataSourcePhotoFilesDetails = new MatTableDataSource<any>(this.inputTemplateData[index].files)
                            }
                        }
                        this.snackBar.open('Se subio la archivo!', '', {
                            duration: 3000, verticalPosition: 'bottom',
                            panelClass: 'notif-success'
                        });
                    } else {
                        this.displayError()
                    }
                },
                error: (error) => {
                    this.displayError()
                }
            });
        }
    }
    upload(event:Event,item: InputTemplateData,i:number) {
        const inputElement = event.target as HTMLInputElement;
       if(inputElement.files){
           const uploadPhoto = inputElement.files[0];
           if (uploadPhoto) {
            let formData = new FormData();
            formData.append('file', uploadPhoto);
            this.restApiService.uploadFile(formData, this.id ?? "", item._id ?? "", this.items?.result.template?._id ?? "").subscribe({
                next: (response) => {
                    if (response.success) {
                        let newPhoto: PhotoSaved = {
                            _id: response.uploadFile._id,
                            reference: response.uploadFile.reference,
                            fileName:response.uploadFile.fileName,
                            createdAt:response.uploadFile.createdAt
                        }
                        if (this.inputTemplateData) {
                            const index = this.inputTemplateData?.findIndex(data => data.type === "Photos" && data._id === item._id);
                            if (index !== -1) {
                                const updatedPhotos = [...this.inputTemplateData[i].photos ?? []];
                                updatedPhotos.push(newPhoto)
                                this.inputTemplateData[i].photos = updatedPhotos
                                this.inputTemplateData[i].dataSourcePhotoFilesDetails = new MatTableDataSource<any>(this.inputTemplateData[i].photos)
                            }
                            this.snackBar.open('Se subio la imagen!', '', {
                                duration: 3000, verticalPosition: 'bottom',
                                panelClass: 'notif-success'
                            });
                        }

                    } else {
                        this.displayError()
                    }
                },
                error: (error) => {
                    this.displayError()
                }
            });
        }
    }
    }
    assertItemTypePhotoSaved(item: PhotoSaved): PhotoSaved {
        return item;
    }
    assertItemTypeFileSaved(item: FileSaved): FileSaved {
        return item;
    }

    didTapOpenPhoto(item: PhotoSaved) {
        const confirmRef = this.dialog.open(ImgDialogComponent, {
            data: {
                lng: 26,
                lat: 26,
                url: item.reference,
                confirm: 'Descargar',
                confirmClass: 'danger-button',
                cancel: 'Cerrar'
            }
        });
        confirmRef.afterClosed().subscribe(result => {
            if (result) {
                // window.open(`${environment.apiUrl}/download_image_one_inspection/` + id_gdrive + '/download' );
            }
        });
    }
    didTapDeletePhoto(item: PhotoSaved,i: number) {
        const confirmRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Confirmar',
                body: '¿Está seguro de eliminar foto: ' + this.getNameFromReference(item.reference) + ' ?',
                confirm: 'Borrar',
                confirmClass: 'danger-button',
                cancel: 'Cancelar'
            }
        });
        confirmRef.afterClosed().subscribe(result => {
        if (result) {
                this.restApiService.deleteImage(this.id ?? "", item._id || 0).subscribe({
                    next: (response) => {
                        if (response.success) {
                            if (this.inputTemplateData) {
                                const index: number = this.inputTemplateData[i].photos?.findIndex(d => d._id === item._id) ?? -1
                                if (index !== -1) {
                                    const updatedPhotos = [...this.inputTemplateData[i].photos ?? []];
                                    updatedPhotos.splice(index, 1);
                                    this.inputTemplateData[i].photos = updatedPhotos;
                                    this.inputTemplateData[i].dataSourcePhotoFilesDetails = new MatTableDataSource<any>(updatedPhotos);
                                }
                            }
                            this.snackBar.open('Se eliminó imagen: ' + this.getNameFromReference(item.reference), '', {
                                duration: 3000, verticalPosition: 'bottom',
                                panelClass: 'notif-success'
                            });
                        } else {
                           this.displayError()
                        }
                    },
                    error: (error) => {
                        this.displayError()
                    }
                });
            }
        });
    }
    didTapOpenFile(item: FileSaved) {
        const confirmRef = this.dialog.open(ViewerFileDialogComponent, {
            data: {
                title: "Documento: "+item.fileName,
                url:item.reference,
                confirm: 'OK',
                confirmClass: 'danger-button',
                cancel: 'Cancelar'
            }
        });
        confirmRef.afterClosed().subscribe(result => {

        });
    }
    assertItemTypeFileDelete(item: FileSaved, i:number) {
        const confirmRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Confirmar',
                body: '¿Está seguro de eliminar foto: ' + this.getNameFromReference(item.reference) + ' ?',
                confirm: 'Borrar',
                confirmClass: 'danger-button',
                cancel: 'Cancelar'
            }
        });
        confirmRef.afterClosed().subscribe(result => {
            if (result) {
                this.restApiService.deleteFile(this.id ?? "", item._id || 0).subscribe({
                    next: (response) => {
                        if (response.success) {
                            if (this.inputTemplateData) {
                                const index: number = this.inputTemplateData[i].files?.findIndex(d => d === item) ?? -1
                                this.inputTemplateData[i].files?.splice(index, 1);
                                this.inputTemplateData[i].dataSourcePhotoFilesDetails =  new MatTableDataSource<any>(this.inputTemplateData[index].photos)
                            }
                            this.snackBar.open('Se eliminó archivo: ' + this.getNameFromReference(item.reference), '', {
                                duration: 3000, verticalPosition: 'bottom',
                                panelClass: 'notif-success'
                            });
                        } else {
                            this.displayError()
                        }
                    },
                    error: (error) => {
                      this.displayError()
                    }
                });
            }
        });
    }
    didTapSaveChanged(){
        if(this.isDisabledEdit){
            this.snackBar.open('Puede editar los datos', '', {
                duration: 3000, verticalPosition: 'bottom',
                panelClass: ['blue-snackbar']
            });
            this.init?.on('click', (e)=>{this.onMapClick(e)});
            this.isDisabledEdit = !this.isDisabledEdit
        } else {
            let nombre = this.formGroup?.value['activo']
            const confirmCreateRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: 'Confirmar',
                    body: '¿Desea actualizar activo ' + nombre + ' ?',
                    confirm: 'Actualizar',
                    confirmClass: 'danger-button',
                    cancel: 'Cancelar'
                }
            });
            confirmCreateRef.afterClosed().subscribe(result => {
                 if (result) {
                    let es_activo =  "off"
                    let hasBIM = false
                    let hasMD = false
                    let hasMI = false
                    let hasVA = false
                    let tipo_de_activo = this.selectedTipe
                    let descripcion = this.formGroup?.value['descripcion']
                    let empresa = this.items?.result.company?._id
                    let URLMD = "https://md.lind.global"
                    let unidad = this.items?.result.unit?._id
                    let latitud = Number(this.formGroup?.value['latitud'])
                    let longitud = Number(this.formGroup?.value['longitud'])

                    this.restApiService.editFullTemplateActive(this.id,nombre, es_activo,  hasBIM,
                        hasMD, hasMI, hasVA,this.items?.result.template?._id, tipo_de_activo, descripcion, empresa, URLMD,
                        unidad, latitud, longitud, this.inputTemplateDataInfo ?? []).subscribe({
                        next: (response) => {
                            if (response.success){
                                this.snackBar.open('Guardo los cambios', '', {
                                    duration: 3000, verticalPosition: 'bottom',
                                    panelClass: ['blue-snackbar']
                                });
                                this.init?.off('click');
                                this.isDisabledEdit = !this.isDisabledEdit
                            }else{
                                this.displayError()
                            }
                        },
                        error: (error) => {
                           this.displayError()
                        }
                    });
                } else if (result.length === 0 ){

                    this.isDisabledEdit = !this.isDisabledEdit
                    this.snackBar.open('Cancelo los cambios', '', {
                        duration: 3000, verticalPosition: 'bottom',
                        panelClass: ['blue-snackbar']
                    });
                     this.reloadRouter()
                }
            });
        }
    }

    getNameFromReference(url:any){
        const fileName = url.split('/').pop();
        if(fileName){
            const [name, extension] = fileName.split('.');
            return `${name}.${extension}`
        } else {
           return url
        }
    }
    textButtonEdit(){
        return this.isDisabledEdit ? "Editar Datos" : "Guardar Cambios";
    }
    onTextChange(event: any, item:InputTemplateData) {
        const newInputTemplateDataInfo = new InputTemplateDataInfo()
        newInputTemplateDataInfo.input = item._id
        newInputTemplateDataInfo.value = event.target.value
        newInputTemplateDataInfo.template = this.items?.result.template?._id
        newInputTemplateDataInfo._id = item.inputData?._id
        this.inputTemplateDataInfo?.push(newInputTemplateDataInfo)
    }
    onChangeCheckBox(ob: MatCheckboxChange,item:InputTemplateData) {
        const newInputTemplateDataInfo = new InputTemplateDataInfo()
        newInputTemplateDataInfo.input = item._id
        newInputTemplateDataInfo.value = ob.checked
        newInputTemplateDataInfo.template = this.items?.result.template?._id
        newInputTemplateDataInfo._id = item.inputData?._id
        this.inputTemplateDataInfo?.push(newInputTemplateDataInfo)
    }
    onChangeSelector(event: any, item:InputTemplateData): void {
        const newInputTemplateDataInfo = new InputTemplateDataInfo()
        newInputTemplateDataInfo.input = item._id
        newInputTemplateDataInfo.value = event.value
        newInputTemplateDataInfo.template = this.items?.result.template?._id
        newInputTemplateDataInfo._id = item.inputData?._id
        this.inputTemplateDataInfo?.push(newInputTemplateDataInfo)
    }
    displayError(){
        this.snackBar.open('Error intentarlo de nuevo', '', {
            duration: 3000, verticalPosition: 'bottom',
            panelClass: ['blue-snackbar']
        });
    }
    reloadRouter() {
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(currentUrl);
        });
    }
    didTapOpenBim(){
        window.open('https://2024001-01-visor-ifc-git-main-linddevelopers-projects.vercel.app/editor.html?id='+this.items?.result._id, '_blank');
    }
    didTapOpenMD(){
        const correspondingMonitor = this.items?.monitorsDefine.find((monitor) => monitor._activeUnit === this.items?.result._id);
        if (correspondingMonitor) {
            const url = correspondingMonitor.url;
            window.open(url, '_blank');
        }
    }
    didTapOpenMI(){
        const correspondingMonitor = this.items?.monitorsDefine.find((monitor) => monitor._activeUnit === this.items?.result._id);
        if (correspondingMonitor) {
            const url = correspondingMonitor.url;
            window.open(url, '_blank');
        }
    }
    checkEnableBim(){
        return !this.items?.result.hasBIM
    }
    checkEnableMD(){
        return !this.items?.monitorsDefine.find((monitor) => this.items?.result._id === monitor._activeUnit)
    }
    checkEnableVA(){
        return !this.items?.result.hasVA
    }
    checkEnableMI(){
        return !this.items?.result.hasMI
    }
    protected readonly String = String;
}
