import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from "@angular/forms";
import {RestApiService} from "../../shared/rest-api.service";
import {Type} from "../../Views/create/create-asset/Type";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-root-input-comment-dialog',
  templateUrl: './input-comment-dialog.component.html',
  styleUrls: ['./input-comment-dialog.component.scss']
})
export class InputCommentDialogComponent implements OnInit {
  form!: FormGroup;
  text:string;
  text1:string;
  typeActive:string;
  tipos: Type[] = [];
  constructor(
    private fb: FormBuilder,
    private restApiService: RestApiService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<InputCommentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.text = data.textEdit
    this.text1 = data.textEdit1
    this.typeActive = data.typeActive
  }
  ngOnInit(): void {
    this.form = this.fb.group({
      text: [this.text, []],
      text1: [this.text1, []]
    });

    if( this.data.showtypeActive){
      this.restApiService.getAllActiveType().subscribe({
        next: (response) => {
          if (response.success){
            this.tipos = response.payload
          }else{
            this.displayError()
          }
        },
        error: (error) => {
          this.displayError()
        }
      });
    }
  }
  save() {
    if( this.data.showtypeActive){
      this.dialogRef.close({texts:this.form?.value, typeActive:this.typeActive});
    }else {
      this.dialogRef.close(this.form?.value);
    }
  }
  close() {
    this.dialogRef.close();
  }
  displayError(){
    this.snackBar.open('Error intentarlo de nuevo', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
}
