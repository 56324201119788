<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>{{ data.body }}</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ data.cancel }}</button>
  <button mat-button [mat-dialog-close]="true" class="{{ data.confirmClass }}">{{ data.confirm }}</button>
</mat-dialog-actions>




