<div class="slider-container">
  <div class="slides" #slides>
    <ng-content  select="[appSliderItem]" #sliderItem></ng-content>
  </div>
  <div class="controls">
    <button   mat-button (click)="onClickLeft()">
      <mat-icon class = "svg" svgIcon="chevron-left"></mat-icon>
      {{"Mover Izquierda"}}
    </button>
    <button   mat-button (click)="onClickRight()">
      <mat-icon class = "svg" svgIcon="chevron-right"></mat-icon>
      {{"Mover Derecha"}}
    </button>
  </div>
</div>
