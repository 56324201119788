import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from "../../shared/authentication.service";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { MenuItem } from "primeng/api";

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.css']
})

export class MainViewComponent implements OnInit {
  public isSidenavOpen = false;
  public menuUserSelected: MenuItem[] = [];
  userInfo = this.authService.getCurrentUser();
  
  public menuGuestMaster: MenuItem[] = [
    { label: "Dashboard", icon: "pi pi-home", command: () => this.navigateTo("/dashboard") },
    {
      label: "Administrador de Activos", icon: "pi pi-building", items: [
        { label: "Activos", icon: "pi pi-list", route: "/wip" },
        { label: "Plantillas", icon: "pi pi-list", route: "/lista-plantillas" },
        { label: "Unidades", icon: "pi pi-list", route: "/lista-unidades" }
      ]
    },
    {
      label: "BIM", icon: "pi pi-warehouse", items: [
        { label: "Inicio", icon: "pi pi-home", route: "/bim" },
        { label: "Cargar BIM", icon: "pi pi-upload", route: "/bim-loader" },
        { label: "Asignar Sensores", icon: "pi pi-wrench", route: "/wip" },
        { label: "Asignar Hallazgos", icon: "pi pi-wrench", route: "/wip" }
      ]
    },
    { label: "Gestión de Activos", icon: "pi pi-list", route: "/wip" },
    {
      label: "Inspecciones / Mantenciones", icon: "pi pi-list", items: [
        { label: "Plantillas de Inspecciones", icon: "pi pi-list", route: "/wip" },
        { label: "Inspecciones", icon: "pi pi-list", route: "/inspecciones" },
        { label: "Mantenciones", icon: "pi pi-list", route: "/mantenciones" }
      ]
    },
    {
      label: "Monitoreo Directo", icon: "pi pi-desktop", items: [
        { label: "Paneles de Monitoreo", icon: "pi pi-desktop", route: "/monitores" },
        { label: "Sensores", icon: "pi pi-list", route: "/sensores" },
        { label: "Alarmas", icon: "pi pi-list", route: "/alarmas" },
        { label: "Eventos", icon: "pi pi-list", route: "/eventos" }
      ]
    },
    {
      label: "Monitoreo Indirecto", icon: "pi pi-map", items: [
        { label: "Caracterización de Activos", icon: "pi pi-map-marker", route: "/caracterizacion-activos" },
        { label: "Carga de Recorrido", icon: "pi pi-upload", route: "/carga-recorrido" },
        { label: "Procesamiento Pasadas", icon: "pi pi-gauge", route: "/procesamiento-pasadas" },
        { label: "Reportes", icon: "pi pi-chart-bar", route: "/reportes" }
      ]
    },
    {
      label: "Visión Artificial", icon: "pi pi-eye", items: [
        { label: "Cámaras", icon: "pi pi-video", route: "/camaras" },
        { label: "Configuración", icon: "pi pi-cog", route: "/configuracion-camaras" }
      ]
    },
    { label: "Monitoreo Satelital", icon: "pi pi-bullseye", route: "/wip" },
    {
      label: "Configuracion", icon: "pi pi-cog", items: [
        { label: "Empresas", icon: "pi pi-building-columns", route: "/empresas" },
        { label: "Ubicaciones", icon: "pi pi-map-marker", route: "/ubicaciones" },
        { label: "Usuarios", icon: "pi pi-users", route: "/usuarios" },
        { label: "Permisos", icon: "pi pi-key", route: "/wip" }
      ]
    }
  ];

  public menuGuestUserVisit: MenuItem[] = [
    { label: "Dashboard", icon: "pi pi-home", command: () => this.navigateTo("/dashboard") },
    {
      label: "Administrador de Activos", icon: "pi pi-building", items: [
        { label: "Activos", icon: "pi pi-list", route: "/wip" },
        { label: "Plantillas", icon: "pi pi-list", route: "/lista-plantillas" },
        { label: "Unidades", icon: "pi pi-list", route: "/lista-unidades" }
      ]
    },
    {
      label: "BIM", icon: "pi pi-warehouse", items: [
        { label: "Inicio", icon: "pi pi-home", route: "/bim" },
        { label: "Cargar BIM", icon: "pi pi-upload", route: "/bim-loader" },
        { label: "Asignar Sensores", icon: "pi pi-wrench", route: "/asignar-sensores" },
        { label: "Asignar Hallazgos", icon: "pi pi-wrench", route: "/asignar-hallazgos" }
      ]
    },
    { label: "Gestión de Activos", icon: "pi pi-list", route: "/gestion-activos" },
    {
      label: "Inspecciones / Mantenciones", icon: "pi pi-list", items: [
        { label: "Plantillas de Inspecciones", icon: "pi pi-list", route: "/wip" },
        { label: "Inspecciones", icon: "pi pi-list", route: "/wip" },
        { label: "Mantenciones", icon: "pi pi-list", route: "/wip" }
      ]
    },
    {
      label: "Monitoreo Directo", icon: "pi pi-desktop", items: [
        { label: "Paneles de Monitoreo", icon: "pi pi-desktop", route: "/monitores" },
        { label: "Sensores", icon: "pi pi-list", route: "/wip" },
        { label: "Alarmas", icon: "pi pi-list", route: "/wip" },
        { label: "Eventos", icon: "pi pi-list", route: "/wip" }
      ]
    },
    {
      label: "Monitoreo Indirecto", icon: "pi pi-map", items: [
        { label: "Caracterización de Activos", icon: "pi pi-map-marker", route: "/caracterizacion-activos" },
        { label: "Carga de Recorrido", icon: "pi pi-upload", route: "/carga-recorrido" },
        { label: "Procesamiento Pasadas", icon: "pi pi-gauge", route: "/procesamiento-pasadas" },
        { label: "Reportes", icon: "pi pi-chart-bar", route: "/reportes" }
      ]
    },
    {
      label: "Visión Artificial", icon: "pi pi-eye", items: [
        { label: "Cámaras", icon: "pi pi-video", route: "/wip" },
        { label: "Configuración", icon: "pi pi-cog", route: "/wip" }
      ]
    },
    { label: "Monitoreo Satelital", icon: "pi pi-bullseye", route: "/wip" },
  ];

  constructor(
    private authService: AuthenticationService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.checkPermission();
  }

  public closeSession(): void {
    this.authService.logout();
    this.router.navigate(['/logear']);
  }

  private navigateTo(link: string): void {
    this.router.navigate([link]);
  }

  private async checkPermission(): Promise<void> {
    const permissionsAll: string[] = environment.permissionsAll;
    const permissionsUserVisit: string[] = environment.permissionsUserVisit;
    const userPermissions: string[] = this.authService.getPermissions();

    if (this.hasPermissions(userPermissions, permissionsAll)) {
      this.menuUserSelected = this.menuGuestMaster;
    } else if (this.hasPermissions(userPermissions, permissionsUserVisit)) {
      this.menuUserSelected = this.menuGuestUserVisit;
    }
  }

  private hasPermissions(userPermissions: string[], requiredPermissions: string[]): boolean {
    return userPermissions.some(permission => requiredPermissions.includes(permission));
  }

  public didTapClickListMonitors(): void {
    this.navigateTo('monitores');
  }

  public didTapClickCamera(): void {
    const url = "https://2024001-04-vision-artificial-front.vercel.app/";
    window.open(url, "_blank");
  }

  public didTapClickBim(): void {
    const url = "https://2024001-01-visor-ifc-git-main-linddevelopers-projects.vercel.app/";
    window.open(url, "_blank");
  }

  public didTapClickMonitor(): void {
    const url = "https://2024001-03-monitoreo-indirecto-git-main-linddevelopers-projects.vercel.app/";
    window.open(url, "_blank");
  }

  public didTapDashboard(): void {
    this.navigateTo('dashboard');
  }
}
